import Block from "./Block";
import React, { useContext, useState } from "react";
import AttributesList from "../ui/AttributesList";
import {FaBolt, FaCog, FaCogs, FaPenAlt, FaTag, FaWrench} from "react-icons/fa";
import EditorContext from "../EditorContext";
import LevelIcon from "./LevelIcon";
import HoverActions from "../ui/HoverActions";
import ButtonClone from "../ui/ButtonClone";
import ButtonDelete from "../ui/ButtonDelete";
import BlockLocal from "./BlockLocal";
import BlockOp from "./BlockOp";
import NodeActions from "../ui/NodeActions";
import Popup from "reactjs-popup";
import { AddyBox } from "./Blocks";

export default function BlockSet({node, onAction, parentAddy}) {

    return (
        <>
            <LevelIcon><FaTag/></LevelIcon>
            <AttributesList icon={<span/>} node={node}/>
            <HoverActions><NodeActions node={node} onAction={onAction} addy={parentAddy} /></HoverActions>        
        </>
    )

}
function BlockSetAddy({node, onDone, container, containerType}){
    const [popuped,setPopuped] = useState(true)
    const context = useContext(EditorContext)
    const id = container.addy.id;
    return <Popup open={popuped} modal onClose={ev=>{
            setPopuped(false);
            onDone(true);
    }}>
        <h2>parameters for {id}: <i>{containerType}</i></h2>
        {Object.values(context.definitions[id][containerType].params).map(o => {
            //console.info(o);
            let syn = o.syntax;
            return <div key={syn} className="classy-item" value={syn}
                    onClick={ev=>{
                        let b = Block.parseFromString('<SET ' + syn + ' />');
                        if(AddyBox.currentNode) AddyBox.currentNode.before(b);
                        else node.append(b);
                        AddyBox.currentNode=null; //TODO refactor into Blocks.js / Addy
                        context.dispatch("block-added");
                        onDone(true)                    
                    }}>
                            <a>{o.desc}</a>
                            <label>{syn}</label></div>

        })}
    </Popup>
}
BlockSet.addy = {
    icon: <span title={"set parameter"}><FaTag/></span>,
    popup: ({node, context, onDone, container, containerType}) => 
        <BlockSetAddy node={node} onDone={onDone} container={container} containerType={containerType} />,
    make: Block.parseFromString('<SET foo="bar" />')    
}