import { useContext, useState } from "react";
import { FaBug, FaCheck, FaChevronDown, FaChevronRight, FaSpinner, FaTimesCircle } from "react-icons/fa";
import EditorContext from "../../EditorContext";
import Utils from "../../converter/Utils";

export function LearnLog({item}){
    const editor = useContext(EditorContext)    
    if(item.type=='file'){
        var fileSize = item.size===-1 ? '(not found)' : Utils.humanFileSize(item.size||0);
        let a = item.meta.split('/');        
        var shortName = a.pop();
        if(a.length) shortName = a.pop()+'/'+shortName;
        if(a.length) shortName = '…/'+shortName;
    }
    return <li className={"learn-step-log learn-step-log-"+item.type}><span>{item.msg}</span>
        {item.type=='file' ? <i><a className={"file-name file-link"+(item.size===-1?' not-found':'')} onClick={ev=>editor.callOpenFile(item.meta,ev)} title={item.meta}>{shortName}</a> <i onClick={ev=>editor.callBrowseFile(item.meta,ev)} className="file-size file-link">{fileSize}</i></i>  : <></>}
        {item.state=='ok' ? <FaCheck className={"state-ok"}/> : <></>}
        {item.state=='work' ? <FaSpinner className={"spinner"}/> : <></>}        
    </li>
}
export function LearnStep({step}){
    const [open,setOpen] = useState((step.id!='Pipeline')||(step.logs && step.logs[0] && step.logs[0].type=='warn'))
    const [showDebugs,setShowDebugs] = useState(false)
    const [showMore,setShowMore] = useState(false)
    let logs = step.logs || [];
    logs = logs.slice();
    let debugs = logs.filter(o=>o.type=='debug');
    let hidden = [];
    if(!showDebugs) logs = logs.filter(o=>o.type!='debug');
    
    if(showMore)logs = logs.map((o,i)=><LearnLog key={i} item={o}/>);
    else {
        let maxVisible = 5
        hidden = logs.filter((o,i)=>i<=logs.length-6);
        logs = logs.filter((o,i)=>i>logs.length-6).map((o,i)=><LearnLog key={i} item={o}/>);
    }
    let monitors = step.monitors ? step.monitors.filter(o=>!o.dead).map((o,i)=><LearnMonitor key={o} exec={o}/>) : <></>
    return <div className={"learn-step "+(step.logs || step.steps?'':'learn-step-pristine')}>
        <div className="learn-step-title" onClick={()=>setOpen(!open)}>{open ? <FaChevronDown/> : <FaChevronRight/>} {step.id || 'untitled step'}
        {debugs.length ? <a className="learn-step-debug" onClick={(ev)=>{
            if(!open) setOpen(true);
            setShowDebugs(!showDebugs)
            setShowMore(true);
            ev.preventDefault();
            ev.stopPropagation();
        }}><FaBug/></a> : <></>}
        </div>
        {open && step.logs ? <ul>
            { hidden.length ? <li className="more-lines-hidden" onClick={()=>setShowMore(true)}>{hidden.length} lines hidden</li> : <></>}
            {logs}
        </ul>:<></>
        }
        {open && step.steps ? step.steps.map((o,i)=><LearnStep key={i} step={o}/>) : <></>}        
        {monitors}        
    </div>
}
export function LearnMonitor({exec}){
    const editor = useContext(EditorContext)
    const killExec = id => {
        editor.parallelCall("kill-exec", {
            id: id            
        });
    }       
    //let logs = step.logs ? step.logs.map(o=><LearnLog item={o}/>) : <></>
    return <span className={"learn-exec-monitor "+(exec.dead ? 'dead':'alive')}>
        <FaSpinner className={"spinner"}/> <b>{exec.commands[0]}</b>
        <button title="kill this external process" onClick={()=>killExec(exec.id)}><FaTimesCircle /></button>
        <i className="cpu-load" title="CPU usage">{(false?exec.cpu+'':'').substring(0,5)}</i>
    </span>
}