class Utils {
/**
* https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
* Format bytes as human-readable text.
* 
* @param bytes Number of bytes.
* @param si True to use metric (SI) units, aka powers of 1000. False to use 
*           binary (IEC), aka powers of 1024.
* @param dp Number of decimal places to display.
* 
* @return Formatted string.
*/
  static humanFileSize(bytes, si=false, dp=1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  let v = bytes>100 ? Math.round(bytes) : bytes.toFixed(dp);
  return  v + ' ' + units[u];
}
 
static toHHMMSS(v) {
    var sec_num = parseInt(v, 10);
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
}

static prettifyXml = xmlDoc => {        
  var xsltDoc = new DOMParser().parseFromString([
      // describes how we want to modify the XML - indent everything
      '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
      '  <xsl:strip-space elements="*"/>',
      '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
      '    <xsl:value-of select="normalize-space(.)"/>',
      '  </xsl:template>',
      '  <xsl:template match="node()|@*">',
      '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
      '  </xsl:template>',
      '  <xsl:output indent="yes"/>',
      '</xsl:stylesheet>',
  ].join('\n'), 'application/xml');

  var xsltProcessor = new XSLTProcessor();    
  xsltProcessor.importStylesheet(xsltDoc);
  var resultDoc = xsltProcessor.transformToDocument(xmlDoc);
  var resultXml = new XMLSerializer().serializeToString(resultDoc);
  return resultXml;
};

static toXML(doc){  
  try {
      return Utils.prettifyXml(doc);
  }catch(ex){
      //https://stackoverflow.com/questions/376373/pretty-printing-xml-with-javascript      
      return new XMLSerializer().serializeToString(doc);
  }
}

}

export default Utils;

