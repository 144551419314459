import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

export default function Sortable(props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
    } = useSortable({
        id: props.id,
        data:{node:props.node}
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div className={"drag-droppable "+props.className} ref={setNodeRef} style={style}>
            <span ref={setActivatorNodeRef} className={"drag-handle"} {...listeners} {...attributes}></span>
            {props.children}
        </div>
    );
}