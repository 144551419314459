import EventDispatcher from "../contexts/EventDispatcher";

export default class EditorFile extends EventDispatcher {

    load(content) { 
        //this.definitions = this.converter.definitions;        
        this.content = content;
        this.parse();
        this.dispatch('load');
    }
    parse(){
        let file = this;
        if (file.document) throw "already parsed";
        const parser = new DOMParser();
        let content = file.content;
        content = content.split('" :').join('" _.'); //TODO clean! !IMPORTANT!
        try {
            console.info('DOING PARSE');  
            file.document = parser.parseFromString(content, "text/xml");    
            console.info('PARSE DONE',file.document);      
            let errNode = file.document.querySelector('parsererror');
            if (errNode) {
                file.document = undefined;                
                let err = errNode.textContent.split('Below is')[0].split('errors:')[1];
                if(!err) err=errNode.textContent;
                return file.parseError = <span className={"file-parse-error"}>Parsing XML failed: {err}</span>
            }  
            if(file.document.documentElement.tagName=='AVAA_CONFIG'){
                file.document = undefined;                
                return file.parseError = <span className={"file-parse-error"}>this is an avaa-toolkit config file, it can't be edited yet</span>
            }
            if(file.document.documentElement.tagName!=='DOCUMENT'){
                file.document = undefined;
                return file.parseError = <span className={"file-parse-error"}>this does not seem to be an avaa-toolkit xml document file</span>
            }
        } catch (ex) {
            file.document = undefined;
            return file.parseError = <span className={"file-parse-error"}>{ex.message}</span>
        }        
    }

    setConvertProgress(v){
        //if(!this.progress) this.progress = {total:0,ffmpeg:0};
        this.progress = v;
        //Object.keys(v).forEach(k=>{if(v[k]!==undefined) this.progress[k]=v[k]});
        this.dispatch("convert-progress", this.progress);
    }
    setDeployProgress(v){
        console.info("setDeployProgress",v);
        if(!this.deploy) this.deploy = {total:0,uploadPercent:0,current:0};
        Object.keys(this.deploy).forEach(k=>{if(v[k]!==undefined) this.deploy[k]=v[k]});
        this.dispatch("deploy-progress", this.deploy);
    }
    setSelected(f) {
        this.selected = f;
        this.dispatch("file-selected", f);
    }

    constructor(o) {
        super()
        for (let k in o) this[k] = o[k];
    }
}