import { useState } from "react";
import AutosizeInput from "react-input-autosize";
import { useEditor } from "../EditorContext";
import { useFile } from "../contexts/FileContext";

export default function Deployer({onClose}){
    const editor = useEditor();
    const file = useFile();    
    const [method, setMethod] = useState('');    
    const [userName, setUserName] = useState('');
    const [userPass, setUserPass] = useState('');
    const [host, setHost] = useState('');
    const [folder, setFolder] = useState('');
    const [ftpLink, setFtpLink] = useState('');
    const [deploying, setDeploying] = useState(false);
    const [deployedURLs, setDeployedURLs] = useState(null);
    const [deployedErrors, setDeployedErrors] = useState(null);


    const onFtpLink = (link)=>{
        setFtpLink(link);
        if(!link) return;
        if(link.substr(0,6).toLowerCase()!='ftp://') return;
        link = link.substr(6);
        console.info(link);        
        let a = link.split('@');
        if(a.length!=2) return;
        let userAndPass = a[0].split(':');
        setUserName(userAndPass[0]);
        setUserPass(userAndPass[1]);
        let hostAndDir = a[1].split('/');
        setHost(hostAndDir[0]);
        if(hostAndDir.length==2){
            setFolder(hostAndDir[1]);
        }        
    }

    const deployNow = (ev) => {
        let zipf = file.path.split('.');
        zipf[zipf.length-1] = 'zip'
        zipf = zipf.join('.');     
        setDeployedURLs(null);
        setDeployedErrors(null);
        setDeploying(true)   
        if(!file.deploy) file.deploy = {}; 
        file.deploy.total = file.deploy.current = file.deploy.uploadPercent = 0;        
        editor.converter.call("deploy", {
            method:method, 
            file: zipf, 
            user: userName, 
            pass:userPass, 
            host:host,
            folder:folder
        }, (r,err) => {                
            setDeploying(false) 
            if(err){
                setDeployedErrors([err]);
                return;
            }        
            console.info(r);
            if(r?.urls?.length){
                setDeployedURLs(r.urls);
            }
            if(r?.errors?.length) {
                setDeployedErrors(r.errors);
            }
            
        })
    }//, [file]);

    return <div>        
        <select value={method} onChange={ev=>setMethod(ev.currentTarget.value)} >
            <option disabled value="">-- select upload method --</option>
            <option value="ftp">FTP</option>
            <option value="web">Web Service</option>
        </select><br/><br/>
    {method=='ftp' ? <div>
    <AutosizeInput
        className='dashed'
        autoFocus={true}
        placeholder={"paste FTP link here"}
        type={"text"}
        value={ftpLink}                
        onChange={ev=>onFtpLink(ev.target.value)}
    /><br/><br/><i style={{color:'gray'}}>or enter connection details:</i>
    </div> :<></>}
    {method ? <div>
    <AutosizeInput
        className='dashed'
        autoFocus={method!='ftp'}
        placeholder={"user name"}                
        type={"text"}
        value={userName}                
        onChange={ev=>setUserName(ev.target.value)}
    /><br/>
    <AutosizeInput
        className='dashed'        
        placeholder={"password"}
        type={"password"}
        value={userPass}                
        onChange={ev=>setUserPass(ev.target.value)}
    /><br/>
    <AutosizeInput
        className='dashed'        
        placeholder={method=='ftp' ? "ftp.example.com:21" : "https://example.com/deployer"}
        type={"text"}
        value={host}                
        onChange={ev=>setHost(ev.target.value)}
    /><br/>
    {method=='ftp' ? <div>
        <AutosizeInput
        className='dashed'    
        title="Specify a directory where to upload the zip files on the FTP. If left blank, a directory with current timestamp will be created.
        It is also possible to use the $time special variable which will be replaced by the current UNIX timestamp."
        placeholder={"directory"}
        type={"text"}
        value={folder}                
        onChange={ev=>setFolder(ev.target.value)}
    /><br/></div> : <></>}
    <button onClick={deployNow} disabled={!method || !userPass || !userName || deploying || deployedURLs}>Send</button><br/>

    </div> : <></>}
    {deploying ? <><br/>
    Upload {file?.deploy?.uploadPercent ?? 0}%<br/>
    Deployment {file?.deploy?.current ?? 0} / {file?.deploy?.total ?? 0}<br/>
    
    </> : <></>}
    {deployedURLs ? <ul>
            {deployedURLs.map(url => {
                if(method=='ftp') url='https://YOUR-FTP-PUBLIC-WEB-ADDRESS'+url;
                return <li key={url}><a target="_blank" rel="noreferrer" href={url}>{url}</a></li>}
            )}
        </ul> : <></>
    }                    
    {deployedErrors ? <ul>
            {deployedErrors.map(err => <li key={err}><b style={{color:'red'}}>{err}</b></li>)}
        </ul> : <></>
    }           
             
    <button disabled={deploying} onClick={onClose}>CLOSE</button>
    </div>
}