import { SketchPicker } from "react-color";
import Popup from "reactjs-popup";

export default function ColorPickerButton({color,onChange}) {
    const onColorChange = (value) => {
        onChange && onChange(value.hex);
    }
    return <Popup className="color-picker-popup" nested trigger={<button style={{backgroundColor:color}} className="color-picker-button"> </button>} modal>            
    <div>
           <SketchPicker color={color} onChange={ v=>onColorChange(v)} />
   </div> 
   </Popup>                

}