import { useEffect, useState } from "react";
import { useEditor } from "../EditorContext";
import { useFile } from "../contexts/FileContext";
import { FaLink, FaStop } from "react-icons/fa";

export default function ProgressTracker(){
    const file = useFile();
    const editor = useEditor();    
    const [ticket, setTicket] = useState(0);
    const [progress, setProgress] = useState({starting:true});
    const [progressProc, setProgressProc] = useState({});
    const [aborting, setAborting] = useState(false);

    const abort = ()=>{
        if(aborting) return;
        setAborting(true);        
        editor.parallelCall("abort-ticket", {
            ticket:ticket
        });
    }
    useEffect(() => {
        const onBlockProgress = (v) =>{            
            let b = v.detail.split('DOCUMENT[1] > ').join('');
            b = b.split('[').join('#').split(']').join('');
            b = b.substring(0,b.length-3).trim()                     
            setProgress({block:b});
            setProgressProc({});
        }
        const onProcProgress = (v) =>{          
            let o = v.detail; 
            let lastStep = o.steps[o.steps.length-1];
            if(lastStep && lastStep.steps){
                lastStep = lastStep.steps[lastStep.steps.length-1];
            }
            if(lastStep && lastStep.logs){
                let lastLog = lastStep.logs[lastStep.logs.length-1];                
                if(lastLog){
                    o.label = lastLog.msg;
                    /*
                    let msg = lastLog.msg;
                    let a = msg.split('Processing...');
                    if(a.length===2){
                        o.label = a[1];
                    }*/
                }
            }
            setProgressProc(o);
            setProgress({});
        }        
        const onCorpusProgress = (v) =>{        
            if(!ticket && v.detail.ticket) { // todo refactor?
                setTicket(v.detail.ticket);
            }
            setProgress({corpus:v.detail});            
        }        
        const onResetProgress = (v) =>{
            setProgress({});            
        }
        const onConvertProgress = (v) => {               
            setProgress(Object(v.detail));           
            if(v.detail.clipping || v.detail.snapping){
                setProgressProc({});
            }
        }
        const onDeployProgress = (v) => {
            //setProgress(v.detail.upload)
        }
        file.on('reset-progress', onResetProgress);
        file.on('convert-progress', onConvertProgress);
        file.on('deploy-progress', onDeployProgress);
        file.on('corpus-progress', onCorpusProgress);
        file.on('block-progress', onBlockProgress);
        file.on('processor-progress', onProcProgress);
        
        return () => {            
            file.off('reset-progress', onResetProgress);
            file.off("convert-progress", onConvertProgress);
            file.off("deploy-progress", onDeployProgress);
            file.off('corpus-progress', onCorpusProgress);
            file.off('block-progress', onBlockProgress);
            file.on('processor-progress', onProcProgress);
        }
    }, [file]);
    return <div className="insight opened progress-tracker">            
            {progress.starting ? <span>Starting...</span> : <></>}
            {progress.corpus ? <span>Loading corpus...</span> : <></>}
            {progress.clipping ? <span>Building clip {progress.clip}{progress.clips ? <span> / {progress.clips}</span>:<></>} - {progress.ffmpeg}% </span> : <></>}
            {progress.snapping ? <span>Building snapshot {progress.snap}{progress.snaps ? <span> / {progress.snaps}</span>:<></>}</span> : <></>}
            {progress.block ? <span>Processing <i>{progress.block}</i></span> : <></>}            
            {progressProc.source ? <span><FaLink/> <b>{progressProc.source}</b> {progressProc.label}</span> : <></>}
            <span>&nbsp;</span>
            {ticket ? <a className={"abort-ticket"} title="click to abort conversion" onClick={abort}><FaStop className={(aborting ? 'spinner' : '')}/></a> : <></>}
    </div>
}