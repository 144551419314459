import Block from "../blocks/Block";
import React, {useContext, useEffect, useState} from "react";
import EditorContext from "../EditorContext";
import ButtonDelete from "./ButtonDelete";
import ButtonAdd from "./ButtonAdd";
import { FaArchive, FaBook, FaBookReader, FaBrain, FaDiscord, FaLightbulb, FaPlusCircle, FaPuzzlePiece, FaRegLightbulb, FaSpinner, FaWalking, FaWrench } from "react-icons/fa";
import Utils from "../converter/Utils";
import Popup from "reactjs-popup";
import FFMPEGInstaller from "./updater/FFMPEGInstaller";
import AVAAConfig from "./AVAAConfig";
import { useForceUpdate } from "../blocks/Blocks";

export default function WelcomeTab({}) {
    const editor = useContext(EditorContext)
    const [calling,setCalling] = useState(false);
    const forceUpdate = useForceUpdate();
    const openFile = (f,e) => {
        if(e){
            e.preventDefault()
            if(e.ctrlKey){
                window.open('/#/'+f.arg+"/"+f.name);
                return;
            }
        }
        f = editor.openFile(f.path);
        editor.setSelected(f);
    }
    let toOpen = editor.converter.files;//Object.values(editor.converter.files).filter(f => editor.opened.indexOf(f) == -1);
    var prev;
    const createFile = folder => {
        console.info(folder);
        let name = prompt("Enter file name",'');
        editor.createFile(folder,name);
        console.info(name);
        
    }
    const refreshStatus = ()=>{
        setCalling(true);
        editor.converter.loadStatus(()=>{
            setCalling(false);            
        });
    }
    const clearTemporaryFiles = ()=>{
        setCalling(true);
        editor.parallelCall("clear-temp", {},()=>{
            setCalling(false);
            refreshStatus()
        });
    }
    const clearAssets = ()=>{
        setCalling(true);
        editor.parallelCall("clear-assets", {},()=>{
            setCalling(false);
            refreshStatus()
        });
    }
    editor.checkLocationHash(); //TODO reafactor 

    const reload = ()=>{
        editor.converter.reloadFolders(()=>{
            forceUpdate();
        })
    }

    const activeFolders = editor.converter.folders.slice();
    let V = editor.converter.version;
    let ready = editor.converter.ready;

/*
    join our <a target="_blank" href="https://discord.gg/8YCySYknUY"> discord
    </a> or */

    return  <div className={"welcome-screen "+(ready?'connected':'')}>
            <div className={(!ready?'spinner-blur':'')}>
                <img className={"spinner3d logo-avaa"} src="./logo512.png"/>
            </div>            
            {editor.autoOpenError}
            {ready ? <div className={"no-file-opened"}>                                                              
                { V.toolkit<V.min ? <div className="outdated-toolkit">
                    You have an outdated toolkit version. <br/>
                    <a href="https://www.avaa-toolkit.org/documentation#update" target="_blank">Update AVAA Toolkit</a><br/>
                    or use the offline editor from your toolkit installation. <br/>
                    Your version: {V.toolkit}<br/>
                    Required: {V.min}<br/>
                    USE AT YOUR OWN RISK!
                </div> : ( V.toolkit < V.last ? <div className="updatable-toolkit"> 
                    A <a href="https://www.avaa-toolkit.org/documentation#update" target="_blank">new version</a> of AVAA Toolkit is available.<br/>
                    Your version: {V.toolkit}<br/>
                    Available: {V.last}<br/>
                </div> : <></>)  }
                { V.toolkit && !V.ffmpeg ?
                        <div className="ffmpeg-missing">FFmpeg is missing<br/>
                        <Popup trigger={<a>install</a>} modal={true} closeOnDocumentClick={false} closeOnEscape={false} >
                            <FFMPEGInstaller />                            
                        </Popup>
                        </div> :
                        <></>
                }     
                {editor.converter.status ? <div className="avaa-status-bar">
                    <Popup className={"popup-status "+(calling?"popup-waiting-lock":'')} trigger={<span className="status-item">
                        <span className={editor.converter.status.assetsFree/editor.converter.status.assetsMax<0.1 ? 'free-space-warning' : '' }>Assets {Utils.humanFileSize(editor.converter.status.assetsUsage)}</span>
                        <i>{Math.round((editor.converter.status.assetsFree/editor.converter.status.assetsMax)*100)}% free</i>
                    </span>} closeOnDocumentClick={!calling} closeOnEscape={!calling} modal>          
                        {calling ? <FaSpinner className="spinner popup-waiting-spin" /> : <></>}
                        <h2>Assets
                            <a className={"file-name file-link"} onClick={ev=>editor.callOpenFile(editor.converter.status.assetsPath,ev)}><b>{editor.converter.status.assetsPath}</b></a>    
                        </h2> 
                        <p>AVAA Toolkit generates cuts and snapshots from media files, so only these smaller files need to be loaded by the HTML page.</p>
                        <p>The cache system avoids regenerating assets when they already exist, but the folder will grow as more and more assets are created.</p>
                        <p>The assets folder can be cleared safely, since all necessary assets will eventually be recreated when converting a document.</p>
                        <hr/>
                        <p>Assets disk usage is {Utils.humanFileSize(editor.converter.status.assetsUsage)}
                            <a onClick={ev=>clearAssets()}><b> clear all assets</b></a>
                         </p>
                    </Popup>
                   
                    <Popup className={"popup-status "+(calling?"popup-waiting-lock":'')} trigger={<span className="status-item">                        
                    <span className={editor.converter.status.tempFree/editor.converter.status.tempMax<0.1 ? 'free-space-warning' : '' }>Temp {Utils.humanFileSize(editor.converter.status.tempUsage)}</span>
                    <i>{Math.round((editor.converter.status.tempFree/editor.converter.status.tempMax)*100)}% free</i>
                    </span>} closeOnDocumentClick={!calling} closeOnEscape={!calling} modal >
                        {calling ? <FaSpinner className="spinner popup-waiting-spin" /> : <></>}
                        <h2>Temporary Files
                            <a className={"file-name file-link"} onClick={ev=>editor.callOpenFile(editor.converter.status.tempPath,ev)}><b>{editor.converter.status.tempPath}</b></a>
                        </h2>
                        
                        <p>By default, AVAA will create and use a <b>temp</b> folder in its installation root.</p>
                        <p>Temporary folder can be changed in the Config interface.</p>
                        <p>Voluminous writes on a SSD disk will reduce its life.<br/>
                         It is recommended to set the temporary folder on a non-SSD drive, if speed is not a concern.</p>
                         <hr/>
                         <p>Temporary files disk usage is {Utils.humanFileSize(editor.converter.status.tempUsage)}   
                            <a onClick={ev=>clearTemporaryFiles()}><b> clear temporary files</b></a>
                         </p>
                    </Popup>

                    <Popup className={"popup-status "+(calling?"popup-waiting-lock":'')} trigger={<span className="status-item avaa-config">
                        <span className={'avaa-config'}>Config <FaWrench />  </span>                        
                    </span>} closeOnDocumentClick={!calling} closeOnEscape={!calling} modal onClose={e=>reload()}>
                        <AVAAConfig />
                    </Popup>
                </div> : <></>}                
                {toOpen.length || activeFolders.length ? <table className={"open-file"}><tbody>{
                    toOpen.map( (f,i) => {
                        let sep=false;
                        let last;                        
                        if(!prev || prev.arg != f.arg){
                            last = prev?.arg;
                            prev = f;        
                            sep = true                    
                        }       
                        let afi = activeFolders.indexOf(f.arg);
                        if(afi!==-1) activeFolders.splice(afi,1);
                        //TODO refactor in sub components, remove dupe code
                        let r = <tr><td>{sep ? f.arg+'/' : ''}</td><td><a onClick={(e) => openFile(f,e)} href={"/#/"+f.arg+"/"+f.name}>{f.name}</a></td></tr>;                        
                        if(i===toOpen.length-1) r = <>{r}<tr className="last"><td></td><td><a onClick={()=>createFile(f.arg)}><FaPlusCircle size={13}/> new file</a></td></tr></>
                        return <React.Fragment key={f.path}>
                           {last ? <tr className="last"><td></td><td><a onClick={()=>createFile(last)}><FaPlusCircle size={13}/> new file</a></td></tr> : <></>}{r}
                        </React.Fragment>;
                    })}
                    {activeFolders.map( f=> <tr key={Math.random()} className="last"><td>{f}</td><td><a onClick={()=>createFile(f)}><FaPlusCircle size={13}/> new file</a></td></tr>)
                    }</tbody></table> : <div>no XML files or folders to edit. modify your launcher and add some.</div>}

                <div className="welcome-links">
                    <a target="_blank" href="/examples">
                        <FaRegLightbulb />
                        <b>examples</b>
                        <i>how-to collection</i>
                    </a>
                    <a target="_blank" href="/documentation">
                        <FaBookReader />
                        <b>documentation</b>
                        <i>for toolkit users</i>
                    </a>
                </div>  
            </div>
            : <div>              
                <div className="welcome-begin">
                    To begin, <a target="_blank" href="https://charles.protocoles.fr/static/avaa-toolkit/download"><b>download</b></a> and run AVAA Toolkit
                </div>
                <div className="welcome-links">
                    <a target="_blank" href="/features">
                        <FaWalking />
                        <b>main features</b>
                        <i>take a tour</i>
                    </a>
                    <a target="_blank" href="/examples">
                        <FaRegLightbulb />
                        <b>examples</b>
                        <i>how-to collection</i>
                    </a>
                    <a target="_blank" href="/documentation">
                        <FaBookReader />
                        <b>documentation</b>
                        <i>for toolkit users</i>
                    </a>
                    <a target="_blank" href="/scripting-guide">
                        <FaPuzzlePiece />
                        <b>scripting guide</b>
                        <i>for developers</i>
                    </a>
                </div>  
                <div className="contact-links">
                    get in touch by <Popup className="popup-contacts" 
                    trigger={<a>email</a>} modal>
                        <p>You can send an email at this address:</p>
                        <p><b>dev <i>[at]</i> avaa-toolkit <i>[dot]</i> org</b></p>
                    </Popup>
                </div>
            </div>    
            }
    </div>   
}