import Block from './Block'
import Domain from './Domain'
import BlockSection from "./BlockSection";
import Blocks, {useBlock, useForceUpdate} from "./Blocks";
import EditorContext from "../EditorContext";
import ButtonAdd from "../ui/ButtonAdd";
import Sections from "./Sections";
import {useCallback, useContext} from "react";
import AutosizeInput from 'react-input-autosize';
import { SketchPicker } from 'react-color';
import Popup from 'reactjs-popup';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { useTranslate } from '../contexts/TranslateContext';
import ButtonDelete from '../ui/ButtonDelete';
import { useUndo } from '../contexts/UndoContext';

export default function BlockStyle({node}) {
    const translate = useTranslate();
    const undo = useUndo();
    const forceUpdate = useForceUpdate();
    const contextType = useContext(EditorContext);
    const block = useBlock(node);

    const addSection = useCallback(() => {
        //file.document.documentElement.appendChild(Block.parseFromString('<SECTION title="untitled section"></SECTION>'));
        forceUpdate();
    }, [node]);
    const items = [];
    const sections = [];

    for (let c of node.getElementsByTagName("CHART_COLOR")) {
        block.idize(c)
        items.push({node: c, id: c._id});
        //console.info('SECTION ID', section._id);        
    }

    const onIdChange = (node,event) => {
        undo.snap(node,'id');
        node.setAttribute("id", event.target.value)
        //console.info(node,event);
        forceUpdate()
    }
    const onLabelChange = (node,event) => {
        undo.snap(node,'label');
        node.setAttribute("label", event.target.value)
        //console.info(node,event);
        forceUpdate()
    }
    const onColorChange = (node,value) => {
        //console.info(node,value);
        undo.snap(node,'color');
        node.setAttribute("color", value.hex)
        forceUpdate()
    }
    const onTypeChange = (node,event) => {
        //console.info(node,value);
        undo.snap(node,'type');
        node.setAttribute("type", event.target.value)
        forceUpdate()
    }

    const onMatchChange = (node,event) => {
        //console.info(node,value);
        undo.snap(node,'match');
        node.setAttribute("match", event.target.value)
        forceUpdate()
    }

    const addColor = ev => {
        undo.snap();
        node.appendChild(Block.parseFromString(`<CHART_COLOR id="" color="" />`));
        forceUpdate();
    }
    
    return <div className="editor-style crud-table">               
        <h2>Colors &amp; labels</h2>        
        {items.length ? <table className="no-top-left-corner">
            <thead><tr>
                <td></td>

                <td>Scope</td> 
                <td>Match</td>                
                <td>ID</td>
                <td>Color</td>
                {translate.langs[0]?.length>1 ?
                translate.langs[0].map(L=><td>{L.name}</td>)
                :<td>{translate.langs[0]?.length ? translate.langs[0][0].name : 'Label'}</td>}
                
            </tr></thead>
            <tbody>
        {items.map(o=>
            <tr key={o.id} className="chart-color-node">
                <td><ButtonDelete onClick={ev=>{
                    undo.snap();
                    forceUpdate(o.node.parentElement.removeChild(o.node))
                    }}/></td>
                    
                <td>
                    <select value={o.node.getAttribute("type")||''}  onChange={ ev=>onTypeChange(o.node,ev)}>
                        <option value="">any</option>
                        <option value="tier">tier</option>
                        <option value="value">value</option>
                    </select>
                </td>
                <td>
                    <select value={o.node.getAttribute("match")||''}  onChange={ ev=>onMatchChange(o.node,ev)}>
                        <option value="">equals</option>
                        <option value="contains">contains</option>                        
                    </select>
                </td>
                
            
        <td>
            <AutosizeInput
                    
                    type={"text"}
                    placeholder='id'
                    value={o.node.getAttribute("id") || ''}
                    onChange={ev=>onIdChange(o.node,ev)}
                />
                </td>
                <td>
                <Popup nested trigger={<button style={{backgroundColor:o.node.getAttribute("color")}} className="chart-color-square"> </button>} modal>            
                 <div>
                        <SketchPicker color={ o.node.getAttribute("color") }
                        onChange={ ev=>onColorChange(o.node,ev)} />
                </div> 
                </Popup>                
                </td>
                
                <td>
                
                <AutosizeInput
                    
                    type={"text"}
                    placeholder=''
                    value={o.node.getAttribute("label") || ''}
                    onChange={ev=>onLabelChange(o.node,ev)}
                />
                </td>
                {translate.langs[0].filter(L=>L.id!=translate.langs[0][0].id).map(L=><td>                  
                    <AutosizeInput
                    
                    type={"text"}
                    placeholder=''
                    value={o.node.getAttribute("label_"+L.id) || ''}
                    onChange={ev=>{
                        undo.snap(node,'label_'+L.id);
                        forceUpdate(o.node.setAttribute("label_"+L.id,ev.target.value))
                    }}
                />
                </td>)}
            </tr>
        )}   
        </tbody></table> : <></> }
        <ButtonAdd onClick={addColor}>add entry</ButtonAdd>        
        
                
    </div>

}