import ConverterSocket from "./converter/ConverterSocket";
import EventDispatcher from "./contexts/EventDispatcher";
import EditorFile from "./converter/EditorFile";
import ConverterSocketChannel from "./converter/ConverterSocketChannel";

export default class EditorContextObject extends EventDispatcher {
    foo = "FOo" + Math.random()
    blocks;

    numRuns=0;
    definitions;
    oldNames = {};
    converter:ConverterSocket;

    opened = [];
    selected;

    parallelChannel;
    autoOpenError = <></>;

    getDefinitionsOf(type){
        let keys = Object.keys(this.definitions[type]);
        return keys.filter(k=>{
            return !this.definitions[type][k].hidden;
        }).sort();
    }

    checkLocationHash(){
        //console.info("WINDOW HASH",window.location.hash)
        if(!this.checkedHash && this.converter.files.length){
            this.checkedHash = true;
            let hash = window.location.hash;
            if(hash.substring(0,2)=='#/'){            
                let path = decodeURI(hash.substring(2));
                console.info("Attempting to open file",path);
                let found = this.converter.files.filter(f=>f.path.substr(f.path.length-path.length)==path);
                if(found.length===1){
                    this.openFile(found[0].path);
                    this.setSelected(found[0]);            
                }else{
                    this.autoOpenError = <div className="auto-open-error">
                        <p>Sorry AVAA can't find this file on your computer:</p>
                        <p>{path}</p>
                    </div>         
                    console.warn("CANNOT FIND FILE",path);       
                }
            }
        }        
    }

    //avTypes = [];//["mov","mkv","mp4","avi","mts","mp3","aac","ogg","wav","opus","flac"]        
    //afTypes = [];//['tei','eaf','cha','textgrid']
    //proTypes = {};//{'srt':'subrip'}

    callFileDialog = (path,filter,cb) => {
        this.parallelCall("file-dialog", {filter: filter||'*',file:path}, cb);
    }

    callOpenFile(path){
        this.parallelCall("open-file", {
            browse: false,
            path: path
        });
    }
    callBrowseFile(path){
        this.parallelCall("open-file", {
            browse: true,
            path: path
        });
    }       

    parallelCall(func,args,cb){
        if(!this.parallelChannel) this.parallelChannel = new ConverterSocketChannel();
        return this.parallelChannel.call(func,args,cb);
    }

    openFile(path,selectIt) {
        //this.definitions = this.converter.definitions;
        let file = this.converter.files.filter(f => f.path == path)[0]
        console.info('openFile', file);        
        this.converter.call('get-file', {path: file.path}, r => {
            for(let k in this.oldNames){
                r.content = r.content.split("<OP "+k).join("<OP "+this.oldNames[k]);
            }
            file.load(r.content);
        });        
        this.opened.push(file)        
        if(selectIt) this.setSelected(file);       
        return file;
    }
    createFile(folder,name) {
        //this.definitions = this.converter.definitions;
        console.info('creating',folder,name);
        this.converter.call('create-file', {folder: folder, name:name}, (r,err) => {
            if(err) return alert(err.e || 'was an error. '+err);
            if(!r || r.error) return alert(r?.error||"error") //huh?
            this.converter.files.push(new EditorFile(r.file)); //TODO refactor
            this.openFile(r.file.path,true);
            //this.setSelected(file);
        });        
    }
    setSelected(f) {
        this.selected = f;
        this.dispatch("file-selected", f);
        //window.title = 'AVAA Toolkit';
    //if(editor.selected){
        if(!f){
            document.title = "AVAA Toolkit";
            return;
        }        
        let a = f.name.split('.');
        a.pop();
        document.title = a.join('.');
        window.location.hash = '#/'+f.folder+'/'+f.name;
    //}
    }

    constructor() {
        super()
        //console.info('EditorContextObject CREATED!')
        this.converter = ConverterSocket.getInstance();
        this.converter.call("get-definitions", {}, r => {
            console.info('Got definitions', r.definitions)
            this.definitions = r.definitions;
            this.definitions.tag.an = r.definitions.tag.select;

            for(let k in this.definitions.op){
                let op = this.definitions.op[k];
                if(op?.oldNames) op.oldNames.forEach(s=>this.oldNames[s]=k);                    
            }            
        })

    }
}