import Popup from "reactjs-popup";
import { useEditor } from "../../EditorContext";
import { edit } from "ace-builds";
import { useFile } from "../../contexts/FileContext";
import AutosizeInput from "react-input-autosize";
import { useState } from "react";

export default function PreviewAnnotations({list,children}){
    const editor = useEditor();
    const file = useFile();
    const [filter,setFilter] = useState('');
    const jumps = Object.values(editor.definitions.misc)
                        .filter(o=>o.id.substr(0,5)==='jump-');


    const callJump = (j,arg)=>{
        console.info(j,arg);
        editor.converter.call('jump',{
            id:j.id,
            arg:arg
        })
    }    
    const openFile = (path)=>{
        editor.callBrowseFile(path);
    }
    
    const annotations = (list||[]).filter(s=>{
        if(!filter) return true;
        let pass=true;
        for(let word of filter.split(' ')){
            if(!s.toLowerCase().includes(word.toLowerCase())) pass=false;
        }
        return pass;
    }).map(s=>{
        
        let o = {};
        let [left,right] = s.split(']');
        let [af,tier] = left.split('[');
        right = right.trim();
        o.af = af.substring(1).trim();
        o.tier = tier.trim();
        o.timings = right.substring(0,right.indexOf(' ')).trim();
        o.value = right.substring(o.timings.length).trim();
        o.start = o.timings.split('-')[0];
        o.stop = o.timings.split('-')[1];        
        if(file.domain) for(let k of Object.keys(file.domain.files)){
            if(k.includes(o.af)){
                let d = file.domain.files[k];
                o.file = d?.medias[0].path;
                o.fileName = k;
                o.filePath = d?.path;             
            }
        }
        return o;
    });
    return <Popup modal={true} trigger={children}>
        <div className="preview-annotations-filter">
         <AutosizeInput
                placeholder={"search"}
                type={"text"}
                value={filter}
                autoCorrect="off"
                autoComplete="off"                                
                onChange={e=>setFilter(e.target.value)}
        /><span>{annotations.length} annotations</span></div>
        <table className="preview-annotations">
            <thead></thead>
            <tbody>
            {annotations.map((o,i)=><tr key={i}>
                <td>{i+1}</td>
                <td><a onClick={e=>openFile(o.filePath)}>{o.fileName || o.af}</a></td>
                <td>{o.tier}</td>
                <td>{o.start}</td>
                <td>{o.stop}</td>
                <td className="value">{o.value}</td>
                <td>{jumps.map(j=><a key={j.id} title={j.label}
                     onClick={e=>callJump(j,o)}>
                        {j.img ? <img  className="jump-img" src={"data:image/png;base64, "+j.img} />
                         : <span>{j.label || j.id}</span>}
                </a>                    
                )}</td>
                
            </tr>)}
            </tbody>
        </table>        
    </Popup>
}