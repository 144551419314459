import Block from "./Block";
import React, {useCallback, useContext, useEffect, useState} from "react";
import ButtonRemove from "../ui/ButtonRemove";
import ButtonAdd from "../ui/ButtonAdd";
import ButtonToggle from "../ui/ButtonToggle";
import ConverterSocket from "../converter/ConverterSocket";
import AutosizeInput from "react-input-autosize/lib/AutosizeInput";
import EditorContext, { useEditor } from "../EditorContext";
import {useFile} from "../contexts/FileContext";
import {getTreeRef, useBlock, useForceUpdate} from "./Blocks";
import Utils from "../converter/Utils";
import { FaCheck, FaCloud, FaCloudDownloadAlt, FaDownload, FaFastForward, FaRegClock, FaTags, FaUserClock } from "react-icons/fa";
import Popup from "reactjs-popup";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useUndo } from "../contexts/UndoContext";
import RemoteCorpus from "../converter/RemoteCorpus";
import AttributesList from "../ui/AttributesList";
import DomainProvider from "./DomainProvider";
import { LearnStep } from "../ui/learn/StepLogger";
import TagListField from "../ui/TagListField";

export default function Domain({node}) {
    const [domain, setDomain] = useState(null)
    const [addingToDomainPopup, setAddingToDomainPopup] = useState(false)
    //const [beginningDate, setBeginningDate] = useState(new Date());
    const block = useBlock(node)
    const file = useFile()
    const undo = useUndo();
    const editor = useEditor();
    const forceUpdate = useForceUpdate()    

    const onDomainData = useCallback(data => {
        setDomain(data.detail)
    }, [node])

    const removeFile = useCallback(c => {
        undo.snap();
        node.removeChild(c);
        forceUpdate();
    }, [node])

    useEffect(() => {
        file.on('domain-data', onDomainData)        
        return () => {
            file.off("domain-data", onDomainData);
        }
    }, [node]);
    
    //console.info('HERE DOMAIN',domain);    
    const onFileDialogResult = r => {
        let def = editor.definitions;
        if (!r || !r.name) return;
        
        var all = def.afExtensions.concat(def.mfExtensions).concat(Object.keys(def.proExtensions)).concat(['xml']);        
        var ext = r.name.split('.').pop().toLowerCase();
        if(!ext || all.indexOf(ext)==-1) {
            console.info(all);
            alert("Sorry this file format is not recognized ("+ext+")");
            return;
        }
        //let c = node.children[0].cloneNode();
        
        let path = r.relative && r.relative.indexOf('../')==-1 ? r.relative : r.path;
        console.info('using path',path);
        let c;
        if(ext=='xml'){
            c = Block.parseFromString('<CORPUS path="" />');    
        }else if(ext in def.proExtensions){
            return addFromProvider({id:def.proExtensions[ext],file:path})
        }else {
            c = Block.parseFromString('<FILE group="" path="" />');
        }        
        c._id = Math.random();
        // use relative path only if not in a parent folder
        // TODO let user select what's best in this case
                
        c.setAttribute("path", path);
        undo.snap();
        node.append(c);
        setAddingToDomainPopup(false);
        forceUpdate();
    }

    const addFromProvider = (p) => {
        let c = Block.parseFromString('<PROVIDER id="" />');    
        c._id = Math.random();
        // use relative path only if not in a parent folder
        // TODO let user select what's best in this case        
        for(let k in p) c.setAttribute(k, p[k]);
        undo.snap();
        node.append(c);
        setAddingToDomainPopup(false);
        forceUpdate();
    }
    const addRemoteCorpus = (p) => {
        let c = Block.parseFromString('<CORPUS url="https://" />');    
        c._id = Math.random();
        // use relative path only if not in a parent folder
        // TODO let user select what's best in this case        
        
        undo.snap();
        node.append(c);
        setAddingToDomainPopup(false);
        forceUpdate();
    }
    const addFile = () => {
        ConverterSocket.getInstance().call("file-dialog", {filter: '*',file:file.path}, onFileDialogResult);
    }
    const browseFile = path => {
        
        if(path.charAt(0)=='.'){
            let rel =  file.path.split('/');
            rel.pop();
            path = rel.join('/')+'/'+path;
        }
        //console.info(path,file);        
        editor.parallelCall("open-file", {
            browse: true,
            path: path
        });
    }       
    const beginning = domain?.beginning||0;//block.getAtt('beginning')*1||0;
    const list = [];
    //console.info('DETAIL',detail);

    const addSeparator = (c)=>{
        list.push(<tr key={"separator-"+getTreeRef(c)} className="domain-separator"><td colSpan={100}></td></tr>)
    }

    var lastTag = '';
    for (let c of node.children){        
        switch(c.tagName){
            case 'CORPUS':                
                let corpusName = 'untitled corpus';
                let corpusID = '';
                let isDLC = c.getAttribute("url");
                if(isDLC){
                    corpusID = c.getAttribute("url");
                }else{
                    corpusID = c.getAttribute("path");
                }
                corpusName = corpusID.split('/').pop();
                addSeparator(c);
                if(isDLC){
                    list.push(<tr key={corpusName} className="corpus-source">
                        <td><ButtonRemove onClick={() => removeFile(c)}/></td>
                        <td colSpan={100}>
                            <b className="corpus-source-type">Remote Corpus</b>                            
                            <DomainRemoteCorpus node={c} file={file} id={corpusID} />                            
                            {domain && domain.errors && domain.errors['CORPUS:'+corpusID] ? <b className="error-color">{domain.errors['CORPUS:'+corpusID]}</b> : <></>}
                    </td>
                    </tr>);
                }else{
                    list.push(<tr key={corpusName} className="corpus-source">
                        <td><ButtonRemove onClick={() => removeFile(c)}/></td>
                        <td colSpan={100}>
                            <b className="corpus-source-type">AVAA Corpus</b>                                                       
                            <i><a className="file-link" onClick={ev=>browseFile(corpusID)}>{corpusName}</a></i>
                            {domain && domain.errors && domain.errors['CORPUS:'+corpusID] ? <b className="error-color">{domain.errors['CORPUS:'+corpusID]}</b> : <></>}
                    </td>
                    </tr>);
                }
                //console.info('NOW DOING THIS SHIT')
                if(domain) for(let k in domain.domain){
                    let fname = k;
                    if(!domain.domain[k]){
                        //TODO list.push(wtf)
                        continue;
                    }
                    let ref = getTreeRef(c);
                    //console.info(k,'REF=',ref);
                    let isRemoteCorpusFile = false;
                    if(k.includes("CORPUS") && ref.includes("CORPUS")){
                        let c1 = k.split('CORPUS[')[1].split(']')[0];
                        let c2 = ref.split('CORPUS[')[1].split(']')[0];
                        //console.info('C1 C2',c1,c2)
                        isRemoteCorpusFile = c1===c2;
                        if(isRemoteCorpusFile) fname = domain.domain[k].path; 
                    }

                    let corp = domain.domain[k].corpus;
                    //console.info(c,corpusName);
                    if(!isRemoteCorpusFile){
                        if(!corp || corp.split('/').pop()!=corpusName) continue;
                    }
                    //TODO error handling is shared among corpii, it's bad
                    list.push(
                        <tr key={k} className={"editor-domain-file corpus-domain-file"}>
                            <td></td>
                            <CorpusDomainFile beginning={beginning} name={fname} detail={domain.domain[k]} />
                        </tr>
                    )
                }
                break;
            case 'PROVIDER':
                let provider = c.getAttribute("id")
                let logo = editor.definitions.provider[provider].logo;
                addSeparator(c);
                list.push(        
                <tr key={c._id} className={"editor-domain-provider"}>
                    <td><ButtonRemove onClick={() => removeFile(c)}/></td>
                    <td colSpan={100}>
                    <span className="domain-provider-config">
                    {logo ? <img title={provider} className="provider-logo" src={"data:image/png;base64, "+logo} />: <b className="provider-id">{provider}</b>}
                    <AttributesList isEdit={true} deletable={true} node={c}
                                    accepted={editor.definitions.provider[provider].attributes} hiddenAttributes={['id']}/>
                                    
                    </span>
                    <span className="domain-provider-step-logger"><DomainProvider node={c} /></span>
                    </td>            
                </tr>
                )
                if(domain) for(let k in domain.domain){
                    if(!domain.domain[k]){
                        //TODO `list.push(wtf)
                        continue;
                    }
                    let dk = domain.domain[k].domainKey;
                    //console.info(k,'=>',dk);
                    if(dk!=provider+':'+getTreeRef(c)) continue;
                    //if(!cid || cid.split('/').pop()!=corpusName) continue;
                    //TODO error handling is shared among corpii, it's bad
                    list.push(
                        <tr key={k} className={"editor-domain-file corpus-domain-file"}>
                            <td></td>
                            <CorpusDomainFile af={""} timeOffsetable={false} beginning={beginning} name={domain.domain[k].path} detail={domain.domain[k]} />
                        </tr>
                    )
                }
                break;
            case 'FILE':
                if(lastTag!='FILE') addSeparator(c);
                list.push(
                    <tr key={c._id} className={"editor-domain-file"}>
                        <td><ButtonRemove onClick={() => removeFile(c)}/></td>
                        <DomainFile node={c} domain={domain} detail={domain?.domain} beginning={domain?.beginning||block.getAtt("beginning")*1||0}/>
                    </tr>)
                break;

        }
        lastTag = c.tagName;
    }

/*    for (let c of node.children) if(c.tagName=='CORPUS'){        
        let corpusName = 'untitled corpus';
        let corpusID = '';
        let isDLC = c.getAttribute("url");
        if(isDLC){
            corpusID = c.getAttribute("url");
        }else{
            corpusID = c.getAttribute("path");
        }
        corpusName = corpusID.split('/').pop();
        list.push(<tr key={corpusName} className="domain-separator"><td colSpan={100}></td></tr>)
        list.push(<tr key={corpusName} className="corpus-source"><td><ButtonRemove onClick={() => removeFile(c)}/></td><td colSpan={100}><b>{isDLC ? "Remote " : <></>}Corpus <i><a onClick={ev=>browseFile()}>{corpusName}</a></i></b>
        {domain && domain.errors && domain.errors['CORPUS:'+corpusID] ? <b className="error-color">{domain.errors['CORPUS:'+corpusID]}</b> : <></>}
        {
            isDLC ? <DomainRemoteCorpus node={c} file={file} id={corpusID} subset={c.getAttribute("subset")}/>
                
                : <></>
        }
        </td>
        </tr>);
        if(domain) for(let k in domain.domain){
            if(!domain.domain[k]){
                //TODO list.push(wtf)
                continue;
            }
            let c = domain.domain[k].corpus;
            //console.info(c,corpusName);
            if(!c || c.split('/').pop()!=corpusName) continue;
            //TODO error handling is shared among corpii, it's bad
            list.push(
                <tr key={k} className={"editor-domain-file corpus-domain-file"}>
                    <td></td>
                    <CorpusDomainFile beginning={beginning} name={k} detail={domain.domain[k]} />
                </tr>
            )
        
    }
    }*/
    
    //let beginningDate = beginning ?? 
    const onBeginningChange = (date) => {
        undo.snap('domain-beginning');
        block.setAtt('beginning', date ? date.getTime() : "");
        forceUpdate()
    } 
    return <div className="corpus-domain proof">
        <h1>Corpus   <span className="beginning">
            <DatePicker 
            title="select the date and time at which your events began"            
            placeholderText="DD/MM/YYYY"            
            isClearable={true}
            showYearDropdown={true}
            showTimeInput={true}
            dateFormat={"dd/MM/yyyy"}
            showFullMonthYearPicker={true}
            selected={ block.getAtt("beginning") ? new Date(block.getAtt("beginning")*1||0) :undefined} onChange={onBeginningChange}  />        
        </span></h1>
        <div className="crud-table">            
            <table className={"editor-domain"}>
                <tbody>
                {list}
                
                </tbody>
            </table>
            <ButtonAdd onClick={ev=>setAddingToDomainPopup(true)}>add to corpus</ButtonAdd>
            <Popup className="add-to-domain" open={addingToDomainPopup} modal onClose={ev=>setAddingToDomainPopup(false)} closeOnDocumentClick={true}>
                <div className="classy-item" onClick={addFile}>
                    <a>add a file from your computer</a>
                    <ul>
                        <li>Annotations: {editor.definitions.afExtensions.join(", ").toUpperCase()}</li>
                        <li>Media Files: {editor.definitions.mfExtensions.join(", ").toUpperCase()}</li>
                    </ul>
                    
                </div>
                <div className="classy-item" onClick={addFile}>
                    <a>add a corpus from another AVAA XML file</a>
                    <label>Multiple AVAA documents can share the same corpus, allowing updates in one single place</label>
                </div>
                <div  className="classy-item" onClick={addRemoteCorpus}>
                    <a>add a Remote Corpus</a>
                    <label>Easily download all required corpora files from a simple <i title="a Remote Corpus Descriptor is a XML file containing a list of corpus media files URL, so they can be automatically downloaded by AVAA Toolkit" className="title-info">Remote Corpus Descriptor</i> URL</label>
                </div>
                {Object.values(editor.definitions.provider).filter(p=>!p.hidden).map(p=>
                <div className="classy-item" key={p.id} onClick={ev=>addFromProvider({id:p.id})}>
                    <a>{p.label ? p.label : 'add from provider - ' +p.id}</a>
                    {p.logo ? <img title={p.id} className="provider-logo" src={"data:image/png;base64, "+p.logo} />: <b className="provider-id">{p.id}</b>}
                    {p.desc ? <label>{p.desc}</label> : <></>}
                    
                </div>
                )}                
            </Popup>        
        </div>
    </div>

}

function DomainRemoteCorpus ({node,id}){    
    const [state,setState] = useState(null);
    const subset=node.getAttribute("subset");
    const rc = RemoteCorpus.get(id,subset);
    const forceUpdate = useForceUpdate();
    const file = useFile()
    const block = useBlock(node)
    useEffect(() => {
        rc.on('download-progress', forceUpdate);
        rc.on('status', forceUpdate)        
        return () => {
            rc.off("download-progress", forceUpdate);
            rc.off('status', forceUpdate)        
        }
    }, [id]);
    const onDomainData = useCallback(data => {
        let r1 = data.detail.split(' > ')[0];
        let r2 = block.getRef().split(' > ')[2];
        console.info(r1,r2);
        if(r1!=r2) return;
        console.info('onCorpusData',r1)
        setState(file.learnDomain[data.detail]);
        //if(block.getRef()==data.detail){
            //setState(file.learnDomain[block.getRef()]);
        //}
        //setDomain(data.detail)
    }, [node])
    useEffect(() => {
        file.on('learn-domain', onDomainData)        
        return () => {
            file.off("learn-domain", onDomainData);
        }
    }, [node]);
    rc.check();    
    const attributes = [
        {type:'string',name:'url',desc:'URL of the remote corpus XML'},
        {type:'string',name:'subset',desc:'Subset of the corpus to load'}
    ]
    return <span>                             
            {rc.downloading ? <span><b className="remote-corpus-downloading"><FaDownload /> downloading... {rc.progress.percent}% </b><i>{Utils.humanFileSize(rc.progress.downloaded || 0)} / {Utils.humanFileSize(rc.size)}</i></span> : <></>}
            {rc.downloadable && !rc.downloading? <a onClick={()=>rc.download()}><FaDownload /> download remote corpus ({Utils.humanFileSize(rc.size)}) </a> : <></>}
            {rc.downloaded ? <b className="remote-corpus-downloaded" title="corpus is downloaded and verified"><FaDownload /></b> : <></>}       
            {rc.downloaded && !rc.size ? <b>CORPUS IS EMPTY!!!</b> : <></>}       

            <AttributesList isEdit={true} deletable={false} node={node}
                            accepted={attributes} />
            <div>
            {state && state.steps ? state.steps.map((o,i)=><LearnStep key={i} step={o}/>) : <></>}      
            </div>
    </span>
}


//TODO refactor dupe code
function DomainFile({node,detail,beginning,domain}) {
    //const [detail, setDetail] = useState(null)
    const block = useBlock(node)
    const file = useFile()
    const undo = useUndo();
    const forceUpdate = useForceUpdate()
    const editor = useContext(EditorContext)
    
    const setEnabled = useCallback(state => {
        undo.snap(node,'disabled')
        block.setAtt('disabled', state ? "false" : "true");
        forceUpdate();
    }, [node])

    const onGroupChange = useCallback(event => {
        undo.snap(node,'group')
        block.setAtt('group', event.target.value);
        forceUpdate();
    }, [node])
    const onOffsetChange = useCallback(value => {
        undo.snap(node,'time-offset')
        block.setAtt('time-offset', value);        
        forceUpdate();
    }, [node])
    const simpleFormat = s=>{
        if(!s) return s;
        var a = s.split(',');
        a.length=3;
        return a.join(' ');
    }
    
    const showMediaFileInFolder = path => {
        editor.converter.call("open-file", {
            browse: true,
            path: path
        });
    }   
    //<td title={o.details.format}>{simpleFormat(o.details.format)}</td> 
    var path = block.getAtt("path");
    //console.info("BLOCK",node);
    var fileName = path.split(path.indexOf('/')>-1 ? '/' : '\\').pop();
    let details = <></>     
    if(domain?.errors && domain.errors[fileName]){ //TODO handle with real paths
        //console.info('ERRORRR',);
        details = <td className="media-file-error" colSpan={"100"}><b>{domain.errors[fileName]}</b></td>
    }
    let d;
    if (detail && detail[path]){
        d = detail[path];
    }else if(detail && path){
        d = Object.values(detail).filter(o=>o?.domainKey==path)[0];
    }
    if(d){
       
        let medias = d.medias && d.medias.length ? 
            d.medias.map(o => <tr key={o.path}><td className="media-file"><i onClick={e=>showMediaFileInFolder(o.path)} title={o.path}>{o.name}</i> </td>
            {o.error ? <><td colSpan="100" className="media-file-error">{o.error}</td></> : <>            
            <td>{o.details.width ? o.details.width +"x"+o.details.height : Utils.humanFileSize(o.details.bitRate,true)+'/s'}</td>
            <td>{Utils.humanFileSize(o.details.size)}</td>
            <td><b>{Utils.toHHMMSS(o.details.duration)}</b></td></>}
            {o.timeOrigin ? <td title="special time origin" className="time-origin"><FaFastForward />  {Utils.toHHMMSS(Math.round(o.timeOrigin/1000))}</td> : <></>}            
            </tr>)        
            
            : <></>
        
        details =<>           
                <td title={d.annotations + " annotations"}>{d.annotations} </td>
                <td title={d.tiers+" tiers"}>{d.tiers} </td>
                
                <td title={d.participants + " participants"}>{d.participants} </td>                
                <td><table className="medias"><tbody>{medias}</tbody></table></td>
            
            </>
    }

    return <>    
        <td><AutosizeInput
            placeholder={'group'}
            type={"text"}
            value={block.getAtt("group")}
            onChange={onGroupChange}
            minWidth={50}

        /></td>
        <td className="domain-file-tags">
           <DomainFileTags node={node} editable={true}/> 
        </td>
        <td title={"time offset"}>
            <TimeOffsetSelector           
            beginning={beginning}
            label="Time offset relative to corpus beginning" 
            value={block.getAtt("time-offset")}
            onChange={onOffsetChange}                  
        /></td>
        <td>        
        <ButtonToggle onClick={setEnabled} toggled={!block.isAttTrue("disabled")}
                            label={fileName}/>
        </td>        
        {details}        
        </>
    
    
}
function TimeOffsetSelector ({value,onChange,label,beginning}){    
    const valueToName = (v,first) => {        
        let o = parseValue(v*1||0);
        let s = '';
        if(first && o.days>800) return `${Math.round(o.days/365)} years…`;
        if(first && o.days>100) return `${Math.round(o.days/31)} months…`;
        if(o.days) s += `${o.days} day${o.days>1?'s':''} `;
        if(o.days && first) return s + (o.hours ? '…' : '');
        if(o.hours) s += `${o.hours} hour${o.hours>1?'s':''} `;
        if(o.hours && first) return s + (o.minutes ? '…' : '');
        if(o.minutes) s += `${o.minutes} min`;
        if(o.minutes && first) return s + (o.seconds ? '…' : '');
        if(o.seconds) s += `${o.seconds} sec`;  
        if(!o.seconds && o.msec && first) return `${o.msec} ms`
        return s;
    }
    const parseValue = milliseconds =>{
        let o = {};
        let s = Math.floor(milliseconds/1000);
        o.msec = milliseconds - (s*1000);
        o.days = Math.floor(s / (3600*24));
        s %= (3600*24);
        o.hours = Math.floor(s / 3600);
        s %= 3600;
        o.minutes = Math.floor(s / 60);
        o.seconds = s % 60;
        return o;
    }
    const calc = o => {
        return o.msec*1 + o.seconds*1000 + o.minutes*1000*60 + o.hours*1000*3600 + o.days*1000*3600*24
    }
    let o = parseValue(value || 0);
    const onInput = (v,t) => {
        console.info(v,t);
        o[t]= v;
        onChange && onChange(calc(o));
    } 
    const onDatePicked = (date) => {
        onChange && onChange(date.getTime() - beginning);
        //block.setAtt('beginning',date.getTime());
        //forceUpdate()
    } 
    
    return <> <Popup trigger={<button className="time-offset-selector"> +{valueToName(value,true)}</button>} modal>            
    <div className="time-offset-selector">
    <p>{label}</p>
    <label><input type="number" min="0" max="" value={o.days} onChange={ev=>onInput(ev.currentTarget.value,'days')}/> days</label>
    <label><input type="number" min="0" max="24" value={o.hours} onChange={ev=>onInput(ev.currentTarget.value,'hours')}/> hours</label>
    <label><input type="number" min="0" max="60" value={o.minutes} onChange={ev=>onInput(ev.currentTarget.value,'minutes')}/> minutes</label>
    <label><input type="number" min="0" max="60" value={o.seconds} onChange={ev=>onInput(ev.currentTarget.value,'seconds')}/> seconds</label>
    <label><input type="number" min="0" max="1000" value={o.msec} onChange={ev=>onInput(ev.currentTarget.value,'msec')}/> milliseconds</label>
    
    {beginning ? <label><br/> Or pick a date <DatePicker 
            className="date-picker"
            placeholderText="pick date"
            showYearDropdown={true}
            showTimeInput={true}
            dateFormat={"dd/MM/yyyy"}
            showFullMonthYearPicker={true}
            selected={ value ? new Date(beginning+value*1) : new Date(beginning*1)} onChange={onDatePicked}  /> 
            {beginning && value ? <div><i>{new Date(beginning*1+value*1).toUTCString()}</i></div> : <></>}</label> : <></>}
            <p>That's {value || 0} milliseconds.</p>    
    </div>
</Popup></>    
}

function CorpusDomainFile({beginning, name, detail, af, timeOffsetable}) {    
    const file = useFile()    
    const editor = useContext(EditorContext)
        
    const simpleFormat = s=>{
        if(!s) return s;
        var a = s.split(',');
        a.length=3;
        return a.join(' ');
    }
    
    const showMediaFileInFolder = path => {
        editor.converter.call("open-file", {
            browse: true,
            path: path
        });
    }
    //<td title={o.details.format}>{simpleFormat(o.details.format)}</td> 
    //var path = block.getAtt("path");
    var fileName = name; //nam.split(path.indexOf('/')>-1 ? '/' : '\\').pop();
    let fna = fileName.split('/');
    let fnai = fna.indexOf("avaa-toolkit");
    if(fnai>-1) {
        while(fnai>-1){
            fna.shift();
            fnai--;
        }
        fileName = fna.join('/');
    }
    let details = <></>        
    if (detail) {
        let d = detail;
        let medias = d.medias && d.medias.length ? 
            d.medias.map(o => <tr key={o.path}><td className="media-file"><i onClick={e=>showMediaFileInFolder(o.path)} title={o.path}>{o.name}</i> </td>
            {o.error ? <><td colSpan="100" className="media-file-error">{o.error}</td></> : <>            
            <td>{o.details.width ? o.details.width +"x"+o.details.height : Utils.humanFileSize(o.details.bitRate,true)+'/s'}</td>
            <td>{Utils.humanFileSize(o.details.size)}</td>
            <td><b>{Utils.toHHMMSS(o.details.duration)}</b></td></>}
            {o.timeOrigin ? <td title="special time origin" className="time-origin"><FaFastForward />  {Utils.toHHMMSS(Math.round(o.timeOrigin/1000))}</td> : <></>}            
            </tr>)                
            
            : <></>
        
        details =<>
            
                <td title={d.annotations + " annotations"}>{d.annotations} </td>
                <td title={d.tiers+" tiers"}>{d.tiers} </td>
                <td title={d.participants + " participants"}>{d.participants} </td>
                <td><table className="medias"><tbody>{medias}</tbody></table></td>
            
            </>
    }

    let shortName = fileName.split('\\').join('/').split('/');
    while(shortName.length>2) shortName.shift();
    shortName = shortName.join('/');

    if(af!==undefined) shortName = af+'';

    return <>    
        <td className="domain-file-group">{detail.group}</td>
        <td className="domain-file-tags"><DomainFileTags tags={detail.tags}/></td>
        <td title={"time offset"} className="time-offset">
            {timeOffsetable ?
            <span style={{pointerEvents:'none'}}>
            <TimeOffsetSelector           
            /*beginning={beginning}*/
            label="Time offset relative to corpus beginning" 
            value={detail.timeOffset}            
        /></span> : <></>}
        {beginning ? <span className="corpus-file-date">{new Date(beginning*1+detail.timeOffset*1||0).toUTCString()}</span> : <></>}
        </td>        
        
        <td>        
        <span className="af-name file-link" onClick={e=>showMediaFileInFolder(detail.path)}>{shortName}</span>
        </td>        
        {details}        
        </>
    
    
}

function DomainFileTags({tags,node}){
    const editable = !!node;
    const forceUpdate = useForceUpdate();
    tags = tags || [];
    if(node) tags = (node.getAttribute("tags")||'').split(',');
    if(tags[0]==='') tags = [];    
    const onChange = (tags)=>{        
        node.setAttribute('tags',tags.join(','));
        forceUpdate()
    }

    return <TagListField tags={tags} editable={editable} onChange={onChange} titleNoTag={"No tag defined for this file"}/>
}