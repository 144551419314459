import logo from './logo.svg';
import './App.css';
import Tab from './ui/Tab';
import React, {useContext, useEffect, useState} from "react";
import {useConverter} from "./converter/Converter";
import {useForceUpdate} from "./blocks/Blocks";
import ConverterContext from "./converter/ConverterContext";
import ConverterSocket from "./converter/ConverterSocket";
import ConverterStatus from "./converter/ConverterStatus";
import FileTabs from "./ui/FileTabs";
import {FaSpinner} from "react-icons/fa";
import EditorContext from "./EditorContext";
import {useUser,UserContext} from "./contexts/UserContext";
import AppLayout from "./ui/AppLayout";

function App() {
    //const conv = useConverter({onStatusChange: update, onFilesList: update})
    const update = useForceUpdate()
    const [converter, setConverter] = useState(ConverterSocket.getInstance())
    const editor = useContext(EditorContext)
    const user = useUser();

    useEffect(() => {
        converter.onStatusChange = update;
        converter.onMessage = update;
    }, [converter]);
    
    return (
        <UserContext.Provider value={user}>
            <EditorContext.Provider value={editor}>            
                <AppLayout/>
            </EditorContext.Provider>
        </UserContext.Provider>
    )
}

export default App;
