import Block from "./Block";
import React, {useContext, useState} from "react";
import AttributesList from "../ui/AttributesList";
import EditorContext from "../EditorContext";
import {FaArrowRight, FaCodeBranch, FaCogs, FaFolderPlus, FaQuestion, FaTag} from "react-icons/fa";
import BlockContainer from "./BlockContainer";
import Level from "./Level";
import LevelIcon from "./LevelIcon";
import ButtonEdit from "../ui/ButtonEdit";
import AttributeField from "../ui/AttributeField";
import HoverActions from "../ui/HoverActions";
import BlockLocal from "./BlockLocal";
import {useAddy, useForceUpdate} from "./Blocks";
import BlockSelect from "./BlockSelect";
import BlockOp from "./BlockOp";
import ButtonAdd from "../ui/ButtonAdd";
import BlockComment from "./BlockComment";
import BlockFilter from "./BlockFilter";
import LearnItem from "../ui/LearnItem";
import NodeActions from "../ui/NodeActions";
import BlockSet from "./BlockSet";
import BlockStack from "./BlockStack";
import BlockUnstack from "./BlockUnstack";
import BlockSave from "./BlockSave";
import BlockLoad from "./BlockLoad";
import BlockExtra from "./BlockExtra";
import BlockTest from "./BlockTest";
import BlockHTML from "./BlockHTML";

export default function BlockIf({node, isEdit, blocks, onNodeAction, onAction, parentAddy}) {
    const context = useContext(EditorContext)
    const forceUpdate = useForceUpdate()
    const [stateEdit, setEdit] = useState(true)//(!!isEdit) || !node.childNodes.length)
    
    const addy = useAddy({
        node, update: forceUpdate,        
        types: [BlockSelect, BlockOp, BlockFilter, BlockStack, BlockUnstack, BlockSave, BlockLoad, BlockExtra, BlockIf, BlockLocal, BlockTest, BlockComment],
        container: BlockIf,
        containerType: "if"
    })

    function toggleEdit() {
        setEdit(!stateEdit)
    }
    
    return <>
        <LevelIcon><FaCodeBranch/></LevelIcon> <b>if </b>
        <AttributeField placeholder={"condition statement"} hideEquals={true}  hideName={true} type={"js"} node={node} att={"condition"}/>
        
        <HoverActions><NodeActions node={node} onAction={onAction} addy={parentAddy}/></HoverActions>
        <LearnItem node={node} type={BlockIf}/>
        <Level isEdit={stateEdit}>
            
            <BlockContainer isEdit={stateEdit} node={node} blocks={blocks} onNodeAction={onNodeAction} onAction={onAction} parentAddy={addy.box}/>
            {stateEdit ? addy.box : <></>}            
        </Level>
    </>
}
BlockIf.learn = {
    render: o => {
        return <span>
        <span className={"color-if"}>
            {o.isTrue ? <b className="color-if-true"><FaCodeBranch /></b> : <></>}
            {o.isFalse ? <b className="color-if-false"><FaCodeBranch/></b> : <></>}
            {!o.isTrue && !o.isFalse ? <b className="color-if-result"><i>{o.result}?</i></b> : <></>}
        </span>
    </span>
    }
}
BlockIf.addy = {
    icon: <span title={"add conditional branch"}><FaCodeBranch /></span>,
    make: ()=>Block.parseFromString('<IF></IF>')    
}