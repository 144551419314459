import { useCallback, useContext, useEffect, useState } from "react";
import { useFile } from "../contexts/FileContext";
import { useUndo } from "../contexts/UndoContext";
import { useBlock, useForceUpdate } from "./Blocks";
import EditorContext from "../EditorContext";
import { LearnStep } from "../ui/learn/StepLogger";

export default function DomainProvider({node}) {
    const [state,setState] = useState(null);
    const block = useBlock(node)
    const file = useFile()
    const undo = useUndo();
    const editor = useContext(EditorContext)
    const forceUpdate = useForceUpdate()    

    const onDomainData = useCallback(data => {
        console.info('onDomainData',data.detail)
        if(block.getRef()==data.detail){
            setState(file.learnDomain[block.getRef()]);
        }
        //setDomain(data.detail)
    }, [node])

    const removeFile = useCallback(c => {
        undo.snap();
        node.removeChild(c);
        forceUpdate();
    }, [node])

    useEffect(() => {
        file.on('learn-domain', onDomainData)        
        return () => {
            file.off("learn-domain", onDomainData);
        }
    }, [node]);

    return <div>        
        <div>
            {state && state.steps ? state.steps.map((o,i)=><LearnStep key={i} step={o}/>) : <></>}      
        </div>
    </div>
}