import EventDispatcher from "../contexts/EventDispatcher";
import EditorFile from "./EditorFile";
import RemoteCorpus from "./RemoteCorpus";

export default class ConverterSocket extends EventDispatcher {
    static instance;

    static getInstance() {
        if (!ConverterSocket.instance) ConverterSocket.instance = new ConverterSocket();
        return ConverterSocket.instance;
    }

    url = "ws://127.0.0.1:42042/atk"
    ws;
    ready = false;
    calls = 0;
    callbacks = {};
    promises = {};
    files = [];
    folders = [];
    opened = [];
    onStatusChange;
    onMessage;
    onHello;
    lastMessage = '?';
    queue = [];
    version = {};

    reconnect() {
        //console.info('connect()');
        this.ws = new WebSocket(this.url);
        this.ws.onmessage = this.onSocketMessage.bind(this);
        this.ws.onerror = this.onSocketError.bind(this);
        this.ws.onopen = this.onSocketOpen.bind(this);
        //this.ws.connect(this.url);
    }


    call(cmd, params, cb) {
        let o = {};
        o._ = ++this.calls;
        o.a = cmd;
        o.p = params;
        this.callbacks[o._] = cb || null;
        if (!this.ready || this.ws.readyState === 0) {
            this.queue.push(o)
            return;
        }
        if (this.ws.readyState > 1) {
            this.ready = false;
            this.queue.push(o)
            this.reconnect();
            return;
        }
        this.ws.send(JSON.stringify(o));

        /*let p = this.promises[o._] = new Promise(function (resolve, reject) {
            var server = new WebSocket('ws://mysite:1234');
            server.onopen = function () {
                resolve(server);
            };
            server.onerror = function (err) {
                reject(err);
            };
        });
        return p;*/
    }

    cbUpdate;

    close() {
        //console.info("CLOSING CONVERTERSOCKET!!!");
    }


    onSocketOpen(ev) {
        //console.log('OPEN', ev);
        this.ready = true;
        this.onStatusChange && this.onStatusChange();
        this.queue.forEach(o => this.ws.send(JSON.stringify(o)));
        this.queue.length = 0;
        this.call('version', {}, r => {
            this.version = r;
            r.min = 0.60;
            r.last = 0.60;            
            if(r.toolkit==='dev') r.toolkit=100;            
            else r.toolkit = r.toolkit*1 || 0;
            if(r.toolkit>=0.60) this.doHello();
        });        
        this.call('get-definitions', {}, r => this.definitions = r);
        this.loadStatus();
        if(!this.folders.length) this.call('list-folders', {}, r => this.folders = r);
        if(!this.files.length) this.call('list-files', {}, r => this.files = r.map(o => new EditorFile(o)));
    }    
    hello;
    doHello(){        
        this.hello={loading:true};
        this.onHello && this.onHello();
        this.call('hello', {}, r => {
            this.hello = r;  
            this.onHello && this.onHello();    
        });
    }
    reloadFolders(cb){
        this.loadStatus();
        this.call('list-folders', {}, r => {
            this.folders = r;
            this.call('list-files', {}, r => {
                this.files = r.map(o => new EditorFile(o));
                cb && cb();
            });
        });
        
    }
    loadStatus(cb){
        this.call('get-status', {}, r => {
            this.status = r;
            console.info('Got status',r);
            if(cb) cb();
        });
    }

    onSocketError(ev) {
        //console.log('ERROR', ev);
        this.ready = false;
        this.onStatusChange && this.onStatusChange();
        setTimeout(this.reconnect.bind(this), 5000);
    }

    tickets = {};
    onSocketMessage(ev) {
        //console.info('MESSAGE', ev.data);
        let o = JSON.parse(ev.data);
        this.lastMessage = ev.data;
        this.onMessage && this.onMessage();
        if (this.callbacks[o._]) {
            this.callbacks[o._](o.r,o.e);
            delete this.callbacks[o._];
        }
        if(o._=='corpus-download-progress'){
            RemoteCorpus.get(o.url,o.subset).onDownloadProgress(o)            
            //let f = this.files.filter(f=>f.path==o.file)[0];
            
            //if(f) f.setConvertProgress(o);
            return;
        }
        if(o._=='ticket'){
            console.info('ticket completed',o);
            let cb = this.tickets[o.ticket]?.onComplete;
            if(cb) cb(o.result,o.error);
            return;
        }
        if(o._=='progress'){
            //console.info(o);
            let f = this.files.filter(f=>f.path==o.file)[0];
            
            if(f) f.setConvertProgress(o);
            return;
        }
        if(o._=='processor-progress'){
            //console.info('PROCESSOR',o.proc);
            let f = this.files.filter(f=>f.path==o.file)[0];          
            if(!f.proc) f.proc = {};
            f.proc[o.proc.id] = o.proc;
            f.dispatch("learn-proc", o.proc.id)  
            f.dispatch("processor-progress", o.proc)  
            return;
        }
        if(o._=='domain-progress'){
            console.info('domain-progress',o);
            let f = this.files.filter(f=>f.path==o.file)[0];          
            if(!f.learnDomain) f.learnDomain = {};
            f.learnDomain[o.logger.id] = o.logger;
            f.dispatch("learn-domain", o.logger.id)  
            return;
        }
        if(o._=='corpus-progress'){
            //console.info('corpus-progress',o);
            let f = this.files.filter(f=>f.path==o.file)[0];                      
            f.dispatch("corpus-progress", {corpus:o.corpus,ticket:o.ticket});
            return;
        }
        if(o._=='learn-progress'){
            //console.info(o);
            let f = this.files.filter(f=>f.path==o.file)[0];          
            //if(!f.proc) f.proc = {};
            f.learn = o.learn;
            f.learn.processing = true;
            f.dispatch("learn-data", f.learn)
            f.dispatch("learn-progress", f.learn)
            //f.proc[o.proc.id] = o.proc;
            //f.dispatch("learn-live", o.proc.id)  
            return;
        }
        if(o._=='learn-block-enter'){
            //console.info(o);
            let f = this.files.filter(f=>f.path==o.file)[0];                      
            f.dispatch("learn-block-enter", o.block);
            f.dispatch("block-progress", o.block);
            //f.proc[o.proc.id] = o.proc;
            //f.dispatch("learn-live", o.proc.id)  
            return;
        }
        if(o._=='deploy'){
            //console.info('deploy',o);
            let fxml = o.file.split('.zip').join('.xml');            
            this.files.forEach(fff=>console.info('PATH =',fff.path));
            let f = this.files.filter(f=>f.path==fxml)[0];
            if(f) f.setDeployProgress(o);
            
            //let f = this.files.filter(f=>f.path==o.file)[0];
            
            return;
        }
        if(o._=='avaa-update'){
            console.info('-> avaa-update',o);            
        }
        if(o._){
            this.dispatch(o._,o);
        }
    }

    constructor() {
        super();
        //console.log('CONVERTER SOCKET CREATED');
        this.reconnect();
    }
}
