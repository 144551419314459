import React from "react";
import {
    FaComment, FaHtml5, FaMarkdown
} from "react-icons/fa";
import ButtonClone from "../ui/ButtonClone";
import ButtonDelete from "../ui/ButtonDelete";
import HoverActions from "../ui/HoverActions";
import Block from "./Block";
import LevelIcon from "./LevelIcon";
import {useForceUpdate} from "./Blocks";
import { useUndo } from "../contexts/UndoContext";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";
import NodeActions from "../ui/NodeActions";


export default function BlockMarkdown({node, onAction, parentAddy}) {
        
    const forceUpdate = useForceUpdate();
    const undo = useUndo();
    const onAceChange = React.useCallback(value => {
        node.childNodes[0].nodeValue = value || '';        
        //undo.add(node);
        forceUpdate();
        //onChange && onChange();
    },[node])

    const content = node.childNodes[0].textContent || '';
    return (
        <>
            <LevelIcon><FaMarkdown/></LevelIcon><AceEditor
  placeholder=""
  mode="markdown"
  theme="tomorrow"
  name={"html"+node}  
  onChange={onAceChange}
  fontSize={14}
  maxLines={Infinity}
  showPrintMargin={false}
  showGutter={false}
  highlightActiveLine={false}
  value={content}
  setOptions={{
  enableBasicAutocompletion: false,
  enableLiveAutocompletion: false,
  enableSnippets: false,
  showLineNumbers: true,
  tabSize: 2,
  }}/>           
<HoverActions><NodeActions node={node} onAction={onAction} addy={parentAddy}/></HoverActions>               
        </>
    )

}
BlockMarkdown.addy = {
    icon: <span title={"add markdown"}><FaMarkdown/></span>,
    make: ()=> Block.parseFromString('<MARKDOWN><![CDATA[]]></MARKDOWN>')
}