import React, {useCallback, useContext, useEffect, useState} from 'react';
import Document from "../blocks/Document";
import EditorContext, { useEditor } from "../EditorContext";
import FileContext, { useFile } from "../contexts/FileContext";
import {
    FaBolt,
    FaEye,
    FaFile,
    FaFlag,
    FaHammer,
    FaLink,
    FaPlusSquare,
    FaRegFileArchive,
    FaRegFilePdf,
    FaSpinner,
    FaStop,
    FaUndo,
    FaUpload
} from "react-icons/fa";
import ReactJson from "react-json-view";
import { useUndo } from '../contexts/UndoContext';
import Popup from "reactjs-popup";
import AutosizeInput from 'react-input-autosize';
import { TranslateProvider } from '../contexts/TranslateContext';
import { useForceUpdate } from '../blocks/Blocks';
import SwitchLangButton from './languages/SwitchLangButton';
import StylePopupButton from './style/StylePopupButton';
import ModsPopupButton from './mods/ModsPopupButton';
import Tutoizer from './tutoizer/Tutoizer';
import ProgressTracker from './ProgressTracker';
import Deployer from './Deployer';
import AVAAUpdater from './updater/AVAAUpdater';
import { LearnStep } from './learn/StepLogger';
import Utils from '../converter/Utils';



export default function Tab({file}) {

    const forceUpdate = useForceUpdate(); //todo reafctor
    const [progress, setProgress] = useState(0);
    const [converting, setConverting] = useState('');

    const [popupHosting, setPopupHosting] = useState(false);
    const [zipped, setZipped] = useState(false);
    const [saving, setSaving] = useState(false);
    const [jsonViewData, setJsonViewData] = useState(null);

    const [insight, setInsight] = useState(null);
    const [errorLog,setErrorLog] = useState(null);
    //const translate = useTranslate();

    

    const editor = useContext(EditorContext)
    const undo = useUndo();
    //console.info('Editor()', file);

    const makeInsight = (learn)=>{
        let errors = [];
        let warnings = []
        let tests = 0;
        let pass = 0;
        for(let k in learn){
            let v = learn[k];
            if(v.error) errors.push({node:k,e:v.error});
            if(v.errors) v.errors.forEach(s=>errors.push({node:k,e:s}));
            if(v.warnings) v.warnings.forEach(s=>warnings.push({node:k,e:s}));
            if(k.indexOf('> TEST[')>-1){
                tests++;
                if(v.pass) pass++
            }
        }        
        return {errors:errors, warnings:warnings, tests:tests,passed:pass}
    }

    const handleResponse = (r,duration) => {
        console.info('conversion completed!', duration,r)
        file.learn = r.learn;
        file.tiers = r.tiers;
        file.domain = r.domain;
        //file.tiers.sort();
        file.dispatch("learn-data", r.learn)
        file.dispatch("learn-block-enter", null)
        let domain = r.domain.domain = {};
        r.domain.order.forEach(k=>domain[k]=r.domain.files[k]); //omg fck this
        for(let k in r.domain.files) if(!domain[k]) domain[k]=r.domain.files[k];
        //console.info('DOMAIN',domain);
        file.dispatch("domain-data", r.domain)
        setConverting('');
        let newInsight = makeInsight(file.learn);
        newInsight.duration = duration;
        setInsight(newInsight);
        //console.info('logs', getLogs(r.learn))
    }
    const handleTicket = (t,cb)=>{                
        editor.converter.tickets[t] = {
            started:Date.now(),
            onComplete: (r,err) => {            
                if(err){
                    setConverting('');
                    setInsight({aborted:err});
                    file.dispatch("learn-block-enter", null)
                    file.dispatch("learn-data", null)
                    //alert(err);                    
                    editor.converter.tickets[t]=null;
                    return;
                }
                handleResponse(r,Date.now()-editor.converter.tickets[t].started); // !?
                if(cb) cb(r);
                editor.converter.tickets[t]=null;
            }
        }
        //handleResponse(r)
    }    
    const save = cb => {
        setSaving(true);                
        editor.numRuns++;
        let xmlStr = Utils.toXML(file.document);        
        editor.converter.call("set-file", {path: file.path, content: xmlStr}, r => {
            setSaving(false);
            cb && cb()
        })
    }
  
    const test = useCallback((ev) => {
        if(ev && ev.shiftKey){
            setConverting('test');
            save(() => {    
                setConverting('');
            });
            return;
        }
        setConverting('test');
        setInsight(null);
        file.learn = {processing: true};
        file.dispatch("learn-data");
        file.dispatch("learn-proc");  
        file.dispatch("reset-progress");  
        const settings = {
            'test-mode': 'true'
        }
        save(() => {            
            editor.converter.call("convert", {lang:file.lang||'',file: file.path, settings: settings}, r => {
                handleTicket(r);
            })
        })
    }, [file]);
    const open = ev => {
        editor.converter.call("open-file", {path: file.path.replace('.xml', '.html')});
    }

    const convert = useCallback(() => {
        setConverting('convert');
        setInsight(null);
        file.learn = {processing: true};
        file.dispatch("learn-data");
        file.dispatch("learn-proc"); 
        file.dispatch("reset-progress");  
        setProgress(0);        
        const t = Math.round(Math.random()*100000+1);        
        const settings = {
            //'clip-overwrite': 'true'
        }
        save(() => {
            editor.converter.call("convert", {lang:file.lang||'',file: file.path, ticket:t, settings:settings}, r => {
                handleTicket(r);
            })
        })
        
    }, [file]);
    const convertZip = useCallback((ev) => {
        setInsight(null);
        setConverting('zip');
        file.dispatch("reset-progress");  
        setProgress(0);
        const t = Math.round(Math.random()*100000+1);
        save(() => {            
            editor.converter.call("convert", {lang:file.lang||'',file: file.path, zip: true,ticket:t}, r => {
                handleTicket(r,r=>{                   
                    //TODO setDeployedURLs(null);
                    setZipped(true);
                    editor.converter.call("open-file", {
                        browse: true,
                        path: file.path.replace('.xml', '.zip')
                    });
                });                
            })
        })
    }, [file]);
    const convertPDF = useCallback((ev) => {
        setInsight(null);
        setConverting('pdf');
        file.dispatch("reset-progress");  
        setProgress(0);
        const t = Math.round(Math.random()*100000+1);
        save(() => {            
            editor.converter.call("convert", {lang:file.lang||'',file: file.path, pdf: true,ticket:t}, r => {
                handleTicket(r,r=>{                   
                    //TODO setDeployedURLs(null);
                    console.info('@@@@@@@@',r);
                    
                    if(r.logger.steps){
                        let pdf = r.logger.steps.filter(o=>o.id=='PDF')[0];
                        if(pdf && pdf.logs){
                            let err = pdf.logs.filter(o=>o.type=='error')[0];
                            if(err) {
                                setErrorLog(r.logger);
                                return;
                            }
                        }
                        console.info(pdf);
                    }                    
                    //return;
                    editor.converter.call("open-file", {
                        browse: true,
                        path: file.path.replace('.xml', '.pdf')
                    });
                });                
            })
        })
    }, [file]);

  

    const onFileLoad = () => {
        console.info('onFileLoad');
        forceUpdate()
    }

    useEffect(() => {
        const onJsonViewData = (r) => {
            let o = r.detail;
            if (o && o.length > 100) {
                o = new Array(o);
                o.length = 100;
            }
            setJsonViewData(o)
        }
        editor.on('json-view-data', onJsonViewData)
        return () => {
            editor.off("json-view-data", onJsonViewData);
        }
    }, [file]);

    useEffect(() => {
        const onConvertProgress = (v) => {
            setProgress(v.detail.total);
            //setProgressFFMPEG(v.detail.ffmpeg);
        }
        const onDeployProgress = (v) => {
            setProgress(v.detail.upload)
        }
        file.on('convert-progress', onConvertProgress)
        file.on('deploy-progress', onDeployProgress)
        file.on('load', onFileLoad)
        file.on('undo-added', forceUpdate)
        return () => {
            file.off("convert-progress", onConvertProgress);
            file.off('deploy-progress', onDeployProgress)
            file.off('load', onFileLoad)
            file.off('undo-added', forceUpdate)
        }
    }, [file]);

    if (file.content===undefined) {
        return <div>
            <FaSpinner className={"spinner"}/>
        </div>
    }
    if (file.content==='') {
        return <div>
            xml file is empty.
        </div>
    }

    const undoOne = ()=>{
        let last = file.undoList.pop();
        file.document=null;
        forceUpdate();
        file.document=last.document;
        forceUpdate();
    }
    const openNewTab = ()=>{
        window.open(window.location.href.split('#')[0]);
    }

    return <FileContext.Provider value={file}>
        {file.document ?
        <TranslateProvider file={file}>
        <div className={"editor"}>            
            <div className={"document-settings"}>
                    <button onClick={e=>openNewTab()} title="open file..."><FaFile/> </button>
                    <span className={"document-name"}>{(file.name.replace('.xml',''))}</span>
                    <StylePopupButton/>
                    <ModsPopupButton/>
                    <SwitchLangButton/>
                </div>
            <div className={"editor-toolbar"}>
                <ConversionInsight onClick={ev=>setInsight(null)} insight={insight}/>
                {converting ? <ProgressTracker /> : <></> }               
                <button title="undo" disabled={converting || !file.undoList?.length} onClick={ev=>undoOne()}><FaUndo/> {file.undoList?.length ? <span className='undo-count'>{file.undoList.length}</span> : <></>}</button>
                <button className={converting=='test'?"converting":""} onClick={test} disabled={!!converting} title="Save &amp; Preview is same as Convert, but will not generate any heavy task like cutting clips or running some processors">
                    <FaBolt/> Save &amp; Preview {converting == 'test' ?
                    <FaSpinner className={"spinner"}/> : ''}
                </button>
                <button className={converting=='convert'?"converting":""} onClick={convert} disabled={!!converting} title="Save your changes and generate the complete final document with all necessary clips">
                    <FaHammer/> Convert {converting == 'convert' ?
                    <FaSpinner className={"spinner"}/> : ''} {converting=='convert' ? progress+"% " :""}
                </button>
                <button onClick={open} disabled={!!converting} title="After Preview or Convert, open the document in the browser to see it">
                    <FaEye/> Open
                </button>
                {window.location.hostname=='localhost'||true ? <button className={converting=='pdf'?"converting":""} onClick={convertPDF} disabled={!!converting} 
                    title="Generate a PDF file">
                    <FaRegFilePdf/> PDF {converting == 'pdf' ?
                    <FaSpinner className={"spinner"}/> : ''} {converting=='pdf' ? progress+" % ":""}
                </button> : <></> }
                <button className={converting=='zip'?"converting":""} onClick={convertZip} disabled={!!converting}
                    title="Build a complete zip of the document, so you can share it or host its content on a web server">
                    <FaRegFileArchive/> ZIP {converting == 'zip' ?
                    <FaSpinner className={"spinner"}/> : ''} {converting=='zip' ? progress+" % ":""}
                </button>
                {zipped ? <button onClick={ev=>setPopupHosting(true)} 
                    title="After Zipping, upload the document on a web server and get an URL for sharing">
                    <FaUpload/> Host
                </button> : <></>}          
                {window.location.hostname=='localhost' ? <Popup trigger={<button><FaPlusSquare/></button>}>
                
                
                <Popup trigger={<button>update</button>} modal>
                    <AVAAUpdater />                    
                </Popup>
                <Tutoizer />                
                </Popup>:<></>}
                <Popup className="popup-host" open={popupHosting} closeOnDocumentClick={false} closeOnEscape={false} modal>           
                    <Deployer onClose={ev=>setPopupHosting(false)}/>
                 </Popup>
                 <span className='toolbar-logo'></span>
            </div>
            {errorLog ? <Popup open={errorLog!=null} modal={true} onClose={e=>setErrorLog(null)}>
                <div>{errorLog.steps ? errorLog.steps.map((o,i)=><LearnStep key={i} step={o}/>) : <></>}</div>
            </Popup> : <></>}
            <Document file={file}/>
            {jsonViewData ?
                <div className={"json-view"}><ReactJson src={jsonViewData} theme={"bright"} collapsed={3}
                                                        displayDataTypes={false} collapseStringsAfterLength={100}
                                                        quotesOnKeys={false}
                                                        name={false}/>
                </div> : ''}
        </div>
        </TranslateProvider>
 : <div>
        {file.parseError ? 
        <span>{file.parseError}</span> :
        <span>loading...</span>}
    
    </div>}
    </FileContext.Provider> 

}

function ConversionInsight({insight,onClick}){
    if(!insight) return <div className="insight closed"></div>
    if(insight.aborted) return <div onClick={onClick} className="insight opened aborted">{insight.aborted}</div>
    let sec = Math.ceil(insight.duration/1000);
    return <div onClick={onClick} className="insight opened">
        <i className='duration'>completed in {sec} seconds</i>
        {insight.warnings.length ? <b className='warnings'>{insight.warnings.length} warnings</b> : <></>}
        {insight.errors.length ? <b className='errors'>{insight.errors.length} errors</b> : <></>}
        {insight.tests ? <b className={'tests tests-'+(insight.tests==insight.passed ? 'pass' : 'fail')}>{insight.passed}/{insight.tests}</b> : <></>}
    </div>
}
