import Block from "./Block";
import React, {useContext, useState} from "react";
import AttributesList from "../ui/AttributesList";
import EditorContext from "../EditorContext";
import {FaArrowRight, FaCogs, FaFolderPlus, FaTag} from "react-icons/fa";
import BlockContainer from "./BlockContainer";
import Level from "./Level";
import LevelIcon from "./LevelIcon";
import ButtonEdit from "../ui/ButtonEdit";
import AttributeField from "../ui/AttributeField";
import HoverActions from "../ui/HoverActions";
import BlockLocal from "./BlockLocal";
import {useAddy, useForceUpdate} from "./Blocks";
import BlockSelect from "./BlockSelect";
import BlockOp from "./BlockOp";
import ButtonAdd from "../ui/ButtonAdd";
import BlockComment from "./BlockComment";
import BlockFilter from "./BlockFilter";
import LearnItem from "../ui/LearnItem";
import NodeActions from "../ui/NodeActions";

export default function BlockExtra({node, isEdit, blocks, onNodeAction, onAction, parentAddy}) {
    const context = useContext(EditorContext)
    const forceUpdate = useForceUpdate()
    const [stateEdit, setEdit] = useState((!!isEdit) || !node.childNodes.length)
    
    const addy = useAddy({
        node, update: forceUpdate,
        types: [BlockSelect, BlockOp, BlockFilter, BlockLocal, BlockComment]
    })

    function toggleEdit() {
        setEdit(!stateEdit)
    }

    return <>
        <LevelIcon><FaFolderPlus/></LevelIcon> <b>extra </b>
        <AttributeField placeholder={"enter id"} hideName={true} node={node} att={"id"}/>
        <ButtonEdit onClick={toggleEdit} isEdit={stateEdit}/>
        <HoverActions><NodeActions node={node} onAction={onAction} addy={parentAddy} /></HoverActions>
        <LearnItem node={node} type={BlockExtra}/>
        <Level isEdit={stateEdit}>
            <AttributesList deletable={true} hiddenAttributes={["id"]} isEdit={stateEdit} node={node}
                            accepted={context.definitions.tag.extra.attributes}/>
            <BlockContainer isEdit={stateEdit} node={node} blocks={blocks} onNodeAction={onNodeAction} onAction={onAction} parentAddy={addy.box}/>
            {stateEdit ? addy.box : <></>}            
        </Level>
    </>
}
BlockExtra.learn = {
    render: o => {
        return <span>
            <span className={"color-extra"}>
                {o.sourceSize!=o.size ? <><b style={{color:'green'}}> {o.sourceSize}</b> <FaArrowRight/> <b style={{color:'red'}}>{o.size}</b></> : <b></b>}
            </span>
        </span>
    }
}
BlockExtra.addy = {
    icon: <span title={"add extra processing branch"}><FaFolderPlus/></span>,
    make: ()=>Block.parseFromString('<EXTRA></EXTRA>')
}