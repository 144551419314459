import Block from "./Block";
import React from "react";

import {
    FaArrowRight,
    FaCheck,
    FaFileImport,    
} from "react-icons/fa";
import LevelIcon from "./LevelIcon";
import HoverActions from "../ui/HoverActions";
import LearnItem from "../ui/LearnItem";
import AttributeField from "../ui/AttributeField";
import { useForceUpdate } from "./Blocks";
import NodeActions from "../ui/NodeActions";
import { useFile } from "../contexts/FileContext";

export default function BlockDoc({node, onAction, parentAddy}) {
    const forceUpdate = useForceUpdate()
    const file = useFile();
    const open = ()=>{
        let path = node.getAttribute("path") || "";
        if(path.charAt(0)=='.' && path.charAt(1)=='/'){ //TODO fix edgecases
            path = file.folder+path.substring(1);
        }
        console.info(path);
        window.open("/#/"+path,"_blank");
        console.info(node);
    }
    return (
        <>
            <LevelIcon><FaFileImport/></LevelIcon>  <b className={"color-load"}>import document </b>
            <AttributeField onChange={forceUpdate} placeholder={""} hideName={true} node={node} att={"path"}/>
            <a className="open-in-editor" onClick={ev=>open()}>open in editor</a>
            <HoverActions><NodeActions node={node} onAction={onAction} addy={parentAddy}  /></HoverActions>
            <LearnItem node={node} type={BlockDoc}/>
        </>
    )

}
BlockDoc.learn = {
    render: o => {
        return <span> <b style={{color:'green'}}><FaCheck /></b>
            <span className={"color-load"} style={{display:'none'}}>
                {!o.annotations ? <><b></b></> : <><FaArrowRight/> <b> {o.size}</b></>}
            </span>
        </span>
    }
}
BlockDoc.addy = {
    icon: <span title={"insert another document"}><FaFileImport/></span>,
    make: ()=>Block.parseFromString('<DOC path="" />')
}