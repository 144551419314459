import React from "react";
import DragDroppable from "../ui/DragDroppable";
import {useBlock, useForceUpdate} from "./Blocks";
import {
    DndContext,
    closestCenter
} from '@dnd-kit/core';
import {
    arrayMove, rectSwappingStrategy,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Sortable from "../ui/Sortable";
import { useUndo } from "../contexts/UndoContext";

export default function BlockContainer({node, isEdit, blocks, onNodeAction, parentAddy}) {
    const block = useBlock(node)
    const undo = useUndo()
    const forceUpdate = useForceUpdate()

    function dragStart(e) {
        //console.info('DRAG START', e, block)
    }

    function dragEnd(event) {
        const {active, over} = event;
        //console.info("dragEnd", event);
        console.info(active, over);
        let children = Array.from(node.children);
        const oldIndex = children.indexOf(active.data.current.node);
        const newIndex = children.indexOf(over.data.current.node);
        if(newIndex===oldIndex) return;
        undo.snap();
        if (newIndex > oldIndex) {
            over.data.current.node.after(active.data.current.node);
        } else {
            over.data.current.node.before(active.data.current.node);
        }        
        forceUpdate();
        //if (over && over.data.current.accepts.includes(active.data.current.type)) {
        // do stuff
        //}
    }

    function cloneNode(e) {
        undo.snap();
        let cloned = e.cloneNode(true);
        block.reidize(cloned);
        e.after(cloned);
        forceUpdate();
    }

    function deleteNode(e) {        
        undo.snap();
        e.parentNode.removeChild(e);        
        forceUpdate();        
    }

    const list = [];
    const items = [];
    //console.info("BLOCKCONTAINER", isEdit)
    for (let c of node.children) {
        items.push({node: c, id: c._id});
        let tag = c.tagName.charAt(0).toUpperCase() + c.tagName.substring(1).toLowerCase();
        const BlockTag = blocks[c.tagName.toUpperCase()];
        if (!BlockTag) {
            list.push(<div key={Math.random()} style={{color: "red"}}>INVALID SUBTAG {c.tagName}</div>);
            continue;
        }
        list.push(
            <Sortable node={c} key={c._id} className={"editor-block editor-block-" + c.tagName.toLowerCase()}
                      id={c._id}>
                <BlockTag isEdit={isEdit} node={c} blocks={blocks} onNodeAction={onNodeAction} parentAddy={parentAddy}
                          onAction={action=>onNodeAction(c,action)}
                          cbClone={() => cloneNode(c)}
                          cbDelete={() => deleteNode(c)}/>
            </Sortable>
        );
    }
    return <><DndContext collisionDetection={closestCenter} onDragStart={dragStart} onDragEnd={dragEnd}>
        <SortableContext strategy={verticalListSortingStrategy} items={items}>{list}</SortableContext>
    </DndContext></>

}