
import Blocks, {useForceUpdate} from "../../blocks/Blocks";

import AutosizeInput from 'react-input-autosize';
import { useTranslatableAttribute, useTranslate } from '../../contexts/TranslateContext';
import ReactCountryFlag from 'react-country-flag';
import ButtonRemove from '../ButtonRemove';
import ButtonAdd from "../ButtonAdd";
import { useUndo } from '../../contexts/UndoContext';
import Block from "../../blocks/Block";
import { useFile } from "../../contexts/FileContext";

export default function LanguagesPopup({}){
    const translate = useTranslate();
    const undo = useUndo();
    const file = useFile();
    const forceUpdate = useForceUpdate();

    const doc = file.document;

    const update = () => {
        translate.update();
        //setLangs(list)        
    }    
   
    let tNodes = doc.getElementsByTagName("TRANSLATION");
    if(!tNodes[0]){
        console.info('creating missing TRANSLATION node');
        doc.appendChild(Block.parseFromString('<TRANSLATION></TRANSLATION>'));
        tNodes = doc.getElementsByTagName("TRANSLATION");
    }
    let tNode = tNodes[0];
    const addLang = ev => {
        let v = prompt("Enter the language code (2-letters)")
        if(v.length!=2) return alert("invalid code")
        undo.snap();
        v = v.toLowerCase();
        tNode.appendChild(Block.parseFromString(`<LANGUAGE id="${v}" flag="${v.toUpperCase()}" name="(${v})" />`));
        update();
        //forceUpdate();
        
    }

    let langNodes = [];
    for(let n of tNode.getElementsByTagName("LANGUAGE")) langNodes.push(n);
    //console.info(langNodes);
    return <div className='editor-languages crud-table'>
        <h2>Document Languages</h2>
        {langNodes.length ? <table className="no-top-left-corner">
            <thead><tr>
                <td></td>
                <td>Flag</td>                
                <td>Code</td>
                <td>Name</td>
            </tr></thead>
            <tbody>
            
        {langNodes.map(L=><tr key={L.getAttribute("id")}>
             <td><ButtonRemove onClick={ev=>{
                undo.snap();
                update(L.parentElement.removeChild(L));
            }}/></td>
            <td><ReactCountryFlag svg countryCode={L.getAttribute('flag').toUpperCase()} />            
            <AutosizeInput            
            type={"text"}            
            value={L.getAttribute('flag')||''}
            onChange={e=>update(L.setAttribute('flag',e.target.value))}            
        />
            </td>
            <td><AutosizeInput            
            type={"text"}            
            value={L.getAttribute('id')||''}
            onChange={e=>update(L.setAttribute('id',e.target.value.toLowerCase()))}            
        /></td>
            <td><AutosizeInput            
            type={"text"}            
            value={L.getAttribute('name')||''}
            onChange={e=>update(L.setAttribute('name',e.target.value))}            
        /></td>
        </tr>)}
        </tbody></table> : <></> }
        <ButtonAdd onClick={addLang}>add language</ButtonAdd>        
    </div>
}