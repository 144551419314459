
import Blocks, {useForceUpdate} from "../../blocks/Blocks";

import AutosizeInput from 'react-input-autosize';
import { useTranslatableAttribute, useTranslate } from '../../contexts/TranslateContext';
import ReactCountryFlag from 'react-country-flag';
import ButtonRemove from '../ButtonRemove';
import ButtonAdd from "../ButtonAdd";
import { useUndo } from '../../contexts/UndoContext';
import Block from "../../blocks/Block";
import { useFile } from "../../contexts/FileContext";
import BlockStyle from "../../blocks/BlockStyle";
import BlockCSS from "../../blocks/BlockCSS";

export default function StylePopup({}){
    const file = useFile();

    let documentElement = file.document.documentElement;
    let styleNodes = documentElement.getElementsByTagName("STYLE");
    if(!styleNodes[0]){
        console.info('creating missing STYLE node');
        documentElement.appendChild(Block.parseFromString('<STYLE></STYLE>'));
        styleNodes = documentElement.getElementsByTagName("STYLE");
    }
    let styleNode = styleNodes[0];
    
   
    return <div>        
        <BlockStyle node={styleNode}/> 
        <BlockCSS node={styleNode}/> 
    </div>
}