import { useState } from "react";
import { FaArrowAltCircleRight, FaArrowCircleRight, FaArrowRight, FaCog, FaRegArrowAltCircleRight } from "react-icons/fa";
import AutosizeInput from "react-input-autosize";

export default function TextContentNode({node,onChange}){
    const [value,setValue] = useState(node.textContent);
    const [changed,setChanged] = useState(false);
    return <span className="text-content-node">
        <FaCog />
        <AutosizeInput type="text" value={node.textContent} 
            onBlur={e=>{
                if(changed) onChange();
            }} 
            onChange={e=>{
                //console.info(e.target.value);
                if(node.textContent == e.target.value) return;
                node.textContent = e.target.value;
                setValue(node.textContent);
                setChanged(true);
            }}
        />
    </span>

}