import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {useForceUpdate, useLearn} from "../blocks/Blocks";
import {useFile} from "../contexts/FileContext";

import {FaBraille, FaBug, FaDownload, FaExclamationTriangle, FaFileDownload, FaFileMedical, FaFileMedicalAlt, FaFire, FaFlag, FaFlask, FaMedkit, FaNewspaper, FaSkullCrossbones, FaSpinner} from "react-icons/fa";
import EditorContext from "../EditorContext";

export default function LearnItem({node, type}) {
    const editor = useContext(EditorContext)
    const file = useFile()
    const learn = useLearn(node)    
    const [state, setState] = useState(file.learn ? file.learn[learn.getRef()] : null);
    const [working, setWorking] = useState(false);
    const [pending, setPending] = useState(false);
    //console.info("LEARN ITEM RENDER()", state)
    const myRef = useRef(null)

    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })    
    // run this function from an event handler or an effect to execute scroll 
    const onLearnData = useCallback(r => {
        if(!r.detail){            
            setWorking(false);
            setPending(false);
            setState(null);            
            return;
        }
        let v  = file.learn[learn.getRef()];
        let proc = !v && file.learn && file.learn.processing;
        if(!file?.learn?.processing) setWorking(false);
        setPending(proc)        
        //if (!proc) {
        setState(v)            
        //}
        //console.info(file.learn[learn.getRef()], state);
    }, [node]);
    const onBlockEnter = useCallback(r => {        
        let w = r?.detail==learn.getRef()
        setWorking(w); 
        if(w) executeScroll();        
    }, [node]);

    useEffect(() => {
        file.on('learn-data', onLearnData)
        file.on('learn-block-enter', onBlockEnter)

        /*async function fetchData() {
        const apiUrl = 'https://restcountries.com/v3.1/region/africa';
        const response = await fetch(apiUrl)
        let data = await response.json()
        //setList(data)
    }*/

        return () => {
            file.off("learn-item", onLearnData);
            file.off('learn-block-enter', onBlockEnter)
        }
    }, [node]);
////{file.name} {learn.ref}
    let ret;
    if (!type || !type.learn) ret = <span>unhandled {type} <i>{learn.ref}</i></span>
    else if (!state) ret = <i></i>;
    else ret = type.learn.render ? type.learn.render(state,node) : type.learn.content;

    let jsonView = <></>
    let errors = <></>
    let warnings = <></>
    let infos = <></>
    let reports = <></>

    const onReportOver = r => {
        
        //console.info("onReportOver",r)        
        editor.dispatch("json-view-data", r)
    }
    const onReportOut = ev => {
        //console.info("onReportOut")        
        editor.dispatch("json-view-data", null)
    }
    if (state) {
        if (state.error) errors = <div className={"learn-error"}><i>{state.error}</i></div>
        if (state.errors) errors = state.errors.map(s => <div key={s} className={"learn-error"}><FaSkullCrossbones />  {s}</div>)
        if (state.warnings) {
            let w = {};
            state.warnings.forEach(s => w[s] = (w[s] || 0) + 1);
            warnings = Object.keys(w).map(s => <div key={s} className={"learn-warning"}><FaExclamationTriangle />  {s}
                {w[s]>1 ? <i>&times;{w[s]}</i> : <></>}</div>);
        }

        if (state.infos) {
            infos = state.infos.map(s => <div key={s} className={"learn-info"}>{s}</div>);            
        }

        if (state.reports) {
            reports = state.reports.map(s => <a onMouseEnter={()=>onReportOver(s)} onMouseLeave={onReportOut} onClick={()=>{console.log(s)||alert("show console with F12")}}
             title={(s.title||s.name||"")+"\n(click to output in console)"} key={s.id || s.name || s.title} className={"learn-report"}><FaFileMedicalAlt /> </a>);
        }
    }

    const onMouseOver = ev => {
        let o;
        //console.info("onMouseOver")        
        if (state && (state.output || state.annotations)) {
            try {
                let o = state.annotations ? state.annotations : JSON.parse(state.output);
                editor.dispatch("json-view-data", o)
            } catch (ex) {
                //console.error(ex);
            }
        }
    }
    const onMouseOut = ev => {
        //console.info("onMouseOut")        
        editor.dispatch("json-view-data", null)
    }

    return <div ref={myRef} className="learn-item-root">
        
        <span onMouseEnter={onMouseOver} onMouseLeave={onMouseOut}
                 className={"learn-item"}> {reports} {ret} {pending ?
            <FaSpinner className={"spinner"}/> : ''}</span>

        {working ? <div className="learn-item-working"></div>:<></>}
        <div className="learn-logs"><>{infos} {warnings} {errors}</></div>
            
        </div>
}