import { useEffect, useRef, useState } from "react";
import { FaArrowDown } from "react-icons/fa";
import AutosizeInput from "react-input-autosize";
import Popup from "reactjs-popup";

export default function EditableSelect({value,defaultValue,onChange,onEnterKey,onEscapeKey,choices,warnInvalidChoice,noChoiceAvailable,choiceInfo,autoFocus}){    
    const ref = useRef();
    const [isOpen,setIsOpen] = useState(false);
    const openTooltip = () => ref.current && ref.current.open();
    const closeTooltip = () => ref.current && ref.current.close();
    //const toggleTooltip = () => ref.current.toggle();
    //console.info('editable select autofocus',autoFocus)
    choiceInfo = choiceInfo || (v=>v)
    value = value || '';    
    const placeholder = defaultValue ? defaultValue : '';
    choices = choices || [];
    const choicesInfo = Array.isArray(choices) ? null : choices;
    if(choicesInfo){
      choices = Object.keys(choicesInfo).sort();      
    }
    const filtered = choices.filter(v=>{
      if(!value) return false;
      if(v.indexOf(value)>-1) return true;
      return false;
    })
    let invalidChoice = filtered.length==0 && choices.length;
    let wildcardPass = false;
    let perfectMatch = '';
    
    if(value) for(let c of choices){
      if(c === value) perfectMatch = value;
      if(c.indexOf('*')>-1){        
        if(new RegExp(c).test(value)) wildcardPass=true;
      }
    }
    const onKeyDown = e=>{
      if(e.keyCode==13){
        if(filtered.length===1 || perfectMatch){
          onChange(perfectMatch || filtered[0]);        
          if(isOpen) closeTooltip();
          onEnterKey && onEnterKey();
        }
        return;
      }
      if(e.keyCode==27){
        if(isOpen) closeTooltip();
        onEscapeKey && onEscapeKey();
        return;
      }
      if(!isOpen) openTooltip();
      
    }
    const onSelect = (v,e)=>{
      onChange(v);
      //console.info(ref.current);
      closeTooltip();
      onEnterKey && onEnterKey();
      e.preventDefault();
      //e.stopImmediatePropagation();
      //console.info("here");
    }    
    useEffect(()=>{
        if(autoFocus) openTooltip();
    },[]);
    return <span className={"editable-select "+(invalidChoice && warnInvalidChoice?'warn-invalid-choice':'')}>

        <AutosizeInput
                onFocus={e=>openTooltip()}
                autoFocus={autoFocus}
                placeholder={placeholder}
                type={"text"}
                value={value || ''}
                autoCorrect="off"
                autoComplete="off"                
                onKeyDown={onKeyDown}
                onChange={e=>onChange && onChange(e.target.value)}
      />
      
      <Popup ref={ref} trigger={<a><FaArrowDown/></a>} className="editable-select-choices" closeOnEscape={true} modal={false} nested={true}
        onOpen={e=>setIsOpen(true)} onClose={e=>setIsOpen(false)}
      >
        {filtered.length ? 
          <div className="choices">
                {filtered.map(o => 
                  {
                    let c = choicesInfo ? choiceInfo(o,choicesInfo[o]) : null;
                    return <div className="choice" key={o} onClick={e=>onSelect(o,e)}> 
                      <Colorize value={value} choice={o}/>
                      {c ? <span className="choice-info" title={c.title}>{c.info}</span>: <></>}
                    </div>
                  })
                }
                <hr/>
            </div>
            
              :<></>}
       {!filtered.length && value && !wildcardPass ? 
          <div className="choices">
                <div className="choice" onClick={e=>onSelect(value,e)}> 
                    <span className={warnInvalidChoice?"invalid-choice":""}>{value}</span>
                  </div>
                
                <hr/>
            </div>
        
              :<></>}              
        {!filtered.length && wildcardPass ? 
          <div className="choices">
                <div className="choice" onClick={e=>onSelect(value,e)}> 
                    <span className="wildcard-choice">{value}</span>
                  </div>
                
                <hr/>
            </div>
        
              :<></>}              
            <div className="choices">
                {!choices.length ? <div className="no-choices-available"><i>{noChoiceAvailable || 'no choice available'}</i></div> : <></>}
                {choices.map(o => {
                  let c = choicesInfo ? choiceInfo(o,choicesInfo[o]) : null;
                  return <div className="choice" key={o} onClick={e=>onSelect(o,e)}> 
                    <span>{o}</span>
                    {c ? <span className="choice-info" title={c.title}>{c.info}</span>: <></>}
                  </div>})
                }
            </div>
        </Popup>

      </span>
}

function Colorize({value,choice}){
  if(value==choice){
    return <span className="perfect-match">{choice}</span>
  }
  let i = choice.indexOf(value);
  if(i==-1) return <span className="invalid-match">{choice}??</span>
  return <span className="">
    <span className="text-before-match">{choice.substring(0,i)}</span>
    <span className="partial-match">{value}</span>
    <span className="text-after-match">{choice.substring(i+value.length)}</span>
  </span>
}