import { Canvg } from 'canvg';
import FileSaver from "file-saver";
import md5 from "md5";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaChartBar, FaCog } from "react-icons/fa";
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import EditorContext from "../EditorContext";
import AttributeField from "../ui/AttributeField";
import AttributesList from "../ui/AttributesList";
import ButtonEdit from "../ui/ButtonEdit";
import HoverActions from "../ui/HoverActions";
import LearnItem from "../ui/LearnItem";
import NodeActions from "../ui/NodeActions";
import Block from "./Block";
import BlockComment from "./BlockComment";
import BlockContainer from "./BlockContainer";
import BlockExtra from "./BlockExtra";
import BlockFilter from "./BlockFilter";
import BlockIf from "./BlockIf";
import BlockLegend from "./BlockLegend";
import BlockLoad from "./BlockLoad";
import BlockLocal from "./BlockLocal";
import BlockOp from "./BlockOp";
import { getTreeRef, useAddy, useForceUpdate } from "./Blocks";
import BlockSave from "./BlockSave";
import BlockSelect from "./BlockSelect";
import BlockSet from "./BlockSet";
import BlockStack from "./BlockStack";
import BlockTest from "./BlockTest";
import BlockUnstack from "./BlockUnstack";
import Level from "./Level";
import LevelIcon from "./LevelIcon";

export default function BlockChart({node, blocks, onNodeAction, onAction, isEdit, parentAddy}) {
    const context = useContext(EditorContext)
    const forceUpdate = useForceUpdate()
    const [stateEdit, setEdit] = useState(!!isEdit)        
    const [stateTypeEdit, setTypeEdit] = useState(false)
    
    const type = node.getAttribute("type")
    const addy = useAddy({
        node, update: forceUpdate,
        types: [BlockLegend, BlockSet, BlockSelect, BlockOp, BlockFilter, BlockStack, BlockUnstack, BlockSave, BlockLoad, BlockExtra, BlockIf, BlockLocal, BlockTest, BlockComment],
        //types: [BlockSelect, BlockOp, BlockFilter, BlockStack, BlockExtra, BlockLocal, BlockSet],
        container: BlockChart,
        containerType: type
    })

    

    const toggleEdit = () => {
        setEdit(!stateEdit)
    }
    
    const editType = () => {
        setTypeEdit(!stateTypeEdit);
    }

    return <>
        <LevelIcon><FaChartBar/></LevelIcon> <b> chart - <b>
            {stateTypeEdit 
            ? <AttributeField autoFocus={true} onEnterKey={editType} definition={{label:'type'}} placeholder={"chart type"} hideName={true} node={node} att={"type"}/>
            : <a className="type" onClick={editType}>{type}</a> }</b></b>
        <ButtonEdit onClick={toggleEdit} isEdit={stateEdit}/>
        <HoverActions><NodeActions node={node} onAction={onAction} addy={parentAddy}/></HoverActions>
        <LearnItem node={node} type={BlockChart}/>
        <Level isEdit={stateEdit}>
            <AttributesList isSortable={true} isEdit={stateEdit} icon={<FaCog/>} node={node}
                            deletable={true}
                            accepted={context.definitions.chart[type] ? context.definitions.chart[type].attributes : null}
                            hiddenAttributes={['type']}
            />
            <BlockContainer isEdit={stateEdit} node={node} onNodeAction={onNodeAction} onAction={onAction} parentAddy={addy.box} blocks={blocks}/>
            {addy.box}
        </Level>
    </>
}
function ChartPreview({node,type,conf,data,colors,labels,style}){
    const editor = useContext(EditorContext);
    const ref = useRef();
    const key = md5(getTreeRef(node)+"~"+editor.numRuns);    
    var odata = {};

    const JStoJSON = s=>{                
        let f = new Function('let data=[];return ('+s+')');
        return f();
    }
    useEffect(()=>{
        //console.info('effet of '+type,key);
        let a = conf.split(',');
        a[0] = `{id:"chart_${key}"`;
        conf = a.join(',');
        odata = JStoJSON(conf);
        let chartStyle = `window.AVAA_STYLE = ${style};`
        let chartGlobals = `window.CHART_COLORS = ${JSON.stringify(colors||{})};`
        chartGlobals += `window.CHART_LABELS = ${JSON.stringify(labels||{})};`
        let chartFunc = `var chart = {};var chartFunc = ()=>{${editor.definitions.chart[type].code};return chart['${type}']};`;
        //console.info(chartFunc);
        let func = `var data = ${data}; var chart_${key} = chartFunc()(data, ${conf});`
        try {
            let f = new Function('return ()=>{'+chartStyle+chartGlobals+chartFunc+func+'}')();    
            f();
        }catch(ex){
            if(!ref || !ref.current) return;            
            ref.current.className = "chart-preview-error";
            ref.current.textContent = ex.message;
        }
    },[key]);
    const download = async (ev,imgType,ext) => {
        let scale=0;
        let background='';
        if(ev.ctrlKey){
            scale = prompt("Enter scale factor","1")*1 || 0;
            background = prompt("Enter background color");
        }
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        let svg = document.getElementById('chart_'+key); // todo: use ref
        //console.info(odata.width,odata.height);
        //console.info(svg.viewBox);
        let vbox = svg.viewBox.baseVal ? svg.viewBox.baseVal : {width:odata.width,height:odata.height};
        canvas.width  = vbox.width;
        canvas.height = vbox.height;
        if(scale){
            canvas.width = Math.round(canvas.width*scale);
            canvas.height = Math.round(canvas.height*scale);
        }
        let svgc = svg.outerHTML;
        if(ext=='jpg' || background){
            ctx.fillStyle = background || "white";
            ctx.fillRect(0, 0, canvas.width, canvas.height);            
        }        
        let opt = {ignoreDimensions: true, ignoreClear:true};
        if(scale){
            opt.scaleWidth = canvas.width;        
            opt.scaleHeight = canvas.height;
        }
        let can = await Canvg.fromString(ctx, svgc,opt);
        can.render();
        canvas.toBlob(function(blob) {            
            FileSaver.saveAs(blob, `chart-${key}.${ext}`);
        },imgType,1);        
    }
    return <div className="chart-preview" ref={ref}><span className="chart-container"><Popup closeOnDocumentClick={true} closeOnEscape={true} position={"right center"}
         trigger={<span className="svg-click-handler"><svg id={'chart_'+key}></svg></span>}>
        <span title="Ctrl+Click to confgiure export options">Download as <br/>
            <a onClick={ev=>download(ev,"image/png",'png')}> PNG</a> &middot; 
            <a onClick={ev=>download(ev,"image/jpeg",'jpg')}> JPEG</a> &middot;
            <a onClick={ev=>download(ev,"image/webp",'webp')}> WEBP</a>
        </span>
        </Popup></span>
    </div>
}
BlockChart.counter=0;
BlockChart.learn = {
    render: (o,node) => {
        if(o.error){
            return <span className="chart-preview-error">{o.error}</span>
        }
        try {
            //
            
            
            //let data = JSON.parse(o.data);
            //let conf = JSON.parse(o.conf);
            //console.info(data)
            return <ChartPreview node={node} type={o.id.split('.')[1]} style={o.style} data={o.data} conf={o.conf} colors={o.colors} labels={o.labels}/>
        }catch(ex){
            return <span className="chart-preview-error">Preview Error: {ex.message}</span>
        }
        
    }
}
BlockChart.addy = {
    id: "chart",
    icon: <span title={"add chart"}><FaChartBar/></span>,
    definitions:'chart',
    make: (id) => Block.parseFromString(`<CHART type="${id}"></CHART>`)
}