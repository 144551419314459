import React, { useEffect, useState } from 'react';
import {FaClipboard, FaClipboardCheck, FaEdit, FaPen, FaRegDotCircle} from "react-icons/fa";
import Popup from 'reactjs-popup';
import useClipboard from '../contexts/Clipboard';
import { AddyBox } from '../blocks/Blocks';
import { useEditor } from '../EditorContext';

export default function NodeActions({node,addy,onAction}) {        
    const clipboard = useClipboard(node);       
    const editor = useEditor();
    const [isPopuped,setPopuped] = useState(false);
    const onOpen = ()=>{ // refactor pliz
        setPopuped(true);
        AddyBox.currentNode=node;
    }
    const close = ()=>{        
        setPopuped(false);       
        AddyBox.currentNode=null; 
    }
    useEffect(() => {        
        editor.on('block-added', close)
        editor.on('block-removed', close)
        return () => {
            editor.off('block-added', close)
            editor.off('block-removed', close)
        }
    }, []);
    return <Popup nested open={isPopuped} onOpen={onOpen} onClose={close} position="bottom left" arrow={false} on={"hover"} className="popup-node-actions" 
            trigger={<a className={"node-actions"+(clipboard.selected?" node-selected":"") } onClick={e=>{
                clipboard.toggle(e);
            }}>
        {clipboard.selected ? <FaClipboardCheck/> :<FaRegDotCircle />}
        </a>} closeOnDocumentClick={true} closeOnEscape={true}>           
            <button onClick={()=>onAction('clone')}>duplicate</button>
            <button onClick={()=>{
                onAction('delete');
                close();//TODO refactor actions, implement block-removed event
            }}>delete</button>                                    
            {clipboard.selection.length ? <button onClick={()=>onAction('paste')}>paste &times;{clipboard.selection.length}</button> : <></>  }
            <Popup position="right" arrow={false} on={"hover"} nested  closeOnDocumentClick={true} closeOnEscape={true} className="popup-node-insert" 
                trigger={<button>insert...</button>}>            
                {addy ? <>{addy}</> : <></>}
            </Popup>
        </Popup>

}
