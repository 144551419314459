import Block from './Block'
import Domain from './Domain'
import BlockSection from "./BlockSection";
import Blocks, {useForceUpdate} from "./Blocks";
import EditorContext from "../EditorContext";
import ButtonAdd from "../ui/ButtonAdd";
import Sections from "./Sections";
import {useCallback, useContext} from "react";
import AutosizeInput from 'react-input-autosize';
import BlockStyle from './BlockStyle';
import { useTranslatableAttribute, useTranslate } from '../contexts/TranslateContext';
import ReactCountryFlag from 'react-country-flag';
import ButtonDelete from '../ui/ButtonDelete';
import ButtonRemove from '../ui/ButtonRemove';
import { useUndo } from '../contexts/UndoContext';
import Modifiers from './Modifiers';
import TextareaAutosize from 'react-textarea-autosize';
export default function Document({file}) {

    const forceUpdate = useForceUpdate();
    //const contextType = useContext(EditorContext);
    const undo = useUndo();

    const addSection = useCallback(() => {
        undo.snap();
        file.document.documentElement.appendChild(Block.parseFromString('<SECTION title="untitled section"></SECTION>'));
        forceUpdate();
    }, [file]);

    if (!file.document) return file.parseError;
        
    /*const onTitleChange = event => {
        file.document.documentElement.setAttribute("title", event.target.value)
        forceUpdate()
    }*/
    let doc = file.document.documentElement;    
   
    //console.info("Document()",styleNode);
    return <div className="editor-document">        
        <Domain node={doc.getElementsByTagName("DOMAIN")[0]}/>
        
        {
        /*<h1>Languages</h1>        
        <DocumentTranslation doc={doc}/>

        <h1>Style</h1>
        <BlockStyle node={styleNode}/> 
        
        <h1>Modifiers</h1>
        <Modifiers node={modsNode}/> 
*/}
        <h1>Document</h1>        
        <DocumentTitle node={doc} />
        <DocumentDescription node={doc} />

        <Sections node={doc}/>
        <div className={"editor-document-actions"}>
            <ButtonAdd onClick={addSection}>add section</ButtonAdd>
        </div>
    </div>

}

function DocumentTitle({node}){
    const translatableTitle = useTranslatableAttribute(node,'title');

    return <div className='proof'><AutosizeInput
    className='document-title'
    type={"text"}
    placeholder='untitled document'
    value={translatableTitle.get()}
    onChange={e=>translatableTitle.set(e.target.value)}
    onFocus={translatableTitle.onFocus}
    onBlur={translatableTitle.onBlur}
    />
    {translatableTitle.hints()}
    </div>
}

function DocumentDescription({node}){
    const translatableDesc = useTranslatableAttribute(node,'description');
    const value = translatableDesc.get();
    return <div className={"proof document-description-container "+(!value ? 'description-empty':'description-not-empty')}><div className="document-description-proof proof-only">
            {value}
        </div><TextareaAutosize
    
    className='document-description proof-hide'
    type={"text"}
    placeholder='add document description'
    value={translatableDesc.get()}
    onChange={e=>translatableDesc.set(e.target.value)}
    onFocus={translatableDesc.onFocus}
    onBlur={translatableDesc.onBlur}
    />
    {translatableDesc.hints()}
    </div>
}