import React from "react";

class AVAAUser {
    id;
    name;    
}


const UserContext = React.createContext(new AVAAUser());

function useUser() {
    const context = React.useContext(UserContext)
    console.info(context);
    if (context === undefined) throw new Error('useUser  must be used within a UserProvider')
    return context
}

export {useUser, UserContext}
export default UserContext
