import React from "react";
import {
    FaArrowRight,
    FaSave
} from "react-icons/fa";
import AttributeField from "../ui/AttributeField";
import HoverActions from "../ui/HoverActions";
import LearnItem from "../ui/LearnItem";
import NodeActions from "../ui/NodeActions";
import Block from "./Block";
import { useForceUpdate } from "./Blocks";
import LevelIcon from "./LevelIcon";

export default function BlockSave({node, onAction, parentAddy}) {
    const forceUpdate = useForceUpdate()
    return (
        <>
            <LevelIcon><FaSave/></LevelIcon> <b className={"color-save"} title="Saves in memory the current selection/data (or current stack if selection is empty)">save </b> 
            <span className={node.hasAttribute('id') && node.getAttribute('id') ? '' : "hide-id"}><AttributeField onChange={forceUpdate} placeholder={"optional id"} hideName={true} node={node} att={"id"}/></span>
            <HoverActions><NodeActions node={node} onAction={onAction} addy={parentAddy} /></HoverActions>        
            <LearnItem node={node} type={BlockSave}/>
        </>
    )

}
BlockSave.learn = {
    render: o => {
        return <span><span>saved {!o.annotations ? 'data' : 'annotations'} </span>
            <span className={"color-save"}>
                 {!o.annotations ? <><b></b></> : <><FaArrowRight/> <b> {o.size}</b></>}
            </span>
        </span>
    }
}
BlockSave.addy = {
    icon: <span title={"save in memory current data or selection (or stack if selection is empty)"}><FaSave/></span>,
    make: ()=> Block.parseFromString('<SAVE />')
}