import Popup from "reactjs-popup";
import { useFile } from "../../contexts/FileContext";
import { useTranslate } from "../../contexts/TranslateContext";
import { FaFlag } from "react-icons/fa";
import ReactCountryFlag from "react-country-flag";
import LanguagesPopup from "./LanguagesPopup";

export default function SwitchLangButton(){
    const file = useFile();
    const translate = useTranslate();
    const changeLang = ev => {
        
        const [lang, setLang] = translate.lang;
        const [langs] = translate.langs;        
        let i = langs.indexOf(lang)+1        
        if(i==langs.length) i=0;
        //console.info('-->',langs,i,'SET:')
        setLang(langs[i]);
        file.lang = langs[i].id// ugly //FIXME
    }
    
    let pop = <Popup nested trigger={<button><i><FaFlag/> languages </i></button>} closeOnDocumentClick={true} closeOnEscape={true} modal>
        <LanguagesPopup />
    </Popup>
    if(!translate.langs[0]?.length) return pop;
    return <span>{pop}<button disabled={translate.langs[0].length<2} onClick={ev=>changeLang(ev)}>
        {translate.lang[0]?.flag ? <ReactCountryFlag svg countryCode={(translate.lang[0].flag).toUpperCase()} /> : <></>}
         <span> {translate.lang[0].name}</span></button></span>
 }
 