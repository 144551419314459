import React, { useState } from "react";
import { FaFlag } from "react-icons/fa";
import { getTreeRef, useForceUpdate } from "../blocks/Blocks";
import ReactCountryFlag from "react-country-flag";
import { useFile } from "./FileContext";
import AutosizeInput from "react-input-autosize";
import Block from "../blocks/Block";
import { useUndo } from "./UndoContext";
import Popup from "reactjs-popup";

const TranslateContext = React.createContext(); 
const langToDefault = (L,context) => !context?.langs[0]?.length ? '' : (L==context?.langs[0][0].id  ?  '' : '_'+((L||'')+'').toLowerCase()) ;
function useTranslate() {
    const context = React.useContext(TranslateContext);
    if (context === undefined) throw new Error('useTranslate must be used within a TranslateProvider')    
    //console.info('useTranslate',context);
    return context;
    return {
        lang:context.lang,
        next:function(){  
            let i = context.      
            console.info('next',context);
            //context.lang = context.lang=='en' ? 'fr' : 'en';        
            //console.info('next',this.lang);
            //setCurrent(context.lang);
        }
    }
}
function useTranslatableAttribute(node,attr) {    
    const context = React.useContext(TranslateContext);
    const undo = useUndo();
    const forceUpdate = useForceUpdate();
    const [focused, setFocused] = React.useState(false);
    if (context === undefined) throw new Error('useTranslateAttribute must be used within a TranslateProvider')    
    const getLang = ()=>{
        
        let ll = langToDefault(context.lang[0]?.id,context);
        //console.info('getLang',ll);
        return ll;
    }
    return {
        context:context,        
        set:v=>{
            let target = getTreeRef(node)+attr+getLang();
            //console.info('TT',target)
            undo.snap(target)
            forceUpdate(node.setAttribute(attr+getLang(),v))
        },
        get:()=>node.getAttribute(attr+getLang())||'',
        onBlur:()=> setFocused(false),
        onFocus:()=>setFocused(true),
        hints:()=><div className="translation-hints-container"><div className={"translation-hints "+(focused?'visible':'')}>
            {context.langs[0].filter(L=>L.id!=context.lang[0]?.id).map(L=><div key={L.id} className="translation-hint">
                <ReactCountryFlag svg countryCode={(L.flag+'').toUpperCase()} /> 
                 {}
                 <AutosizeInput            
            type={"text"}            
            value={node.getAttribute(attr+langToDefault(L.id,context))||''}
            onChange={e=>forceUpdate(node.setAttribute(attr+langToDefault(L.id,context),e.target.value))}
        />
                </div>
             )}
        </div></div>
        

    }
}
function TranslateProvider(props) {
    //TODO refactor this mess
    //console.info('TRANSLATE PROVIDER',props.file,props.file?.document)
    

    let doc = props.file.document.documentElement;
        let tNodes = doc.getElementsByTagName("TRANSLATION");
        if(!tNodes[0]){
            console.info('creating missing TRANSLATION node');
            doc.appendChild(Block.parseFromString('<TRANSLATION></TRANSLATION>'));
            tNodes = doc.getElementsByTagName("TRANSLATION");
        }
        let tNode = tNodes[0];    
        let docLangs = [];
        for(let n of tNode.getElementsByTagName("LANGUAGE")) docLangs.push({
            id:n.getAttribute('id') || '',
            flag:n.getAttribute('flag') || '',
            name:n.getAttribute('name') || ''
        });
    //console.info('docLangs',docLangs);
    const [langs, setLangs] = React.useState(docLangs);
    const valueLangs = React.useMemo(() => [langs, setLangs], [langs])
    const [lang, setLang] = React.useState(docLangs[0]); //langs[0].length ? langs[0][0].id||'' : ''
    const valueLang = React.useMemo(() => [lang, setLang], [lang])
    const update = () => {
        let doc = props.file.document;
        let tNodes = doc.getElementsByTagName("TRANSLATION");
        if(!tNodes[0]){
            console.info('creating missing TRANSLATION node');
            doc.appendChild(Block.parseFromString('<TRANSLATION></TRANSLATION>'));
            tNodes = doc.getElementsByTagName("TRANSLATION");
        }
        let tNode = tNodes[0];    
        let docLangs = [];
        for(let n of tNode.getElementsByTagName("LANGUAGE")) docLangs.push({
            id:n.getAttribute('id') || '',
            flag:n.getAttribute('flag') || '',
            name:n.getAttribute('name') || ''
        });
        if(langs.length!=docLangs.length){
            setLang(docLangs[0]||{})
        }        
        setLangs(docLangs);
    }
    //setLangs(docLangs)
    return <TranslateContext.Provider value={{
        lang:valueLang,
        langs:valueLangs,
        update:update
    }} {...props} />
 }
 
export {useTranslate, useTranslatableAttribute, TranslateContext, TranslateProvider}
export default TranslateContext
    