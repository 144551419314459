import { useEffect, useState } from "react";
import { FaCross, FaPlusCircle, FaSave, FaTimesCircle } from "react-icons/fa";
import Utils from "../converter/Utils";
import { useEditor } from "../EditorContext";
import AttributesList from "./AttributesList";
import SortableNode from "./crudxml/SortableNode";
import AttributeField from "./AttributeField";
import TextContentNode from "./crudxml/TextContentNode";
import Block from "../blocks/Block";

export default function AVAAConfig(){
    const editor = useEditor();    
    const [doc,setDoc] = useState(null);
    const [err,setErr] = useState(null);
    const [saving,setSaving] = useState(false);
    const parseConf = content =>{
        let parser = new DOMParser();   
        let x;             
        try {
            x = parser.parseFromString(content, "text/xml");
            let errNode = x.querySelector('parsererror');
            if (errNode){
                return setErr(errNode.textContent);            
            }
            if(x.documentElement.tagName!='AVAA_CONFIG'){                
                return setErr(`invalid AVAA config XML file!?`);
            }            
            setDoc(x);
        } catch (ex) {
            setErr(ex.message);
        }
        
    }

    const saveConf = ()=>{        
        //console.info("saveConf!");
        let s = Utils.toXML(doc);;
        if(!s) return alert(`Something went wrong serializing to XML!`);
        setSaving(true);
        editor.converter.call("set-conf", {content:s}, r => {
            setTimeout(t=>setSaving(false),500);
        })
    }

    const onAddDirectory = r=>{
        if(!r || !r.path) return;
        let path = r.relative && r.relative.indexOf('../')==-1 ? r.relative : r.path;
        path = path.substring(0,path.lastIndexOf('/'));        

        let already = Array.from(doc.getElementsByTagName("DIR")).map(b=>b.getAttribute('path'));        
        if(already.includes(path)){
            return alert("This directory is already in the list:\n"+path);
        }
        console.info('adding path',path);
        let b = Block.parseFromString('<DIR path="" />');    
        b.setAttribute("path", path);
        doc.documentElement.append(b);
        saveConf();
    }
    useEffect(()=>{
        //console.info('loading conf');
        editor.converter.call("get-conf",{},r=>{
            parseConf(r.content);
        });
    },[])

    
    const pdf = doc ? doc.getElementsByTagName("PDF")[0] : null;
    const temp = doc ? doc.getElementsByTagName("TEMP")[0] : null;
    const mongo = doc ? doc.getElementsByTagName("MONGO")[0] : null;
    return <div className="avaa-config editor">        
        <h2>Configuration 
        <a className={"file-name file-link"} onClick={ev=>editor.callBrowseFile(editor.converter.status.confPath,ev)}><b>{editor.converter.status.confPath}</b></a>
        </h2> 
                        
        {err ? <p className="error">{err}</p> : <></>}
        {doc ? <div>
            <h4>PROJECTS <span>Choose the directories where AVAA should look for XML documents</span></h4>
            <div className="avaa-config-dir">
            <SortableNode onChange={saveConf} node={doc.documentElement} items={
                Array.from(doc.getElementsByTagName("DIR")).map(o=>({
                    node:o,
                    as: <div className='avaa-config-dir-item'>
                        <AttributesList onChange={saveConf} node={o} />
                        <span className="delete-item"><a onClick={ev=>{
                            o.remove();
                            saveConf();
                        }}><FaTimesCircle/></a></span>
                    </div>
                }))} 
                createTrigger={<div className="create-item"><a onClick={ev=>{
                    editor.callFileDialog(editor.converter.status.confPath,'',onAddDirectory)
                }}><FaPlusCircle/>add directory</a></div>}
                />
            </div>
            <h4>TEMP <span>Choose a directory for storing temporary files</span></h4>
            <div>
                { temp ? <AttributeField onBlurChange={saveConf} node={temp} att={'path'} hideName={true} definition={{
                    name:'',
                    label:'temp directory',
                    type:'string',
                    desc:'Path to the directory AVAA will use for creating temporary files'
                }} />  : <a onClick={ev=>{                
                    doc.documentElement.append(Block.parseFromString(`<TEMP path="" />`));
                    saveConf();
    }}>set a custom temp path</a>}                
            </div>            
            <h4>PDF <span>Configure the PDF engine and its arguments</span></h4>
            <div className="avaa-config-pdf">
            { pdf ? <div>
                <AttributeField onBlurChange={saveConf} node={pdf} att={'exe'} hideName={true} definition={{
                    name:'',
                    label:'executable path',
                    type:'string',
                    desc:'Path to the executable used for generating PDF from HTML'
                }} />                
                <SortableNode onChange={saveConf} node={pdf} items={
                Array.from(pdf.getElementsByTagName("ARG")).map(o=>({
                    node:o,
                    as: <div className='avaa-config-pdf-arg'>
                        <TextContentNode node={o} onChange={saveConf}/>
                        <span className="delete-item"><a onClick={ev=>{
                            o.remove();
                            saveConf();
                        }}><FaTimesCircle/></a></span>
                    </div>
                }))} 
                createTrigger={<div className="create-item"><a onClick={ev=>{
                    let b = Block.parseFromString('<ARG></ARG>');    
                    pdf.append(b);
                    saveConf();
                }}><FaPlusCircle/>add argument</a></div>}                
                />
            </div> : <a onClick={ev=>{                
                doc.documentElement.append(Block.parseFromString('<PDF exe="auto">\
    <ARG>--headless=old</ARG>\
    <ARG>--no-margins</ARG>\
    <ARG>--no-pdf-header-footer</ARG>\
    <ARG>--virtual-time-budget=3000</ARG>\
    <ARG>--run-all-compositor-stages-before-draw</ARG>\
    <ARG>-–allow-file-access-from-files</ARG>\
    <ARG>--print-to-pdf=${PDF_FILE}</ARG>\
    <ARG>${HTML_FILE}</ARG>\
</PDF>'));
                saveConf();
}}>create default PDF settings</a>
            }</div>
            <h4>MONGO <span>Configure MongoDB connection</span></h4>            
            <div className='avaa-config-mongo'>
            {mongo ? <div >
                        <AttributesList onChange={saveConf} node={mongo} />
                    </div>
            : <a onClick={ev=>{                
                doc.documentElement.append(Block.parseFromString(`<MONGO host="localhost" port="27017" db="test" />`));
                saveConf();
}}>create default Mongo settings</a>}                
            </div>

        </div> : <></>}
        <FaSave className={"notification " + (saving ? "visible" : "")} />
    </div>
}