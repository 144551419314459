import Block from './Block'
import Domain from './Domain'
import BlockSection from "./BlockSection";
import Blocks, {useBlock, useForceUpdate} from "./Blocks";
import EditorContext from "../EditorContext";
import ButtonAdd from "../ui/ButtonAdd";
import Sections from "./Sections";
import React, {useCallback, useContext} from "react";
import AutosizeInput from 'react-input-autosize';
import { SketchPicker } from 'react-color';
import Popup from 'reactjs-popup';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { useTranslate } from '../contexts/TranslateContext';
import ButtonDelete from '../ui/ButtonDelete';
import { useUndo } from '../contexts/UndoContext';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";
export default function BlockCSS({node}) {
    const translate = useTranslate();
    const undo = useUndo();
    const forceUpdate = useForceUpdate();
    const contextType = useContext(EditorContext);
    const block = useBlock(node);

    const addSection = useCallback(() => {
        //file.document.documentElement.appendChild(Block.parseFromString('<SECTION title="untitled section"></SECTION>'));
        forceUpdate();
    }, [node]);
    const items = [];
    const sections = [];

    for (let c of node.getElementsByTagName("CHART_COLOR")) {
        block.idize(c)
        items.push({node: c, id: c._id});
        //console.info('SECTION ID', section._id);        
    }

    const onIdChange = (node,event) => {
        undo.snap(node,'id');
        node.setAttribute("id", event.target.value)
        //console.info(node,event);
        forceUpdate()
    }
    const onLabelChange = (node,event) => {
        undo.snap(node,'label');
        node.setAttribute("label", event.target.value)
        //console.info(node,event);
        forceUpdate()
    }
    const onColorChange = (node,value) => {
        //console.info(node,value);
        undo.snap(node,'color');
        node.setAttribute("color", value.hex)
        forceUpdate()
    }
    const onTypeChange = (node,event) => {
        //console.info(node,value);
        undo.snap(node,'type');
        node.setAttribute("type", event.target.value)
        forceUpdate()
    }

    const addColor = ev => {
        undo.snap();
        node.appendChild(Block.parseFromString(`<CHART_COLOR id="" color="" />`));
        forceUpdate();
    }

    let cssNodes = node.getElementsByTagName("CSS");
    if(!cssNodes[0]){
        console.info('creating missing CSS node');
        node.appendChild(Block.parseFromString('<CSS></CSS>'));
        cssNodes = node.getElementsByTagName("CSS");
    }
    let cssNode = cssNodes[0];
    
    const onAceChange = React.useCallback(value => {
        console.info(value)
        cssNode.textContent = value || '';        
        //undo.add(node);
        forceUpdate();
        //onChange && onChange();
    },[node])

    const cssContent = cssNode.textContent || '';
    
    return <div className="editor-style editor-css">               
        <h2>Custom CSS</h2>        
        <AceEditor
  placeholder=""
  mode="css"
  theme="tomorrow"
  name={"css"+node}  
  onChange={onAceChange}
  fontSize={14}
  maxLines={Infinity}
  showPrintMargin={false}
  showGutter={false}
  highlightActiveLine={false}
  value={cssContent}
  setOptions={{
  enableBasicAutocompletion: false,
  enableLiveAutocompletion: false,
  enableSnippets: false,
  showLineNumbers: true,
  tabSize: 2,
  }}/>               
    </div>

}