import React from "react";
import {
    FaComment, FaDotCircle
} from "react-icons/fa";
import AutosizeInput from "react-input-autosize";
import ButtonClone from "../ui/ButtonClone";
import ButtonDelete from "../ui/ButtonDelete";
import HoverActions from "../ui/HoverActions";
import Block from "./Block";
import LevelIcon from "./LevelIcon";
import {useForceUpdate} from "./Blocks";
import { useUndo } from "../contexts/UndoContext";
import NodeActions from "../ui/NodeActions";
export default function BlockComment({node, onAction, parentAddy}) {
            
    const forceUpdate = useForceUpdate();
    const undo = useUndo();
    const onFieldChange = React.useCallback(ev => {        
        undo.snap(node);
        node.childNodes[0].nodeValue = ev.target.value;        
        
        forceUpdate();
        //onChange && onChange();
    },[node])
    if(!node.childNodes[0]){
        node.append(Block.parseFromString('<![CDATA[]]>'))
    }
    const txt = node.childNodes[0].textContent || '';
    //console.info("update txt:",txt);    
    const target = node.getAttribute("target") || "";
    const toggle = ()=>{        
        node.setAttribute("target",target ? "" : "doc");
        forceUpdate();
    }
    //TODO console.info("TARGET IS",target);
    return <>
            <LevelIcon onDoubleClick={toggle} className={"target-"+target}><FaComment/></LevelIcon>
            <AutosizeInput
                placeholder={'enter comment'}
                type={"text"}
                value={txt}
                onChange={onFieldChange}
            />
            <HoverActions><NodeActions node={node} onAction={onAction} addy={parentAddy} /></HoverActions>
        </>

}
BlockComment.addy = {
    icon: <span title={"add a comment"}><FaComment/></span>,
    make: ()=>Block.parseFromString('<COMMENT><![CDATA[]]></COMMENT>')
}