export default class EventDispatcher extends EventTarget {

    on(ev, listener) {
        super.addEventListener(ev, listener);
    }

    off(ev, listener) {
        super.removeEventListener(ev, listener);
    }

    dispatch(eventName, data) {
        const event = new CustomEvent(eventName, {detail: data});
        super.dispatchEvent(event);
    }
}