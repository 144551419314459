import { FaRecycle } from "react-icons/fa";
import Popup from "reactjs-popup";
import ModsPopup from "./ModsPopup";

export default function ModsPopupButton(){
        
    return <Popup trigger={<button><i><FaRecycle/> modifiers </i></button>} className="popup-mods" closeOnDocumentClick={true} closeOnEscape={true} modal nested>
        <ModsPopup />
    </Popup>        
 }
 