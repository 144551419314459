import React from "react";
import { useFile } from "./FileContext";
import { getTreeRef } from "../blocks/Blocks";

const UndoContext = React.createContext({
    list: [], 
    add:function(c){
        this.list.push(c);
    }
});

function useUndo() {
    const context = React.useContext(UndoContext)
    const file = useFile();    
    if (context === undefined) throw new Error('useUndo must be used within an UndoProvider')
    const snap = (target,key)=>{
        if(!file.document){
            console.warn('cannot snap without document')
            return;
        }
        if(target instanceof Node){
            target = getTreeRef(target);            
        }
        if(key) target+=':'+key;
        if(!file.undoList) file.undoList = [];
        if(target && file.undoList.length){
            let last = file.undoList[file.undoList.length-1];
            if(last.target === target) return;
        }
        file.undoList.push({
            document: file.document.cloneNode(true),
            target:target
        })
        //console.info(file.undoList);
        file.dispatch('undo-added')
    }
    return {
        context:context,
        snap:snap
    }
}

export {useUndo, UndoContext}
export default UndoContext
