import { useEffect, useState } from "react";
import { useEditor } from "../../EditorContext";
import { LearnStep } from "../learn/StepLogger";

export default function AVAAUpdater() {
    const editor = useEditor()
    const [state,setState] = useState(null)
    const updateNow = ()=>{
        setState({})
        editor.converter.call('update-avaa');
    }
    useEffect(() => {
        const onUpdateProgress = (v) => {            
            if(!v.detail.log) return;
            setState(v.detail.log);
            //setProgress(v.detail.total);
            //setProgressFFMPEG(v.detail.ffmpeg);
        }
        editor.converter.on('update-avaa', onUpdateProgress)
        return () => {
            editor.converter.off('update-avaa', onUpdateProgress)            
        }
    }, []);
    return <div>
        {state && state.steps ? state.steps.map((o,i)=><LearnStep key={i} step={o}/>) : <></>}
        <button onClick={updateNow} disabled={!!state}>Update</button>
    </div>
}