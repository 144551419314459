import React from 'react';
import {useState} from 'react'

export default function ButtonToggle({label, toggled, onClick}) {
    const [isToggled, toggle] = useState(toggled)

    const callback = () => {
        toggle(!isToggled)
        onClick(!isToggled)
    }
    const onChange = ev => {
        console.info(ev);
        //TODO clean
    }
    return (
        <label>
            <input type="checkbox" checked={!!isToggled} onChange={onChange} onClick={callback}/>
            <span/>
            <strong style={{opacity: isToggled ? 1 : 0.5}}>{label}</strong>
        </label>
    )
}