import Block from "../blocks/Block";
import React, {useContext, useState} from "react";
import EditorContext from "../EditorContext";
import ButtonDelete from "./ButtonDelete";
import { FaGlassCheers } from "react-icons/fa";

export default function DefinitionList({definitions,onSelect}) {
    
    return <div className={"def-list"}>
        {
            Object.values(definitions)
            .filter(o => !o.hidden)
            .filter(o => !o.deprecated)
            .sort((a,b)=> a.id > b.id ? 1 : -1)
            .map(o => <div  onClick={e=>onSelect(o)} key={o.id} className="classy-item">
                <a>{o.id}</a>
                {o.experimental ? <b title="experimental feature">🧪</b> : <></>}
                
                <p>{o.desc}</p>
                {o.img ? <div className="definition-image">
                    <img src={"data:image/png;base64, "+o.img} />
                </div> : <></>}
            </div> 
            
            )}
    
    </div>

}