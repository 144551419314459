import React, { useState } from "react";
import { FaCog, FaLink, FaLongArrowAltRight, FaRegSave, FaTimes } from "react-icons/fa";
import { useEditor } from "../EditorContext";
import AttributeField from "../ui/AttributeField";
import AttributesList from "../ui/AttributesList";
import ButtonEdit from "../ui/ButtonEdit";
import HoverActions from "../ui/HoverActions";
import LearnItem from "../ui/LearnItem";
import LearnProc from "../ui/LearnProc";
import NodeActions from "../ui/NodeActions";
import Block from "./Block";
import BlockComment from "./BlockComment";
import BlockContainer from "./BlockContainer";
import BlockExtra from "./BlockExtra";
import BlockFilter from "./BlockFilter";
import BlockIf from "./BlockIf";
import BlockLoad from "./BlockLoad";
import BlockLocal from "./BlockLocal";
import BlockOp from "./BlockOp";
import { useAddy, useForceUpdate } from "./Blocks";
import BlockSave from "./BlockSave";
import BlockSelect from "./BlockSelect";
import BlockSet from "./BlockSet";
import BlockStack from "./BlockStack";
import BlockTest from "./BlockTest";
import BlockUnstack from "./BlockUnstack";
import Level from "./Level";
import LevelIcon from "./LevelIcon";

export default function BlockProc({node, blocks, onAction, onNodeAction, isEdit, parentAddy}) {
    const editor = useEditor()
    const forceUpdate = useForceUpdate()
    const [stateEdit, setEdit] = useState((!!isEdit) || node.attributes.length<3)    
    const [stateTypeEdit, setTypeEdit] = useState(false)
    const editType = () => {
        setTypeEdit(!stateTypeEdit);
    }
   
    const type = node.getAttribute("type")

    const addy = useAddy({
        node, update: forceUpdate,
        types: [BlockSet, BlockSelect, BlockOp, BlockFilter, BlockStack, BlockUnstack, BlockSave, BlockLoad, BlockExtra, BlockIf, BlockLocal, BlockTest, BlockComment],
        container: BlockProc,
        containerType: type
    })

    if(!node.getAttribute("pipeline-cache-id")) node.setAttribute("pipeline-cache-id",Math.random());

    const isDisabled = ()=>{
        return node.getAttribute("disabled")=='true'
    }
    const isCached = ()=>{
        return node.getAttribute("pipeline-cache")=='true';
    }
    const toggleDisabled = () =>{
        node.setAttribute("disabled",isDisabled() ? "false" : "true");
        forceUpdate();    
    }
    const toggleCached = () =>{
        node.setAttribute("pipeline-cache",isCached() ? "false" : "true");        
        if(!isCached()){
            node.setAttribute("pipeline-cache-id",Math.random());
            // well....
        }
        forceUpdate();    
    }
    const toggleEdit = () => {
        setEdit(!stateEdit)
    }
    
    const def = editor.definitions.proc[type] || {};
    return <>
        <LevelIcon><FaLink /></LevelIcon> 
        
            <button title={isDisabled() ? "enable this processor" : "disable this processor"}  className={"toggle-disable "+(isDisabled() ? 'disabled' : '')} onClick={()=>toggleDisabled()} >{isDisabled() ? <FaTimes/> : <FaLongArrowAltRight/>}</button> 

            <b> <b title={def.desc||''} className={ def.id ? '' : 'wavywarn'}><b>
            {stateTypeEdit 
            ? <AttributeField autoFocus={true} onEnterKey={editType} onEscapeKey={editType} definition={{label:'type',choices:editor.getDefinitionsOf('proc')}} placeholder={"proc type"} hideName={true} node={node} att={"type"}/>
            : <a className="type" onClick={editType}>{type}</a> }</b></b> </b>
                    {def.cachable?<button className={"toggle-cache "+(isCached() ? 'cached' : '')} title={isCached() ? "disable cache" : "enable cache"} onClick={()=>toggleCached()}>{isCached() ? <FaRegSave/> : <FaRegSave/>}</button>:<></>}                            
        <ButtonEdit onClick={toggleEdit} isEdit={stateEdit}/>        
        <HoverActions><NodeActions node={node} onAction={onAction} addy={parentAddy} /></HoverActions>        
        {!isDisabled() ? <LearnItem node={node} type={BlockProc}/> : <></>}
        <Level isEdit={stateEdit} className={isDisabled() ?'proc-disabled':''}>
            <LearnProc node={node} disabled={isDisabled()} />
            <AttributesList isSortable={true} isEdit={stateEdit} icon={<FaCog/>} node={node}
                            deletable={true}
                            accepted={def.attributes || null}
                            hiddenAttributes={['type','disabled','pipeline-cache','pipeline-cache-id']}
            />
            <BlockContainer isEdit={stateEdit} node={node} blocks={blocks} onNodeAction={onNodeAction} parentAddy={addy.box} onAction={onAction}/>
            {addy.box}            
        </Level>
    </>
}
BlockProc.nopaste = ['VIEW','CHART']; //TODO
BlockProc.learn = {
    render: o => {
        let out = (o.output || "").trim();
        let obj;
        if(out.indexOf("org.mozilla.javascript.Undefined@")===0){
            return <></>
        }
        if (out && (out[0]=='{' || out[0]=='[')) {
            try {
                let j = obj = JSON.parse(out);
                if (j && j.length !== undefined && j[0] && j[0][0]=='@') out = "added " +j.length + " annotations to corpus";
                else if (j && Object.keys(j).length) out = Object.keys(j).length;
            } catch (ex) {
                console.warn(ex);                
            }
        }
        return <span>
            <i></i> <b className={"color-proc"}>{out}</b>
            </span>
    }
}
BlockProc.addy = {
    id: "proc",
    icon: <span title={"add processor"}><FaLink /></span>,
    definitions: 'proc',
    make: (id)=>Block.parseFromString('<PROC type="' + id + '"></PROC>')    
}