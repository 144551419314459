import React, { useContext, useState } from "react";
import { FaCog, FaEye } from "react-icons/fa";
import EditorContext, { useEditor } from "../EditorContext";
import AttributeField from "../ui/AttributeField";
import AttributesList from "../ui/AttributesList";
import ButtonEdit from "../ui/ButtonEdit";
import HoverActions from "../ui/HoverActions";
import LearnItem from "../ui/LearnItem";
import NodeActions from "../ui/NodeActions";
import Block from "./Block";
import BlockComment from "./BlockComment";
import BlockContainer from "./BlockContainer";
import BlockExtra from "./BlockExtra";
import BlockFilter from "./BlockFilter";
import BlockIf from "./BlockIf";
import BlockLoad from "./BlockLoad";
import BlockLocal from "./BlockLocal";
import BlockOp from "./BlockOp";
import { useAddy, useForceUpdate } from "./Blocks";
import BlockSave from "./BlockSave";
import BlockSelect from "./BlockSelect";
import BlockSet from "./BlockSet";
import BlockStack from "./BlockStack";
import BlockTest from "./BlockTest";
import BlockUnstack from "./BlockUnstack";
import Level from "./Level";
import LevelIcon from "./LevelIcon";

export default function BlockView({node, blocks, onAction, onNodeAction, isEdit, parentAddy}) {
    const editor = useEditor()
    const forceUpdate = useForceUpdate()
    const [stateEdit, setEdit] = useState((!!isEdit) || !node.childNodes.length)
    const [stateTypeEdit, setTypeEdit] = useState(false)
    const editType = () => {
        setTypeEdit(!stateTypeEdit);
    }

    const type = node.getAttribute("type")

    const addy = useAddy({
        node, update: forceUpdate,
        types: [BlockSet, BlockSelect, BlockOp, BlockFilter, BlockStack, BlockUnstack, BlockSave, BlockLoad, BlockExtra, BlockIf, BlockLocal, BlockTest, BlockComment],
        container: BlockView,
        containerType: type
    })


    const toggleEdit = () => {
        setEdit(!stateEdit)
    }

    return <>
        <LevelIcon><FaEye/></LevelIcon> <b> view - <b className={editor.definitions.view[type] ? '' : 'wavywarn'}><b>
            {stateTypeEdit 
            ? <AttributeField autoFocus={true} onEnterKey={editType} onEscapeKey={editType} definition={{label:'type',choices:editor.getDefinitionsOf('view')}} placeholder={"view type"} hideName={true} node={node} att={"type"}/>
            : <a className="type" onClick={editType}>{type}</a> }</b></b> </b>
        {editor.definitions.view[type] && editor.definitions.view[type].attributes.length ? <ButtonEdit isEdit={stateEdit} onClick={toggleEdit}/> :<></>}
        <HoverActions><NodeActions node={node} onAction={onAction} addy={parentAddy} /></HoverActions>        
        <LearnItem node={node} type={BlockView}/>
        <Level isEdit={stateEdit}>
            <AttributesList isSortable={true} isEdit={stateEdit} icon={<FaCog/>} node={node}
                            deletable={true}
                            accepted={editor.definitions.view[type] ? editor.definitions.view[type].attributes : null}
                            hiddenAttributes={['type']}
            />
            <BlockContainer isEdit={stateEdit} node={node} blocks={blocks} onNodeAction={onNodeAction} onAction={onAction} parentAddy={addy.box}/>
            {addy.box}
        </Level>
    </>
}
BlockView.nopaste = ['VIEW','CHART']; //TODO
BlockView.learn = {
    render: o => {
        return <span></span>
    }
}
BlockView.addy = {
    id: "view",
    icon: <span title={"add view"}><FaEye/></span>,
    definitions:'view',
    make: (id) => Block.parseFromString(`<VIEW type="${id}"></VIEW>`)
}