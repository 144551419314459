import BlockContainer from "./BlockContainer";
import BlockOp from "./BlockOp";
import BlockView from "./BlockView";
import BlockLocal from "./BlockLocal";
import BlockSet from "./BlockSet";
import BlockSelect from "./BlockSelect";
import BlockExtra from "./BlockExtra";
import React, {useContext, useState} from "react";
import {FaBolt, FaClipboard, FaPaste, FaSearch, FaTag} from "react-icons/fa";
import ButtonDelete from "../ui/ButtonDelete";
import EditorContext, { useEditor } from "../EditorContext";
import BlockStack from "./BlockStack";
import BlockSave from "./BlockSave";
import BlockChart from "./BlockChart";
import BlockLoad from "./BlockLoad";
import BlockLegend from "./BlockLegend";
import BlockComment from "./BlockComment";
import BlockFilter from "./BlockFilter";
import BlockUnstack from "./BlockUnstack";
import BlockTest from "./BlockTest";
import BlockHTML from "./BlockHTML";
import BlockMarkdown from "./BlockMarkdown";
import BlockDoc from "./BlockDoc";
import BlockIf from "./BlockIf";
import BlockIfTags from "./BlockIfTags";
import BlockProc from "./BlockProc";
import Popup from "reactjs-popup";
import DefinitionList from "../ui/DefinitionList";
import ButtonAdd from "../ui/ButtonAdd";
import BlockLoop from "./BlockLoop";
import useClipboard, { useClipboardSelection } from "../contexts/Clipboard";
import { useUndo } from "../contexts/UndoContext";

//TODO refactor!

function RenderDef ({node,update,onBtn,type,idx}){
    const [popuped,setPopuped] = useState(false)
    const editor = useEditor();
    //if(type.addy.definitions){       
        let defType = type.addy.definitions;        
        const onSelect = (o) => {            
            console.info("ON SELECT",o,type);
            if(type.addy.onSelect) type.addy.onSelect(node,o.id);
            else{
                let e = type.addy.make(o.id);
                if(!AddyBox.currentNode) node.append(e);
                else AddyBox.currentNode.before(e)
            }
            editor.dispatch("block-added");
            setPopuped(false)
            update && update();
            //onDone();
        }
        const onChange = (ev) => {
            onSelect({id:ev.target.value});            
        }            
        return <>
            <button onClick={() => setPopuped(true)} >{type.addy.icon}</button>
            <Popup className="addy-def-list" onClose={ev=>setPopuped(false)} open={popuped} nested modal>
                <div><select onChange={onChange}>
                        <option value="">quick choice...</option>
                        {Object.values(editor.definitions[defType])
                            .filter(o => !o.hidden)
                            .sort((a,b)=>(a.id>b.id?1:-1))
                            .map(o => <option key={o.id} value={o.id}>{o.id}</option>)}
                            :<></>
                        
                    </select>
                        
                    <DefinitionList definitions={editor.definitions[defType]} onSelect={onSelect}/>            
                </div>
            </Popup>
        </>
    
}

export function AddyBox({addy,buttons,content,alreadyOpened}){
   
    buttons = buttons || addy.buttons;
    content = content || addy.content;    
    const [opened, setOpened] = useState(!!alreadyOpened)
    const onAdd = (ev)=>{
        AddyBox.currentNode = null; // this is set with context menu "insert..."        
        setOpened(!opened);
    }    
   
    return <span className={"addy-box "+(opened?"opened":"")}>
        <ButtonAdd onClick={onAdd}></ButtonAdd>
       {true ? content : <></>}
    </span>     
}

export function useAddy({node, types, onButton, onAdded, update, containerType, container}) {
    const clipboard = useClipboard()
    const clipboardSelection = useClipboardSelection()
    const [expanded, setExpand] = useState(null)
    const [popup, setPopup] = useState(null)
    const context = useContext(EditorContext)
    const undo = useUndo()
 
    const onBtn = (type) => {   
        //console.info("onBtn",AddyBox.currentNode);
        if (type.addy.popup) {
            setPopup(type.addy.popup({
                node,
                context,
                onDone: onExpandDone,
                containerType: containerType,
                container: container
            }))
        }
        else if (type.addy.expand) {
            setExpand(type.addy.expand({
                node,
                context,
                onDone: onExpandDone,
                containerType: containerType,
                container: container
            }))
        } else if(type.addy.make){
            let e = type.addy.make();
            if(!AddyBox.currentNode) node.append(e);
            else AddyBox.currentNode.before(e)
            onAdded && onAdded();
        }else{
            type.addy.addTo(node);
            onAdded && onAdded();
        }
        context.dispatch("block-added");
        AddyBox.currentNode=null;
        update && update();
        
    }
    const onExpandDone = () => {
        setExpand(null)
        setPopup(null)
        onAdded && onAdded();
    }
    const cancel = () => {
        setExpand(null)
    }
    const onPaste = (ev)=>{
        //console.info(node)
        undo.snap();                
        const children = Array.from(clipboardSelection[0].parentNode.children);
        clipboardSelection.sort((a,b)=>{
            return children.indexOf(a)>children.indexOf(b) ? 1 : -1;
        }).forEach(e=>{
            node.append(e.cloneNode(true));
        })        
        context.dispatch("block-added");
        //AddyBox.currentNode=null;
        update && update();
        //setOpened(!opened);
    }

    let buttons = types.map((type, idx) => type.addy.definitions ? 
        <RenderDef key={idx} node={node} onBtn={onBtn} update={update} type={type} idx={idx} /> : 
        <button onClick={() => onBtn(type)} key={idx}>{type.addy.icon}</button>)
    
        if(clipboard.selection.length) buttons.splice(0,0,<button key="_paste">
            <FaPaste onClick={onPaste} title={`copy here ${clipboard.selection.length} selected block${clipboard.selection.length>1 ? 's':''} `}/>
        </button>)
 


    let content = popup ? <>{buttons}{popup}</> : (expanded ? <span>{expanded} <a onClick={cancel}>cancel</a></span> : <>{buttons}</>)
    let box = <AddyBox buttons={buttons} content={content}/>    
    return {
        box:box,        
        content: content,
        buttons: buttons
    }

}

export function getTreeRef(node) {
    //console.info(node.parentNode);
    let s = "";
    while (node) {
        let parent = node.parentNode;
        let idx = -1;
        let c = 0;
        if (parent) {
            for (let i = 0; i < parent.childNodes.length; i++) {
                let item = parent.childNodes.item(i);
                if (item.nodeType !== 1) continue;
                c++;
                if (item == node) {
                    idx = c;
                    break;
                }
            }
        }

        s = node.tagName + '[' + idx + ']' + ' > ' + s;
        if (node.tagName == 'DOCUMENT') break;
        node = parent;

    }
    return s;
}

export function useLearn(node) {

    return {
        getRef: () => getTreeRef(node)
    }
}

export function useBlock(node) {
    function idize(node) {
        if (!node) return;
        if (!node._id) node._id = Math.random();
        if (node.children) for (let c of node.children) if (c && !c._id) c._id = Math.random();
    }


    let reidize = function reidize(node) {
        if (!node) return;
        node._id = Math.random();
        if (node.children) for (let c of node.children) reidize(c);
    }
    idize(node);

    return {
        reidize: reidize,
        idize: idize,
        getRef(){
            return getTreeRef(node);
        },
        setAtt: (att, val) => {
            node.setAttribute(att, val);
        },
        getAtt: function (att, def) {
            return node.hasAttribute(att) ? node.getAttribute(att) : def;
        },
        isAttTrue: function (att) {
            return this.getAtt(att) == "true";

        }
    }
}

export function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // A function that increment 👆🏻 the previous state like here
    // is better than directly setting `setValue(value + 1)`
}

export default class Blocks {
    
    
    static theBlocks = {
        'OP': BlockOp,
        'AN': BlockSelect, // legacy
        'SELECT': BlockSelect,
        'FILTER': BlockFilter,
        'SET': BlockSet,
        'LOCAL': BlockLocal,
        'PROC': BlockProc,
        'VIEW': BlockView,
        'CHART': BlockChart,
        'LEGEND': BlockLegend,
        'EXTRA': BlockExtra,
        'IF': BlockIf,
        'IFTAGS': BlockIfTags,
        'LOOP': BlockLoop,
        'STACK': BlockStack,
        'UNSTACK': BlockUnstack,
        'SAVE': BlockSave,
        'LOAD': BlockLoad,
        'TEST': BlockTest,
        'COMMENT':BlockComment,
        'MARKDOWN':BlockMarkdown,
        'HTML':BlockHTML,
        'DOC':BlockDoc

    }
}