import Block from "../../blocks/Block";
import Modifiers from "../../blocks/Modifiers";
import { useFile } from "../../contexts/FileContext";

export default function ModsPopup({}){    
    
    const file = useFile();
    

    const documentElement = file.document.documentElement;
    let modsNodes = documentElement.getElementsByTagName("MODS");
    if(!modsNodes[0]){
        console.info('creating missing MODS node');
        documentElement.appendChild(Block.parseFromString('<MODS></MODS>'));
        modsNodes = documentElement.getElementsByTagName("MODS");
    }
    let modsNode = modsNodes[0];
    let count = 0;//modsNode.getElementsByTagName("MOD").length;
    return <div>        
        <h2>Modifiers{count ? ' ('+count+')' : ''}</h2>
        <Modifiers node={modsNode}/> 
    </div>
}