import React, { useState } from 'react';
import AutosizeInput from "react-input-autosize/lib/AutosizeInput";
import HoverActions from "./HoverActions";
import ButtonClone from "./ButtonClone";
import ButtonDelete from "./ButtonDelete";
import {useForceUpdate} from "../blocks/Blocks";
import ButtonToggle from "./ButtonToggle";
import {useFile} from "../contexts/FileContext";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";
import { useUndo } from '../contexts/UndoContext';
import Popup from 'reactjs-popup';
import { useEditor } from '../EditorContext';
import TagListField from './TagListField';
import ColorPickerButton from './ColorPickerButton';
import EditableSelect from './EditableSelect';

export default function AttributeField({
                                           node,
                                           type,
                                           att,
                                           cbDelete,
                                           definition,
                                           prefix,
                                           hideName,
                                           hideEquals,
                                           warn,
                                           warnClass,
                                           warnTitle,
                                           placeholder,
                                           onEnterKey,
                                           onEscapeKey,
                                           onChange,
                                           onBlurChange,
                                           autoFocus,
                                           title
                                       }) {

    const [changed,setChanged] = useState(false);
    const forceUpdate = useForceUpdate();
    const editor = useEditor();
    const undo = useUndo();
    const file = useFile()
    prefix = prefix || '';

    //console.info('ATT', att, prefix);

    const onKeyDown = event => {        
        if(onEnterKey && event.keyCode==13) onEnterKey();
        if(onEscapeKey && event.keyCode==27) onEscapeKey();
    }
    function onFieldFocus(event){        
        setChanged(false);
    }
    function onFieldBlur(event){
        changed && onBlurChange && onBlurChange();
    }

    function onFieldChange(event) {
        //console.info(event);
        undo.snap(node,att);
        node.setAttribute(att, event.target.value);
        setChanged(true);
        forceUpdate();
        onChange && onChange();
    }
    function onEditableSelectChange(v) {
        console.info('cc',v);
        undo.snap(node,att);
        node.setAttribute(att, v);
        setChanged(true);
        forceUpdate();
        onChange && onChange();
    }
    function onBoolChange(ev) {
        undo.snap(node,att);
        let value = ev.target.checked;
        node.setAttribute(att, value ? "true" : "false");
        setChanged(true);
        forceUpdate();
        onChange && onChange();
    }
    function onColorPickerChange(value) {
        undo.snap(node,att);        
        node.setAttribute(att, value);
        setChanged(true);
        forceUpdate();
        onChange && onChange();
    }
    function onTierChange(ev) {
        undo.snap(node,att);
        let value = ev.target.value || '';
        node.setAttribute(att, value);
        forceUpdate();
        onChange && onChange();
    }
    function onTierChange2(value) {
        undo.snap(node,att);        
        node.setAttribute(att, value||'');
        forceUpdate();
        onChange && onChange();
    }
    function onAceLoad(ev) {
    }
    function onAceChange(ev) {        
        undo.snap(node,att);
        let value = ev || '';
        node.setAttribute(att, value);
        forceUpdate();
        onChange && onChange();
        
    }

    const defineMatrix = ()=>{
        
    }

    const onFileDialogResult = r=>{
        if (!r || !r.name) return;
        let path = r.relative && r.relative.indexOf('../')==-1 ? r.relative : r.path;
        node.setAttribute(att, path);
        forceUpdate();
        onChange && onChange();
    }
    const onFilesDialogResult = r=>{
        //if (!r || !r.name) return;
        console.info('RESULT',r);
        //return;
        //TODO popup
        //let path = r.relative && r.relative.indexOf('../')==-1 ? r.relative : r.path;
        if(!r || !r.length) return;
        node.setAttribute(att, JSON.stringify(r));
        forceUpdate();
        onChange && onChange();
    }
    const chooseFile = () => {
        editor.converter.call("file-dialog", {filter: '*'}, onFileDialogResult);
    }
    const chooseFiles = () => {
        editor.converter.call("file-dialog", {filter: '*',multiple:true}, onFilesDialogResult);
    }
    //console.info(' DEFINITION', definition)
    let label = definition ? definition.label || "" : "";
    
    title = title || (definition ? definition.desc : "");
    let lbl = att + '';
    if (prefix && lbl.substring(0, prefix.length) === prefix) {
        lbl = lbl.substring(prefix.length);
    }
    if(definition?.deprecated && !warnTitle){
        warn = true;
        warnTitle = "This feature is deprecated";
        warnClass = "deprecated";
    }
    let name = hideName ? "" : <span><b title={warnTitle||title||''} className={warn ? 'wavywarn '+(warnClass||'') : ''}>{lbl}</b></span>
    let input;
    let value = node.getAttribute(att);
    if (value === null && type == 'bool' && definition && definition.def) {
        value = definition.def;
    }    
    let fieldType = type || definition?.type;
    //if (definition && definition.choices && type == 'string') fieldType = 'choices';
    if (definition && definition.def) placeholder = definition.def;
    const choices = definition && definition.choices ? (Array.isArray(definition.choices) ? definition.choices : definition.choices.split(',')) : []
    const onTagListChange = (tags)=>{
        node.setAttribute(att, tags.join(','));
        forceUpdate();
        onChange && onChange();
    }
    switch (fieldType) {
        case 'tags':
            input = <TagListField editable={true} tags={value} onChange={onTagListChange}/>
            break;
        case 'files':            
            if(!value) {
                input = <a onClick={chooseFiles} title="choose files from your computer"><i>choose...</i></a>;
                break;
            }
            let j;
            try {
                j = JSON.parse(value);
            }catch(ex){

            }
            if(!j){
                input = <span className='error' title={value}>could not parse value</span>
                break;
            }            
            input = <a onClick={chooseFiles} title="choose files from your computer"><i>{`${j.length} files`}</i></a>;
            break;
        case 'file':
            let name  = (value||'').split('/').pop().split('\\').pop();
            input = name ? <a onClick={chooseFile} title={value}>{name}</a> : <a onClick={chooseFile} title="choose a file from your computer"><i>choose...</i></a>;
            break;
        case 'select':
        case 'autofill':
        case 'choice':
        case 'choices':            
            input = <select value={value || ''} onChange={onTierChange}>

                {choices.map(o => <option key={o} value={o}>{o}</option>)}
            </select>
            if(value && choices.indexOf(value)==-1){
                input = <label><span className='wavywarn'>{value}</span>{input}</label>
            }
            break;
        case 'color':
            input = <ColorPickerButton color={value} onChange={onColorPickerChange} />
            break;
        case 'bool':
            //console.info("BOOL", value, value === "true");
            input = <input type="checkbox" checked={value === "true"} onChange={onBoolChange}/>
            break;
        case 'html':
        case 'js':    
            input = <AceEditor
            placeholder=""
            mode={fieldType=='html' ? "html" : 'javascript'}
            theme="tomorrow"
            name={"att_"+att}
            onLoad={onAceLoad}
            onChange={onAceChange}
            fontSize={14}
            maxLines={Infinity}
            showPrintMargin={false}
            showGutter={false}
            highlightActiveLine={false}
            value={value}
            setOptions={{
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: false,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2,
            }}/>
            
            break;
        case 'matrix':
            input = <Popup className="popup-field-matrix" trigger={<span>{value ? JSON.stringify(value) : 'define'}</span>} closeOnDocumentClick={true} closeOnEscape={true} modal> 
                <div>editing the matrix</div>
            </Popup>
            //if(!value)  else input = <span>the matrix</span>
            break;
        case 'tier':
            /*if (file.tiers && file.tiers.length) {
                let exists = file.tiers.indexOf(value)>-1;*/
            input = <EditableSelect noChoiceAvailable={"Save & Preview to get list of corpus tiers"} warnInvalidChoice={file.tiers && Object.keys(file.tiers).length} value={value} onChange={onTierChange2} choices={file.tiers}
            choiceInfo={(k,v)=>({info:v,title:`${k} has ${v} annotation${v>1?'s':''} in corpus`})}/>
            break;
        default:
            
        input = <span>  {choices.length ? <span>
            <EditableSelect onEnterKey={onEnterKey} onEscapeKey={onEscapeKey} autoFocus={autoFocus} value={value} defaultValue={placeholder} onChange={onEditableSelectChange} choices={choices}/>
          </span>:<AutosizeInput
                autoFocus={autoFocus}
                placeholder={placeholder || ''}                
                type={"text"}
                value={value || ''}
                onKeyDown={onKeyDown}
                onChange={onFieldChange}         
                onBlur={onFieldBlur}
                onFocus={onFieldFocus}
            />}
            </span>
    }
    const masked = !!definition?.masked ? "attribute-masked" : "";
    return <span className={"attribute-field "+masked} title={title}>
            <label>
                {name} <b>{label}</b>
                {!definition || !definition.novalue ? <>{hideEquals ? <></> : <span className='equals'> = </span>} {input}</> : <></>}</label>

        <HoverActions>
        {cbDelete ? <ButtonDelete title="reset to default" onClick={cbDelete}/> : <></>}
        </HoverActions>
        </span>

}
