import Block from "./Block";
import React, {useContext, useState} from "react";
import AttributesList from "../ui/AttributesList";
import {FaCogs} from "react-icons/fa";
import EditorContext from "../EditorContext";
import LevelIcon from "./LevelIcon";
import Level from "./Level";
import HoverActions from "../ui/HoverActions";
import ButtonEdit from "../ui/ButtonEdit";
import NodeActions from "../ui/NodeActions";

export default function BlockLocal({node, onAction, isEdit, parentAddy}) {

    const context = useContext(EditorContext);
    const [stateEdit, setEdit] = useState((!!isEdit) || !node.getAttributeNames().length)

    function toggleEdit() {
        setEdit(!stateEdit)
    }

    return <>
        <LevelIcon><FaCogs/></LevelIcon> <b>Settings</b>
        <ButtonEdit onClick={toggleEdit} isEdit={stateEdit}/>
        <HoverActions><NodeActions node={node} onAction={onAction} addy={parentAddy} /></HoverActions>        
        <Level isEdit={stateEdit}>
            <AttributesList isEdit={stateEdit} deletable={true} node={node} accepted={context.definitions.settings}/>
        </Level>
    </>

}

BlockLocal.addy = {
    icon: <span title={"define local settings"}><FaCogs/></span>,
    make: () => Block.parseFromString('<LOCAL  />')
}