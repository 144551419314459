import Block from './Block'
import Domain from './Domain'
import BlockSection from "./BlockSection";
import Blocks, {useBlock, useForceUpdate} from "./Blocks";
import EditorContext, { useEditor } from "../EditorContext";
import ButtonAdd from "../ui/ButtonAdd";
import Sections from "./Sections";
import {useCallback, useContext, useState} from "react";
import AutosizeInput from 'react-input-autosize';
import { SketchPicker } from 'react-color';
import Popup from 'reactjs-popup';
import { FaCog, FaLongArrowAltRight } from 'react-icons/fa';
import { useTranslate } from '../contexts/TranslateContext';
import ButtonDelete from '../ui/ButtonDelete';
import { useUndo } from '../contexts/UndoContext';
import { edit } from 'ace-builds';
import AttributesList from '../ui/AttributesList';
import DefinitionList from '../ui/DefinitionList';

export default function Modifiers({node}) {
    const translate = useTranslate();
    const undo = useUndo();
    const forceUpdate = useForceUpdate();
    const editor = useEditor();
    const block = useBlock(node);
    const [isOpen,setOpen] = useState(false)

    const addSection = useCallback(() => {
        //file.document.documentElement.appendChild(Block.parseFromString('<SECTION title="untitled section"></SECTION>'));
        forceUpdate();
    }, [node]);
    const items = [];
    const sections = [];

    for (let c of node.getElementsByTagName("MOD")) {
        block.idize(c)
        items.push({node: c, id: c.getAttribute('id')});
        //console.info('SECTION ID', section._id);        
    }

    const onIdChange = (node,event) => {
        undo.snap(node,'id');
        node.setAttribute("id", event.target.value)
        //console.info(node,event);
        forceUpdate()
    }
    const onLabelChange = (node,event) => {
        undo.snap(node,'label');
        node.setAttribute("label", event.target.value)
        //console.info(node,event);
        forceUpdate()
    }
    const onColorChange = (node,value) => {
        //console.info(node,value);
        undo.snap(node,'color');
        node.setAttribute("color", value.hex)
        forceUpdate()
    }
    const onTypeChange = (node,event) => {
        //console.info(node,value);
        undo.snap(node,'type');
        node.setAttribute("type", event.target.value)
        forceUpdate()
    }

    const addModifier = id => {
        undo.snap();
        node.appendChild(Block.parseFromString(`<MOD id="${id}"></MOD>`));
        forceUpdate();
        setOpen(false);
    }
    const onModSelect = (o) => {            
        addModifier(o.id);
    }
    const onQuickChoiceChange = (ev) => {
        onModSelect({id:ev.target.value});            
    }     
    
    return <div className="editor-modifiers crud-table">               
        {items.length ? <table className="no-top-left-corner">            
            <tbody>
        {items.map(o=>{
            let m = editor.definitions.mod[o.id];
            return <tr key={o.id} className="mod-node">
                <td><ButtonDelete onClick={ev=>{
                    undo.snap();
                    forceUpdate(o.node.parentElement.removeChild(o.node))
                    }}/>
                </td>                    
                <td>
                <Popup trigger={<a>{o.id}</a>} modal nested>            
                 <div>
                        Config: {o.id}
                        <input type="text" style={
                            /* special trick to actually prevent autofocus of first field when popup opens 
                               TODO wtf fix autofocus!!
                            */
                            {display:'none'}}/>
                        <br/>
                        <AttributesList autoFocus={false} isEdit={true} icon={<FaCog/>} hiddenAttributes={['id']}
                            node={o.node} accepted={m?.attributes} deletable={true}/>
                </div> 
                </Popup>                
                </td>
                <td>
                    {m ? m.desc : <span className='error'><i>this modifier is unknown</i></span>}
                </td>
            </tr>
        }
        )}   
        </tbody></table> : <></> }
        <ButtonAdd onClick={()=>setOpen(true)}>add modifier</ButtonAdd>
        <Popup className="addy-def-list" open={isOpen}  onClose={()=>setOpen(false)} nested modal>
                <div><select onChange={onQuickChoiceChange}>
                        <option value="">quick choice...</option>
                        {Object.values(editor.definitions.mod)
                            .filter(o => !o.hidden)
                            .sort((a,b)=>(a.id>b.id?1:-1))
                            .map(o => <option key={o.id} value={o.id}>{o.id}</option>)}
                            :<></>
                        
                    </select>
                        
                    <DefinitionList definitions={editor.definitions.mod} onSelect={onModSelect}/>            
                </div>
            </Popup>                
                
    </div>

}