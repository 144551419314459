import Block from "./Block";
import React, {useContext, useState} from "react";
import {FaArrowRight, FaFilter, FaFolderPlus, FaLongArrowAltRight, FaSearch} from "react-icons/fa";
import AttributesList from "../ui/AttributesList";
import EditorContext from "../EditorContext";
import Level from "./Level";
import LevelIcon from "./LevelIcon";
import ButtonEdit from "../ui/ButtonEdit";
import HoverActions from "../ui/HoverActions";
import LearnItem from "../ui/LearnItem";
import NodeActions from "../ui/NodeActions";
import PreviewAnnotations from "../ui/learn/PreviewAnnotations";

export default function BlockFilter({node, isEdit, onAction, parentAddy}) {
    const context = useContext(EditorContext)
    const [stateEdit, setEdit] = useState((!!isEdit) || !node.getAttributeNames().length)

    function toggleEdit() {
        setEdit(!stateEdit)
    }

    //TODO replace .an with .select
    return <>
        <LevelIcon><FaFilter/></LevelIcon>
        <b className={"color-filter block-name"}
           title="Reduces a selection of annotations based on a variety of filters">filter</b>
        <ButtonEdit onClick={toggleEdit} isEdit={stateEdit}/>
        <HoverActions><NodeActions node={node} onAction={onAction} addy={parentAddy} /></HoverActions>
        <LearnItem node={node} type={BlockFilter}/>
        <Level isEdit={stateEdit}>
            <AttributesList isEdit={stateEdit} deletable={true} node={node}
                            accepted={context.definitions.tag.filter.attributes}/>
        </Level>
    </>
}
BlockFilter.learn = {
    render: o => {
        return <PreviewAnnotations list={o.annotations}><span className={!o.count ? 'zero' : ''}>removed {o.before-o.after} annotations
            <span className={"color-filter"}>
                <b> {o.before}</b> <FaArrowRight/> <b>{o.after}</b>
            </span>
        </span></PreviewAnnotations>
    }
}
BlockFilter.addy = {
    icon: <span title={"filter selection"}><FaFilter/></span>,
    make: ()=>Block.parseFromString('<FILTER />')    
}