import React from 'react';
import AutosizeInput from "react-input-autosize";
import ButtonCollapser from "./ButtonCollapser";
import ButtonRemove from "./ButtonRemove";
import AttributeField from "./AttributeField";
import Block from "../blocks/Block";
import {FaCog} from "react-icons/fa";
import Sortable from "./Sortable";
import {closestCenter, DndContext} from "@dnd-kit/core";
import {SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {useBlock, useForceUpdate} from "../blocks/Blocks";
import { useUndo } from '../contexts/UndoContext';

export default function AttributesList({
                                           isSortable,
                                           deletable,
                                           isEdit,
                                           node,
                                           icon,
                                           prefix,
                                           accepted,
                                           hiddenAttributes,
                                           revealedAttributes,
                                           autoFocus,
                                           onChange,
                                           ...props
                                       }) {

    const block = useBlock(props.node)
    const undo = useUndo();
    const forceUpdate = useForceUpdate();

    function deleteAttr(id) {
        undo.snap(node,'delete_'+id);
        node.removeAttribute(id)
        forceUpdate()
    }

    function onAttributeChange() {
        //console.info("ATTRIBUTE LIST UPDATE!");
        forceUpdate()
    }

    accepted = accepted || [];

    const defMap = {};
    accepted.forEach(attr => {
        defMap[(attr.name || attr.id)] = attr;
    })
    let list = [];
    const acceptedNames = accepted.map(o => o.name)
    const attributes = node.getAttributeNames();
    prefix = prefix || '';
    for (let name of acceptedNames) {
        if (attributes.indexOf(prefix + name) > -1) {
            list.push({name: prefix + name, defined: true, accepted: true});
            attributes.splice(attributes.indexOf(prefix + name), 1)
        } else {
            list.push({name: prefix + name, defined: false, accepted: true});
        }
    }
    for (let name of attributes) {
        list.push({name: name, defined: true, accepted: false});
    }
    list.forEach(o => {
        o.cleanName = o.name;
        if (prefix && o.name.substring(0, prefix.length) === prefix) {
            o.cleanName = o.name.substring(prefix.length);
        }
    })
    //console.info('defmap',list)
    //.filter(o => isEdit || o.defined)
    list = list

        .filter(o => !hiddenAttributes || !hiddenAttributes.length || hiddenAttributes.indexOf(o.name) === -1)
        //.filter(o => !revealedAttributes || !revealedAttributes.length || (isEdit && revealedAttributes.indexOf(o.name) === -1))
        .map(o => <div tabIndex={!(isEdit || o.defined) ? "-1" : ""} className={"attribute-field-container " + (isEdit || o.defined ? "" : "hide")} key={o.name}
                       style={{opacity: o.defined ? 1 : 0.5}}>
            {icon || <FaCog/>}
            <AttributeField
                autoFocus={autoFocus}
                cbDelete={o.defined && deletable ? () => deleteAttr(o.name) : null}
                prefix={prefix} node={node}
                type={defMap[o.cleanName] ? defMap[o.cleanName].type : ''}
                att={o.name}
                definition={defMap[o.cleanName]}
                onChange={onAttributeChange}  
                onBlurChange={onChange}              
                warn={accepted.length && !defMap[o.cleanName]}/></div>
        )
    return <DndContext collisionDetection={closestCenter}>
        <div className={"attributes-list"}>
            <SortableContext strategy={verticalListSortingStrategy} items={list}>
                {list}
            </SortableContext>
        </div>
    </DndContext>


}
