import React from "react";
import EditorContextObject from "./EditorContextObject"
const EditorContext = React.createContext(new EditorContextObject());

function useEditor() {
    const context = React.useContext(EditorContext)
    if (context === undefined) throw new Error('useEditor  must be used within an EditorProvider')
    return context
}

export {useEditor, EditorContext}
export default EditorContext;



