import React from 'react';
import EditorContext from "../EditorContext";

export default class Block extends React.Component {

    static parseFromString(str) {
        let doc = new DOMParser().parseFromString('<root>' + str + '</root>', "text/xml");
        return doc.documentElement.children[0]
    }

    node;

    constructor(props) {
        super(props);
        this.node = props.node;
        this._idize(this.node);
        this.setAtt = this.setAtt.bind(this);
        this.getAtt = this.getAtt.bind(this);
        this.isAttTrue = this.isAttTrue.bind(this);
    }

    remove = () => {
        this.props.cbRemove(this.node);
    }

    _idize(node) {
        if (node && !node._id) node._id = Math.random();
        if (node && node.children) for (let c of node.children) if (c && !c._id) c._id = Math.random();
    }

    setAtt(att, val) {
        this.node.setAttribute(att, val);
    }

    getAtt(att, def) {
        return this.node.hasAttribute(att) ? this.node.getAttribute(att) : def;
    }

    isAttTrue(att) {
        return this.getAtt(att) == "true";

    }
}
