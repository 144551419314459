import Block from "../blocks/Block";
import React, {useContext, useEffect, useState} from "react";
import EditorContext, { useEditor } from "../EditorContext";
import ButtonDelete from "./ButtonDelete";
import ButtonAdd from "./ButtonAdd";
import { FaArchive, FaBook, FaBookReader, FaBrain, FaDiscord, FaLightbulb, FaPlusCircle, FaPuzzlePiece, FaRegLightbulb, FaSpinner, FaWalking, FaWrench } from "react-icons/fa";
import Utils from "../converter/Utils";
import Popup from "reactjs-popup";
import FFMPEGInstaller from "./updater/FFMPEGInstaller";
import AVAAConfig from "./AVAAConfig";
import { useForceUpdate } from "../blocks/Blocks";
import { useUser } from "../contexts/UserContext";

export default function WelcomeServerTab({}) {
    const editor = useEditor();
    const user = useUser();
    const [form,setForm] = useState({})
    const [calling,setCalling] = useState(false);
    const forceUpdate = useForceUpdate();
    const login = ()=>{
        setCalling(true);
        editor.converter.call("login",form,(r,err)=>{            
            if(err){
                if(err.includes('(')) err=err.split('(')[0];// removes js file and line no
                alert(err);
            }
            if(r){
                for(let k in r) user[k] = r[k];
            }
            setCalling(false);
        });
    }
    return <div className={"welcome-server-screen"}>
        
        {!user.id ? <div className="login-screen">
            <div className={'spinner-blur'}><img className={"spinner3d logo-avaa"} src="./logo512.png"/></div>
            <h2>AVAA SERVER</h2>
            <p>{editor.converter.hello.loginGreet || ''}</p>
            <label><input value={form.id||''} onChange={e=>setForm({...form,id:e.target.value})} type="text" placeholder="user name" /></label>
            <label><input value={form.password||''} onChange={e=>setForm({...form,password:e.target.value})} type="password" placeholder="password" /></label>
            <button onClick={login} disabled={calling}>login</button>
        </div> : <div className="corpora-screen">
            <div class="user-infos">{user.name}</div>
            <div><img className={"spinner3d logo-avaa"} src="./logo512.png"/></div>            
            <div className="corpora-greet">{user.greet}</div>
            <div className="corpora-list">
                {(user.corpora||[]).map(c=><div key={c.id} className="corpora-item">
                    <h3>{c.name || c.id}</h3>
                </div>)}
            </div>
        </div>}
    </div>   
}