import React, { useState } from "react";
import {
    FaArrowRight,
    FaRegArrowAltCircleDown,
    FaRegArrowAltCircleUp
} from "react-icons/fa";
import ButtonClone from "../ui/ButtonClone";
import ButtonDelete from "../ui/ButtonDelete";
import HoverActions from "../ui/HoverActions";
import LearnItem from "../ui/LearnItem";
import Block from "./Block";
import LevelIcon from "./LevelIcon";
import NodeActions from "../ui/NodeActions";
import ButtonEdit from "../ui/ButtonEdit";
import { useEditor } from "../EditorContext";
import Level from "./Level";
import AttributesList from "../ui/AttributesList";
import PreviewAnnotations from "../ui/learn/PreviewAnnotations";

export default function BlockUnstack({node, isEdit, onAction, parentAddy}) {
    const editor = useEditor()
    const [stateEdit, setEdit] = useState((!!isEdit))

    return (
        <>
            <LevelIcon><FaRegArrowAltCircleDown /></LevelIcon>
            <b className={"color-unstack block-name"}
               title={"Creates a new selection from all stacked annotations"}>unstack</b>
            <ButtonEdit onClick={()=>setEdit(!stateEdit)} isEdit={stateEdit}/>
            <HoverActions><NodeActions node={node} onAction={onAction} addy={parentAddy}/></HoverActions>        
            <LearnItem node={node} type={BlockUnstack}/>
            <Level isEdit={stateEdit}>
            <AttributesList isEdit={stateEdit} deletable={true} node={node}
                            accepted={editor.definitions.tag.unstack.attributes}/>
            </Level>
        </>
    )

}
BlockUnstack.learn = {
    render: o => {
        return <PreviewAnnotations list={o.annotations}><span>unstacked {o.size} annotations
            <span className={"color-unstack"}>
                <b style={{color:'red'}}> {o.before||''}</b> <FaArrowRight/> <b className="color-select">{o.size}</b>
            </span>
        </span></PreviewAnnotations>
    }
}
BlockUnstack.addy = {
    icon: <span title={"select all stacked annotations"}><FaRegArrowAltCircleDown/></span>,
    make:()=>Block.parseFromString('<UNSTACK />')
}