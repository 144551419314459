import React, {useContext, useEffect, useState} from "react";
import {useForceUpdate} from "../blocks/Blocks";
import ConverterSocket from "../converter/ConverterSocket";

import {FaSpinner} from "react-icons/fa";
import EditorContext from "../EditorContext";
import Tab from "../ui/Tab";
import WelcomeTab from "./WelcomeTab";
import WelcomeServerTab from "./WelcomeServerTab";

function AppLayout() {
    //const conv = useConverter({onStatusChange: update, onFilesList: update})
    const update = useForceUpdate()
    const [converter, setConverter] = useState(ConverterSocket.getInstance())
    const editor = useContext(EditorContext)

    useEffect(() => {
        //TODO: events
        converter.onStatusChange = update;
        converter.onMessage = update;
        converter.onHello = update;
    }, [converter]);
    
    return (
        <div>
            <div className={"tabs"}>            
            </div>
            <div className={"tab-content"}>
                {
                    editor.selected ?
                        <Tab key={editor.selected.path} file={editor.selected}/> :
                        (editor.converter.hello ? (
                            editor.converter.hello.loading ? <span>loading server data</span>
                            : (editor.converter.hello.server ? <WelcomeServerTab/> : <WelcomeTab/>)
                        ) : <WelcomeTab/>)
                }
            </div>
            {!converter.ready ? <div className={"connecting "+(editor.selected ? "connecting-full" : "")}>Waiting for AVAA to be launched <FaSpinner className={"spinner"}/></div> : <></>}
        </div>
    )
}

export default AppLayout;
