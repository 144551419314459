import FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import { FaInfo } from 'react-icons/fa';
import { useFile } from '../../contexts/FileContext';

const style = `.comment {
    white-space: pre-line;
    color: #4c4c4c;
    font-size: 1.3em;
    margin: 0px;
    margin-bottom: 5px;
    margin-top: 30px;
}
a {
    font-size: 1.3em;
    color:rgb(9, 105, 218);
    text-decoration:none;
}
.block {
    overflow-x: hidden;
    margin-bottom: 5px;
}
ul {
    padding: 0px;
    padding-left: 20px;
}
li {
    list-style-type: circle;
    margin-bottom: 10px;
    padding-left:2px;
}
li .comment {
    margin: 0px;    
}
.container {
    padding-left: 20px;
    margin-left: 10px;
    border-left: 3px dashed gainsboro;
}
img.chart-preview {
    margin-top: 20px;
}
img {
    margin-left: 3px;
}
section > .container {
    border: none;
}
section {
    margin-bottom: 50px;
    border-bottom: 5px solid gainsboro;
    padding-bottom: 50px;
}
section:last-child {
    border: none;
}
body {
    font-family: 'calibri';
}
section.description {
    white-space: pre-line;
    font-size: 1.6em;
}
h1 {
    font-size: 2.4em;
}
h2 {
    font-size: 2em;
}
.container > .comment:first-child {
    margin-top: 15px;
}
`
const containers = ['chart','view','if','iftags','proc','loop'];
const isContainer = element => {
    let is = false;
    containers.forEach(c=>is = is || element.classList.contains(`editor-block-${c}`));
    return is;
}
const hide = (element, selectors)=>{
    selectors.forEach(s=>{
        for(let e of element.querySelectorAll(`:scope > ${s}`)) e.style.display='none';
    })
    return ()=>{
        selectors.forEach(s=>{
            for(let e of element.querySelectorAll(`:scope > ${s}`)) e.style.display='';
        })
    }
}
const fixes = { //omg
    'set':{
        top:'-8px',
        width:'27px',
        height:'15px'
    },
    'load':{
        width:'30px',
        height:'20px',
        top:'3px'
    },
    'save':{
        width:'30px',
        top:'4px'
    },
    'view':{
        width:'33px',
        top:'6px',
    },
    'chart':{
        width:'22px',
        top:'5px',
    },
    'html':{
        width:'34px',        
    },    
    'iftags':{
        width:'25px',    
    },
    'proc':{
        width:'30px',    
    },
    'select':{
        width:'34px',
    },
    'local':{
        width:'33px',    
    }
}
const fixIcon = (b,opt)=>{
    //editor-block-set
    let unfix = [];
    let type='';
    for(let t of b.classList.values()){
        if(t.indexOf('editor-block-')>-1){
            type = t.split('-').pop();
        }
    }    
    for(let icon of b.querySelectorAll(`:scope > .level-icon > svg`)){        
        unfix.push(fixLevelIcon(b,fixes[type] || opt));
    }    
    return ()=>{
        unfix.forEach(f=>f());
    }
}
const fixLevelIcon = (b,o)=>{
    o = o || {};
    o.width = o.width || '';
    o.height = o.height || '';
    o.top = o.top || '7px';
    o.left = o.left || '-3px';    
    const levelIcon = b.querySelector(`:scope > .level-icon`);
    if(!levelIcon) return ()=>{};
    const levelIconSVG = b.querySelector(`:scope > .level-icon > svg`);    
    levelIcon.style.position='relative';
    levelIcon.style.top=o.top;
    levelIcon.style.left=o.left;
    levelIconSVG.style.position='initial';         
    levelIconSVG.style.width=o.width;
    levelIconSVG.style.height=o.height;
    return ()=>{
        levelIcon.style.position='';
        
        levelIcon.style.top='';
        levelIcon.style.left='';
        levelIconSVG.style.position='';
        levelIconSVG.style.width='';
        levelIconSVG.style.height='';
    }
}
const fixFields = (b)=>{
    let inputs = b.querySelectorAll(`:scope .attribute-field input[type="text"]`);
    let selects = b.querySelectorAll(`:scope .attribute-field select`);
    let attSvgs = b.querySelectorAll(`:scope .attribute-field-container > svg`);      
    let borderInputs = b.querySelectorAll(`:scope a.type`);      
    let masks = b.querySelectorAll(`:scope .attribute-masked input`);      
    
    /*for(let att of b.querySelectorAll(`:scope .delete`)){
        att.style.display='none';
    }*/
    let cursors = b.querySelectorAll(`:scope .ace_cursor-layer`);            
    for(let cur of cursors) cur.style.display='none';
            
    for(let att of attSvgs) att.style.opacity='0';
    for(let inp of inputs) inp.style.height='25px';            
    for(let inp of selects) inp.style.position='relative';            
    //for(let inp of selects) inp.style.top='-5px';            
    for(let inp of selects) inp.style.height='27px';   
    
    for(let inp of masks) inp.type='password';   
    for(let inp of borderInputs) inp.style.borderBottom='none';   
    return ()=>{
        for(let att of attSvgs) att.style.opacity='1';
        for(let inp of inputs) inp.style.height='';
        for(let inp of selects) inp.style.position='relative';            
        for(let inp of selects) inp.style.top=''; 
        for(let inp of selects) inp.style.height=''; 
        for(let cur of cursors) cur.style.display='';   
        for(let inp of masks) inp.type='text';   
        for(let inp of borderInputs) inp.style.borderBottom='';   
    } 
}
async function renderSection(element){
    let html = '<section>';
    let title = element.querySelector(':scope > div > input')?.value||'';
    if(title) html += `<h2>${escapeHtml(title)}</h2>`;
    html += await renderContainer(element);
    return html+'</section>'; 
}

async function renderContainer(element){
    let html = '', htmlAtt='';
    let blocks = element.querySelectorAll(':scope > .level > .editor-block');
    let attributes = element.querySelectorAll(':scope > .level > .attributes-list > .attribute-field-container:not(.hide) > .attribute-field');
    console.info('ATTRIBUTES',attributes);
    let currentComment = '';
    let currentLink = '';
    let attComment = {};
    for(let b of blocks){        
        if(b.classList.contains("editor-block-test")){
            currentComment="";
            continue;
        }
        if(b.classList.contains('editor-block-comment')){            
            let comment = b.querySelector(':scope input').value;
            if(comment.charAt(0)=='/'){
                if(currentComment){
                    html += `<div class="comment">${escapeHtml(currentComment)}</div>\n`;            
                    currentComment='';
                }
                currentLink = comment.substring(1);
            }
            else if(comment.charAt(0)=='@'){
                let a = comment.split(' ');
                let k = a[0].substring(1);
                a.shift();
                attComment[k]=a.join(' ');
            }else{               
                if(currentComment) currentComment += "\n";
                currentComment += comment;
            }
            continue;
        }
        if(currentLink){
            html += `<div class="comment"><a href="${encodeURI(currentLink)}">${escapeHtml(currentComment)}</a></div>`;
            currentLink='';
            currentComment='';
        }
        if(currentComment){
            html += `<div class="comment">${escapeHtml(currentComment)}</div>\n`;            
            currentComment='';
        }
        html += `<div class="block">`;
        if(isContainer(b)){
            let unhide = hide(b,['.edit','.toggle-cache','.level','.learn-item-root','* .ace_layer.ace_marker-layer','* .ace_layer.ace_cursor-layer']);   
            let unfixLevelIcon = fixIcon(b);  
            b.style.paddingBottom = '5px';       
            for(let inp of b.querySelectorAll(`:scope a.type`)) inp.style.borderBottom = '2px solid transparent';
            html += await drawToImg(b);            
            for(let inp of b.querySelectorAll(`:scope a.type`)) inp.style.borderBottom = '';
            unhide();        
            unfixLevelIcon();   
            b.style.paddingBottom = '';
            html += await renderContainer(b);
        }else{
            let restore = hide(b,['.edit','.toggle-cache','.learn-item-root','* .ace_layer.ace_marker-layer','* .ace_layer.ace_cursor-layer']);
            let unfixLevelIcon = fixIcon(b)    
            let level = b.querySelector(`:scope > .level`);    
            b.style.minHeight="26px";
            if(level) level.style.borderLeft='none';   
            //if(levelIcon) levelIcon.style.width='30px';         
            let unfixFields = fixFields(b);
            html += await drawToImg(b);
            if(level) level.style.borderLeft='';
            b.style.minHeight="";            
            //if(levelIcon) levelIcon.style.height='';
            unfixFields()
            unfixLevelIcon();
            restore();
        }
        html += `</div>\n`;        
    }
    if(currentComment){
        html += `<div class="comment">${escapeHtml(currentComment)}</div>\n`;            
        currentComment='';
    }
    if(attributes.length){
        htmlAtt+='<ul>';
        for(let att of attributes){
            let e = att.querySelector(':scope b');
            let k = e.textContent;            
            htmlAtt+=`<li>`;
            if(!attComment[k]) attComment[k] = e.title||'';
            if(attComment[k]) htmlAtt+=`<span class="comment">${attComment[k]}</span><br>`
            let unhide = hide(att,['.hover-actions','* .ace_layer.ace_marker-layer','* .ace_layer.ace_cursor-layer']);    
            let unfixFields = fixFields(att.parentElement);
            htmlAtt += await drawToImg(att,'att');
            unfixFields();
            
            unhide();
            htmlAtt+=`</li>`;
        }
        htmlAtt+='</ul>';
    }    
    let htmlChart = '';
    if(element.classList.contains('editor-block-chart')){
        let chartContainer = element.querySelector(':scope .chart-container');
        if(chartContainer){
            chartContainer.style.transform = 'scale(2.5)'
            htmlChart = await drawToImg(chartContainer,"chart-preview");
            chartContainer.style.transform = ''
        }
    }
    
    return html = `<div class="container">
        ${htmlChart}
        ${htmlAtt}
        ${html}
    </div>\n`;
}
async function drawToImg(element,classes){
    const b64 = await drawElement(element);    
    return `<img src="${b64}" class="${classes||''}" />`; 
}

async function drawElement(element){
    let canvas = await html2canvas(element);    
    canvas = cropImageFromCanvas(canvas);
    return canvas.toDataURL(); 
}

export default function Tutoizer(props){
    const file = useFile();
    const tutoize = async () => {
        //const element = printRef.current;
        let title = document.querySelector('.document-title input').value;
        let desc = document.querySelector('.document-description').value;

        let html = `<html><head><style>${style}</style>`;
        html += `<meta http-equiv="Content-Type" content="text/html; charset=utf-8">`;
        html += `<script src="./examplize.js"></script>`;
        html += `<link rel="stylesheet" type="text/css" href="./style.css">`;
        html += `<script src="./examplize.js"></script>`;
        html += `<title>${escapeHtml(title)}</title>`;
        html += `</head><body>`;        
        html+=`<h1>${escapeHtml(title)}</h1>`
        if(desc) html+=`<section class="description">${escapeHtml(desc)}</section>`;
        for(let section of document.getElementsByClassName('block-section')){
            html += await renderSection(section);
            //break;
        }
        html += "</body></html>";
        //console.info('FINAL HTML',html);

        FileSaver.saveAs(new Blob([html]), file.name.replace('.xml','.html'), {type: "text/plain;charset=utf-8"}); 
        return;
        
        //const data = canvas.toDataURL('image/jpg');
        //const link = document.createElement('a');
    }
    return <button onClick={tutoize} title="Build a HTML tutorial from current document">
        <FaInfo/> Tutoize
    </button>
}

//https://stackoverflow.com/questions/12175991/crop-image-white-space-automatically-using-jquery/12178531#12178531
function cropImageFromCanvas(source) {
    let imgWidth = source.width;
    let imgHeight = source.height;    
    var canvas = document.createElement('canvas');
    canvas.setAttribute("width", imgWidth);
    canvas.setAttribute("height", imgHeight);
    var context = source.getContext('2d');
    var imageData = context.getImageData(0, 0, imgWidth, imgHeight),
        data = imageData.data,
        getRBG = function(x, y) {
            var offset = imgWidth * y + x;
            return {
                red:     data[offset * 4],
                green:   data[offset * 4 + 1],
                blue:    data[offset * 4 + 2],
                opacity: data[offset * 4 + 3]
            };
        },
        isWhite = function (rgb) {
            // many images contain noise, as the white is not a pure #fff white
            return rgb.red > 200 && rgb.green > 200 && rgb.blue > 200;
        },
                scanY = function (fromTop) {
        var offset = fromTop ? 1 : -1;

        // loop through each row
        for(var y = fromTop ? 0 : imgHeight - 1; fromTop ? (y < imgHeight) : (y > -1); y += offset) {

            // loop through each column
            for(var x = 0; x < imgWidth; x++) {
                var rgb = getRBG(x, y);
                if (!isWhite(rgb)) {
                    if (fromTop) {
                        return y;
                    } else {
                        return Math.min(y + 1, imgHeight);
                    }
                }
            }
        }
        return null; // all image is white
    },
    scanX = function (fromLeft) {
        var offset = fromLeft? 1 : -1;

        // loop through each column
        for(var x = fromLeft ? 0 : imgWidth - 1; fromLeft ? (x < imgWidth) : (x > -1); x += offset) {

            // loop through each row
            for(var y = 0; y < imgHeight; y++) {
                var rgb = getRBG(x, y);
                if (!isWhite(rgb)) {
                    if (fromLeft) {
                        return x;
                    } else {
                        return Math.min(x + 1, imgWidth);
                    }
                }      
            }
        }
        return null; // all image is white
    };

    var cropTop = scanY(true),
        cropBottom = scanY(false),
        cropLeft = scanX(true),
        cropRight = scanX(false),
        cropWidth = cropRight - cropLeft,
        cropHeight = cropBottom - cropTop;

    canvas.setAttribute("width", cropWidth);
    canvas.setAttribute("height", cropHeight);
    // finally crop the guy
    canvas.getContext("2d").drawImage(source,
        cropLeft, cropTop, cropWidth, cropHeight,
        0, 0, cropWidth, cropHeight);

    return canvas;
    //return canvas.toDataURL();
}
function escapeHtml(unsafe)
{
    return unsafe
         .replace(/&/g, "&amp;")
         .replace(/</g, "&lt;")
         .replace(/>/g, "&gt;")
         .replace(/"/g, "&quot;")
         .replace(/'/g, "&#039;");
 }