import Block from "./Block";
import React, {useContext, useState} from "react";
import {FaArrowRight, FaFolderPlus, FaLongArrowAltRight, FaSearch} from "react-icons/fa";
import AttributesList from "../ui/AttributesList";
import EditorContext from "../EditorContext";
import Level from "./Level";
import LevelIcon from "./LevelIcon";
import ButtonEdit from "../ui/ButtonEdit";
import HoverActions from "../ui/HoverActions";
import ButtonClone from "../ui/ButtonClone";
import ButtonDelete from "../ui/ButtonDelete";
import BlockExtra from "./BlockExtra";
import LearnItem from "../ui/LearnItem";
import BlockStack from "./BlockStack";
import NodeActions from "../ui/NodeActions";
import PreviewAnnotations from "../ui/learn/PreviewAnnotations";

export default function BlockSelect({node, isEdit, onAction,parentAddy}) {
    const context = useContext(EditorContext)
    const [stateEdit, setEdit] = useState((!!isEdit))// || !node.getAttributeNames().length)

    function toggleEdit() {
        setEdit(!stateEdit)
    }

    //TODO replace .an with .select
    return <>
        <LevelIcon><FaSearch/></LevelIcon>
        <b className={"color-select block-name"}
           title="Makes a selection of annotations based on a variety of filters">select</b>
        <ButtonEdit onClick={toggleEdit} isEdit={stateEdit}/>
        <HoverActions><NodeActions node={node} onAction={onAction} addy={parentAddy}/></HoverActions>        
        <LearnItem node={node} type={BlockSelect}/>
        <Level isEdit={stateEdit}>
            <AttributesList isEdit={stateEdit} deletable={true} node={node}
                            accepted={context.definitions.tag.select.attributes}/>
        </Level>
    </>
}
BlockSelect.learn = {
    render: o => {
        return <PreviewAnnotations list={o.annotations}><span className={!o.count ? 'zero' : ''}>found {o.count} annotations
            <span className={"color-select"}>
                <b> {o.before}</b> <FaArrowRight/> <b>{o.after}</b>
            </span>            
        </span></PreviewAnnotations>
    }
}
BlockSelect.addy = {
    icon: <span title={"select annotations"}><FaSearch/></span>,
    make: () => Block.parseFromString('<SELECT />')
}