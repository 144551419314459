import EventDispatcher from "../contexts/EventDispatcher";
import ConverterSocket from "./ConverterSocket";

export default class RemoteCorpus extends EventDispatcher {

    static map = {};
    static get(url,subset){
        subset = subset || '';
        var key = url+'@'+subset;
        if(!RemoteCorpus.map[key]) RemoteCorpus.map[key] = new RemoteCorpus(url,subset);
        return RemoteCorpus.map[key];
    }
    progress = {percent:0}

    check(){
        if(this.checking) return;
        this.checking = true;
        ConverterSocket.getInstance().call("corpus-manager", {url: this.url, subset:this.subset}, r=>{
            //this.checking=false;
            this.onCorpusManager(r);
        });        
    }
    onCorpusManager(r){
        let rc = r.corpus;    
        for(var k in rc) this[k]=rc[k];        
        this.dispatch("status");
    }
   
    download = () => {
        if(this.downloading) return;
        this.downloading = true;
        this.progress.percent = 0;
        ConverterSocket.getInstance().call("corpus-manager", {url: this.url, subset:this.subset,action:'download'}, r=>{
            this.onCorpusManager(r);
        });
        this.dispatch("status");
    }
    onDownloadProgress(o){
        
        Object.keys(o).forEach(k=>{this.progress[k]=o[k]});
        this.dispatch("download-progress", this.progress);
    }
    
    constructor(url,subset) {
        super()    
        this.subset = subset;
        this.url = url;
        
    }
}