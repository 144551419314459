import React from "react";

const FileContext = React.createContext({tiers: []});

function useFile() {
    const context = React.useContext(FileContext)
    if (context === undefined) throw new Error('useFile  must be used within a FileProvider')
    return context
}

export {useFile, FileContext}
export default FileContext
