import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove, rectSwappingStrategy,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Blocks, { useBlock } from '../../blocks/Blocks';
import Sortable from '../Sortable';
import AttributesList from '../AttributesList';

export default function SortableNode({node,items,onChange,createTrigger,deleteTrigger}){
    const block = useBlock(node);
    function dragStart(e) {
        //console.info('DRAG START', e, block)
    }

    function dragEnd(event) {
        const {active, over} = event;
        //console.info("dragEnd", event,active, over);        
        let children = Array.from(node.children);
        const oldIndex = children.indexOf(active.data.current.node);
        const newIndex = children.indexOf(over.data.current.node);
        if(newIndex===oldIndex) return;        
        if (newIndex > oldIndex) {
            over.data.current.node.after(active.data.current.node);
        } else {
            over.data.current.node.before(active.data.current.node);
        }        
        onChange && onChange()
        //forceUpdate();
        //if (over && over.data.current.accepts.includes(active.data.current.type)) {
        // do stuff
        //}
    }

    const cloneNode = e => {        
        let cloned = e.cloneNode(true);
        //block.reidize(cloned);
        e.after(cloned);
        //forceUpdate();
    }

    const deleteNode = e => {                
        e.parentNode.removeChild(e);        
        //forceUpdate();
    }
       
    items.forEach(i=>{
        block.idize(i.node);
        i.id = i.node._id;
    });
            
    return <div className='sortable-node'><DndContext collisionDetection={closestCenter} onDragStart={dragStart} onDragEnd={dragEnd}>
        <SortableContext strategy={verticalListSortingStrategy} items={items}>{items.map(i=>
            <Sortable node={i.node} key={i.id} id={i.id} className={"sortable-node-item sortable-node-tag-" + i.node.tagName.toLowerCase()}>
                {deleteTrigger}{i.as}
            </Sortable>)}
            {createTrigger}
        </SortableContext>
        
    </DndContext></div>
}