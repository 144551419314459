import { FaPaintBrush } from "react-icons/fa";
import Popup from "reactjs-popup";
import { useFile } from "../../contexts/FileContext";
import { useTranslate } from "../../contexts/TranslateContext";
import StylePopup from "./StylePopup";

export default function StylePopupButton(){
    const file = useFile();
    const translate = useTranslate();
    const changeLang = ev => {
        
        const [lang, setLang] = translate.lang;
        const [langs] = translate.langs;        
        let i = langs.indexOf(lang)+1        
        if(i==langs.length) i=0;
        //console.info('-->',langs,i,'SET:')
        setLang(langs[i]);
        file.lang = langs[i].id// ugly //FIXME
    }
    
    return <Popup nested trigger={<button><i><FaPaintBrush/> style </i></button>} className="popup-style" closeOnDocumentClick={true} closeOnEscape={true} modal>
        <StylePopup />
    </Popup>        
 }
 