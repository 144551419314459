import { useState } from "react";
import { FaTags } from "react-icons/fa";
import Popup from "reactjs-popup";

export default function TagListField({tags,editable,onChange,titleNoTag}){    
    titleNoTag = titleNoTag || "No tag defined";
    let otags = tags;
    tags = tags || [];
    if(!Array.isArray(tags) && tags.split) tags = tags.split(',')
    if(!Array.isArray(tags)){
        console.warn("TagListField provided with invalid tags",otags);
    }
    const [tagList,setTagList] = useState(tags);
    let base = <span className={"tag-list-field "+(tags.length ? "has-tags" : "has-no-tags")} title={tagList.length ? "Tags: \n"+tagList.join('\n') : titleNoTag}>
        <FaTags /> {tagList.length}
    </span>
    const remove = (t)=>{        
        tagList.splice(tagList.indexOf(t),1)        
        onChange && onChange(tagList);        
        setTagList(tagList.slice());
    }    
    const add = ()=>{
        let tag = prompt("Enter tag name");
        if(!tag) return;
        let newTags = tag.split(',')
        newTags.forEach(tag=>{
            tag = tag.trim();
            if(tagList.indexOf(tag)>-1){
                alert(`Tag "${tag}" is already specified`);
                return;
            }
            tagList.push(tag);
        })
        onChange && onChange(tagList);
        setTagList(tagList.slice());
    }
    return editable ? <Popup className="tag-list-field" trigger={<span className="tag-list-field-editable">{base}</span>}>
            {tagList.map(t=><div title="click to remove this tag" className="tag-list-item" key={t} onClick={ev=>remove(t)}>
                {t}
            </div>)}
            <div className="tag-list-add" onClick={add}><a>add tag</a></div>
        </Popup> : base;
}