import Block from "./Block";
import React, {useCallback, useState} from "react";
import BlockContainer from "./BlockContainer";
import Level from "./Level";
import AutosizeInput from "react-input-autosize";
import {AddyBox, useAddy, useForceUpdate} from "./Blocks";
import BlockSet from "./BlockSet";
import BlockSelect from "./BlockSelect";
import BlockOp from "./BlockOp";
import BlockExtra from "./BlockExtra";
import BlockLocal from "./BlockLocal";
import BlockView from "./BlockView";
import ButtonAdd from "../ui/ButtonAdd";
import HoverActions from "../ui/HoverActions";
import ButtonClone from "../ui/ButtonClone";
import ButtonDelete from "../ui/ButtonDelete";
import LevelIcon from "./LevelIcon";
import {FaEye, FaParagraph} from "react-icons/fa";
import BlockComment from "./BlockComment";
import BlockChart from "./BlockChart";
import BlockHTML from "./BlockHTML";
import BlockMarkdown from "./BlockMarkdown";
import BlockDoc from "./BlockDoc";
import { useTranslatableAttribute } from "../contexts/TranslateContext";
import NodeActions from "../ui/NodeActions";
import BlockIfTags from "./BlockIfTags";
import BlockProc from "./BlockProc";
import BlockLoop from "./BlockLoop";

export default function BlockSection({node, blocks, onNodeAction, parentAddy}) {

    const forceUpdate = useForceUpdate();
    const translatableAttribute = useTranslatableAttribute(node, 'title');
    
    const addy = useAddy({
        node, update: forceUpdate,
        types: [BlockView, BlockChart, BlockHTML, BlockProc, BlockIfTags, BlockLoop, BlockLocal, BlockDoc, BlockComment]
    })

    return <div className={"editor-section block-section"}>
        <LevelIcon></LevelIcon>        
        <AutosizeInput            
            type={"text"}            
            placeholder="untitled section"
            value={translatableAttribute.get()}
            onChange={e=>translatableAttribute.set(e.target.value)}
            onFocus={translatableAttribute.onFocus}
            onBlur={translatableAttribute.onBlur}
        />        
        <HoverActions><NodeActions node={node} onAction={action=>onNodeAction(node,action)} addy={parentAddy} /></HoverActions>        
        {translatableAttribute.hints()}
        <Level>
            <BlockContainer blocks={blocks} node={node} onNodeAction={onNodeAction} parentAddy={addy.box} />
            <AddyBox addy={addy} alreadyOpened={!node.childNodes.length}/>
        </Level>
    </div>

}