import EditorFile from "./EditorFile";
import RemoteCorpus from "./RemoteCorpus";

export default class ConverterSocketChannel {
    url = "ws://127.0.0.1:42042/atk"
    ws;
    ready = false;
    calls = 0;
    callbacks = {};
    promises = {};
    files = [];
    folders = [];
    opened = [];
    onStatusChange;
    onMessage;
    lastMessage = '?';
    queue = [];
    version = {};

    reconnect() {
        //console.info('connect()');
        this.ws = new WebSocket(this.url);
        this.ws.onmessage = this.onSocketMessage.bind(this);
        this.ws.onerror = this.onSocketError.bind(this);
        this.ws.onopen = this.onSocketOpen.bind(this);
        //this.ws.connect(this.url);
    }


    call(cmd, params, cb) {
        let o = {};
        o._ = ++this.calls;
        o.a = cmd;
        o.p = params;
        this.callbacks[o._] = cb || null;
        if (!this.ready || this.ws.readyState === 0) {
            this.queue.push(o)
            return;
        }
        if (this.ws.readyState > 1) {
            this.ready = false;
            this.queue.push(o)
            this.reconnect();
            return;
        }
        this.ws.send(JSON.stringify(o));

        /*let p = this.promises[o._] = new Promise(function (resolve, reject) {
            var server = new WebSocket('ws://mysite:1234');
            server.onopen = function () {
                resolve(server);
            };
            server.onerror = function (err) {
                reject(err);
            };
        });
        return p;*/
    }

    cbUpdate;

    close() {
        //console.info("CLOSING CONVERTERSOCKET!!!");
    }


    onSocketOpen(ev) {
        //console.log('OPEN', ev);
        this.ready = true;
        this.onStatusChange && this.onStatusChange();
        this.queue.forEach(o => this.ws.send(JSON.stringify(o)));
        this.queue.length = 0;        
    }

    onSocketError(ev) {
        //console.log('ERROR', ev);
        this.ready = false;
        this.onStatusChange && this.onStatusChange();
        setTimeout(this.reconnect.bind(this), 5000);
    }

    onSocketMessage(ev) {
        //console.info('MESSAGE', ev.data);
        let o = JSON.parse(ev.data);
        this.lastMessage = ev.data;
        this.onMessage && this.onMessage();
        if (this.callbacks[o._]) {
            this.callbacks[o._](o.r,o.e);
            delete this.callbacks[o._];
        }       
    }

    constructor() {
        //console.log('CONVERTER SOCKET CREATED');
        this.reconnect();
    }
}
