import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLearn } from "../blocks/Blocks";
import { useFile } from "../contexts/FileContext";

import { FaSpinner } from "react-icons/fa";
import EditorContext from "../EditorContext";
import { LearnStep } from "./learn/StepLogger";


export default function LearnProc({node, type, disabled}) {
    const editor = useContext(EditorContext)
    const file = useFile()
    const learn = useLearn(node)
    const [state, setState] = useState(file.learn ? file.learn[learn.getRef()] : null)
    
    const onLearnProc = useCallback(r => {  
        //console.info("onLearnProc",r.detail);      
        if(!r.detail) {
            if(file.proc) file.proc[learn.getRef()]={} //todo refactor
            return setState({pending:true});
        }
        if(r.detail!=learn.getRef()) return;
        setState(file.proc[learn.getRef()]);        
    }, [node]);

    useEffect(() => {
        file.on('learn-proc', onLearnProc)
        return () => {
            file.off("learn-proc", onLearnProc);
        }
    }, [node]);
    return <div className={"learn-proc"}>
        {!disabled && file.learn && file.learn.processing && state && state.pending
            ? <i>pending... <FaSpinner className="spinner"/></i> 
            : <></>
        }
        {state && state.steps ? state.steps.map((o,i)=><LearnStep key={i} step={o}/>) : <></>}        
    </div>
}