import React from "react";
import {
    FaCode,
    FaHtml5, FaTags
} from "react-icons/fa";
import HoverActions from "../ui/HoverActions";
import Block from "./Block";
import LevelIcon from "./LevelIcon";
import {useForceUpdate} from "./Blocks";
import { useUndo } from "../contexts/UndoContext";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";
import NodeActions from "../ui/NodeActions";
import LearnItem from "../ui/LearnItem";


export default function BlockHTML({node, onAction, parentAddy}) {
        
    const forceUpdate = useForceUpdate();
    const undo = useUndo();
    const onAceChange = React.useCallback(value => {
        node.childNodes[0].nodeValue = value || '';        
        //undo.add(node);
        forceUpdate();
        //onChange && onChange();
    },[node])

    const htmlContent = node.childNodes[0].textContent || '';
    return (
        <>
            <LevelIcon><FaCode/></LevelIcon><AceEditor
  placeholder=""
  mode="html"
  theme="tomorrow"
  name={"html"+node}  
  onChange={onAceChange}
  fontSize={14}
  maxLines={Infinity}
  showPrintMargin={false}
  showGutter={false}
  highlightActiveLine={false}
  value={htmlContent}
  setOptions={{
  enableBasicAutocompletion: false,
  enableLiveAutocompletion: false,
  enableSnippets: false,
  showLineNumbers: true,
  tabSize: 2,
  }}/>           
<HoverActions><NodeActions node={node} onAction={onAction} addy={parentAddy} /></HoverActions>        
<LearnItem node={node} type={BlockHTML}/>
        </>
    )

}
BlockHTML.learn = {
    render: o => {
        return <span></span>
        let count = '?'
        let obj;
        if (o.output) {
            try {
                let j = obj = JSON.parse(o.output);
                if (j && j.length !== undefined) count = j.length;
                else if (j && Object.keys(j).length) count = Object.keys(j).length;
            } catch (ex) {
                console.warn(ex);
            }
        }
        return <span className={!count ? 'zero' : ''}>
            <i>{o.outputClass}</i> <b className={"color-op"}>{count}</b>
            </span>
    }
}
BlockHTML.addy = {
    icon: <span title={"add raw HTML"}><FaCode/></span>,
    make: ()=>Block.parseFromString('<HTML><![CDATA[]]></HTML>')
}