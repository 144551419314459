import React, {useContext, useState} from "react";
import { FaChartArea,  FaCog} from "react-icons/fa";
import LevelIcon from "./LevelIcon";
import Level from "./Level";
import AttributesList from "../ui/AttributesList";
import EditorContext from "../EditorContext";
import AttributeField from "../ui/AttributeField";
import {useBlock, useLearn} from "./Blocks";
import ButtonEdit from "../ui/ButtonEdit";
import HoverActions from "../ui/HoverActions";
import Block from "./Block";
import LearnItem from "../ui/LearnItem";
import NodeActions from "../ui/NodeActions";

export default function BlockLegend({node, isEdit, onAction, parentAddy}) {
    const context = useContext(EditorContext)
    const block = useBlock(node)

    const [stateEdit, setEdit] = useState(!!isEdit)


    function toggleEdit() {
        setEdit(!stateEdit)
    }

    //let args = node.getAttributeNames()
    //let operation = args.shift()

    return <>
        <LevelIcon><FaChartArea/></LevelIcon> <b>legend</b> <AttributeField node={node} att={"type"}/>
        {false ? <ButtonEdit onClick={toggleEdit}/> : <></>}
        <HoverActions><NodeActions node={node} onAction={onAction} addy={parentAddy} /></HoverActions>
        <LearnItem node={node} type={BlockLegend}/>
        <Level isEdit={stateEdit}>
            <AttributesList prefix="_." hiddenAttributes={["type"]} isEdit={stateEdit} icon={<FaCog/>}
                            node={node} deletable={true}/>
        </Level>
    </>
}

BlockLegend.learn = {
    render: o => {
        let count = '?'
        if (o.output) {
            try {
                let j = JSON.parse(o.output);
                if (j && j.length !== undefined) count = j.length;
            } catch (ex) {
                console.warn(ex);
            }
        }
        return <span className={!count ? 'zero' : ''}>
            <i>{o.outputClass}</i> <b className={"color-op"}>{count}</b>
            </span>
    }
}
BlockLegend.addy = {
    icon: <span title={"add legend"}><FaChartArea/></span>,
    definitions:'legend',
    make: (id) => Block.parseFromString(`<LEGEND type="${id}" />`)
}