import React from "react";
import DragDroppable from "../ui/DragDroppable";
import Blocks, {useBlock, useForceUpdate} from "./Blocks";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove, rectSwappingStrategy,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Sortable from "../ui/Sortable";
import BlockSection from "./BlockSection";
import { useUndo } from "../contexts/UndoContext";
import { useClipboardSelection } from "../contexts/Clipboard";

export default function Sections({node}) {

    const forceUpdate = useForceUpdate()
    const clipboardSelection = useClipboardSelection();
    //console.info('CLIPBOARD SELECTION',clipboardSelection);
    const undo = useUndo();
    const block = useBlock(node)

    function dragStart(e) {
        //console.info('DRAG START', e, block)
    }

    function dragEnd(event) {
        const {active, over} = event;
        //console.info("dragEnd", event,active, over);        
        let children = Array.from(node.children);
        const oldIndex = children.indexOf(active.data.current.node);
        const newIndex = children.indexOf(over.data.current.node);
        if(newIndex===oldIndex) return;
        undo.snap();
        if (newIndex > oldIndex) {
            over.data.current.node.after(active.data.current.node);
        } else {
            over.data.current.node.before(active.data.current.node);
        }        
        forceUpdate();
        //if (over && over.data.current.accepts.includes(active.data.current.type)) {
        // do stuff
        //}
    }

    const cloneNode = e => {
        undo.snap();
        let cloned = e.cloneNode(true);
        block.reidize(cloned);
        e.after(cloned);
        forceUpdate();
    }

    const deleteNode = e => {        
        undo.snap();
        e.parentNode.removeChild(e);        
        forceUpdate();
    }

    const onNodeAction = (node, action) => {        
        console.info('Sections::onNodeAction',action,node);
        switch(action){
            case 'clone':
                undo.snap();
                let cloned = node.cloneNode(true);
                block.reidize(cloned);
                node.after(cloned);
                break;
            case 'delete':
                undo.snap();
                node.parentNode.removeChild(node);
                break;
            case 'paste':
                undo.snap();                
                const children = Array.from(clipboardSelection[0].parentNode.children);
                clipboardSelection.sort((a,b)=>{
                    return children.indexOf(a)>children.indexOf(b) ? -1 : 1;
                }).forEach(e=>{
                    node.after(e.cloneNode(true));
                })
                break;
            case 'copy':
                //TODO
                console.info('COPY:',node);
        }        
        forceUpdate();
    }

    const items = [];
    const sections = [];

    for (let c of node.getElementsByTagName("SECTION")) {
        block.idize(c)
        items.push({node: c, id: c._id});
        //console.info('SECTION ID', section._id);
        sections.push(<Sortable node={c} key={c._id} className={"proof editor-block editor-block-" + c.tagName.toLowerCase()}
                                id={c._id}>
            <BlockSection onNodeAction={onNodeAction} cbClone={() => cloneNode(c)}
                          cbDelete={() => deleteNode(c)} blocks={Blocks.theBlocks} key={c._id} node={c}></BlockSection>
        </Sortable>)
    }


    return <><DndContext collisionDetection={closestCenter} onDragStart={dragStart} onDragEnd={dragEnd}>
        <SortableContext strategy={verticalListSortingStrategy} items={items}>{sections}</SortableContext>
    </DndContext></>

}