import React, { useContext, useState } from "react";
import {
    FaArrowRight,
    FaRegArrowAltCircleUp
} from "react-icons/fa";
import ButtonClone from "../ui/ButtonClone";
import ButtonDelete from "../ui/ButtonDelete";
import HoverActions from "../ui/HoverActions";
import LearnItem from "../ui/LearnItem";
import Block from "./Block";
import LevelIcon from "./LevelIcon";
import NodeActions from "../ui/NodeActions";
import { useEditor } from "../EditorContext";
import ButtonEdit from "../ui/ButtonEdit";
import Level from "./Level";
import AttributesList from "../ui/AttributesList";
import PreviewAnnotations from "../ui/learn/PreviewAnnotations";

export default function BlockStack({node, isEdit, onAction, parentAddy}) {
    const editor = useEditor()
    const [stateEdit, setEdit] = useState((!!isEdit))

    return (
        <>
            <LevelIcon><FaRegArrowAltCircleUp/></LevelIcon>
            <b className={"color-stack block-name"}
               title={"Puts aside current selection and starts a new one"}>stack</b>
            <ButtonEdit onClick={()=>setEdit(!stateEdit)} isEdit={stateEdit}/>
            <HoverActions><NodeActions node={node} onAction={onAction} addy={parentAddy}/></HoverActions>        
            <LearnItem node={node} type={BlockStack}/>
            <Level isEdit={stateEdit}>
            <AttributesList isEdit={stateEdit} deletable={true} node={node}
                            accepted={editor.definitions.tag.stack.attributes}/>
            </Level>
        </>
    )

}
BlockStack.learn = {
    render: o => {
        let diff = o.size - o.before;
        return <PreviewAnnotations list={o.annotations}><span>
                {diff >=0 ? <span>stacked {diff} annotations</span> 
                : <span>removed {diff*-1} annotations from stack</span>}
            <span className={"color-stack"}>
                <b> {o.before}</b> <FaArrowRight/> <b>{o.size}</b>
            </span>
        </span></PreviewAnnotations>
    }
}
BlockStack.addy = {
    icon: <span title={"stack current selection"}><FaRegArrowAltCircleUp/></span>,
    make: ()=>Block.parseFromString('<STACK />')
    
}