import Block from "./Block";
import React from "react";
import {
    FaArrowCircleUp, FaArrowRight,
    FaCog,
    FaCogs, FaFolderOpen,
    FaLongArrowAltUp,
    FaPenAlt,
    FaRegArrowAltCircleUp, FaSave,
    FaTag,
    FaWrench
} from "react-icons/fa";
import LevelIcon from "./LevelIcon";
import HoverActions from "../ui/HoverActions";
import LearnItem from "../ui/LearnItem";
import AttributeField from "../ui/AttributeField";
import { useForceUpdate } from "./Blocks";
import NodeActions from "../ui/NodeActions";

export default function BlockLoad({node, onAction, parentAddy}) {
    const forceUpdate = useForceUpdate()
    return (
        <>
            <LevelIcon><FaFolderOpen/></LevelIcon> <b className={"color-load"}>load </b>
            <span className={node.hasAttribute('id') && node.getAttribute('id') ? '' : "hide-id"}><AttributeField onChange={forceUpdate} placeholder={"optional id"} hideName={true} node={node} att={"id"}/></span>
            <HoverActions><NodeActions node={node} onAction={onAction} addy={parentAddy}/></HoverActions>
            <LearnItem node={node} type={BlockLoad}/>
        </>
    )

}
BlockLoad.learn = {
    render: o => {
        return <span><span>loaded {!o.annotations ? 'data' : 'annotations'}</span>
            <span className={"color-load"}>
                {!o.annotations ? <><b></b></> : <><FaArrowRight/> <b> {o.size}</b></>}
            </span>
        </span>
    }
}
BlockLoad.addy = {
    icon: <span title={"load previously saved data or selection"}><FaFolderOpen/></span>,
    make: ()=>Block.parseFromString('<LOAD />')
}