import EditorContext from "../EditorContext";
import { useState, useEffect, useContext } from "react";
import { useForceUpdate } from "../blocks/Blocks";
import EventDispatcher from "./EventDispatcher";

function initClipboard(){
    let o = new EventDispatcher;
    o.selection=[];
    return o;
}

function useClipboard(node) {
    const context = useContext(EditorContext)
    if (context === undefined) throw new Error('useClipboard  must be used within an EditorContext')    
    if(!context.clipboard) context.clipboard = initClipboard();
    let s = context.clipboard.selection;
    const [selected, setSelected] = useState(s.indexOf(node)>-1);//clipboard.selection.indexOf(node)>-1);    
    const forceUpdate = useForceUpdate();

    useEffect(() => {
        const onClipboardChange = (r) => {
            //console.info('clipbchanged',node);            
            setSelected(s.indexOf(node)>-1);
            forceUpdate();
        }
        context.clipboard.on('change', onClipboardChange)
        return () => {
            context.clipboard.off('change', onClipboardChange)
        }
    }, [node]);
    return {
        selected:selected,
        selection:s,
        toggle:ev=>{
            setSelected(!selected)            
            if(ev.shiftKey && s.length){
                let last = s[s.length-1];
                const children = Array.from(last.parentNode.children);
                let i1 = children.indexOf(last);
                let i2  = children.indexOf(node);
                let iA = i1 < i2 ? i1 : i2;
                let iB = i1 < i2 ? i2 : i1;
                for(let i=iA;i<=iB;i++){
                    let n = children[i];
                    if(s.indexOf(n)<0) s.push(n);
                }
            }else if(!ev.ctrlKey){
                if(s.length===1 && s[0]==node){
                    s.length = 0;
                }else{
                    s.length = 0;
                    s.push(node);
                }
            }else{            
                if(selected) s.splice(s.indexOf(node),1);
                else {
                    for(let i=0;i<s.length;i++) if(s[0].parentNode!=node.parentNode){
                        console.info('CLEARING SELECTION BECAUSE PARENTS DIFFER');
                        s.length=0;
                        break;
                    }
                    s.push(node);
                }
            }        
            //console.info('clipboard selection',s);
            context.clipboard.dispatch('change', node)
        }
    }
}
function useClipboardSelection() {
    const context = useContext(EditorContext)
    if (context === undefined) throw new Error('useClipboardSelection  must be used within an EditorContext')    
    if(!context.clipboard) context.clipboard = initClipboard();
    return context.clipboard.selection;
}
export {useClipboard,useClipboardSelection}
export default useClipboard;
