import React, { useContext, useState } from "react";
import { FaBolt, FaCog } from "react-icons/fa";
import EditorContext, { useEditor } from "../EditorContext";
import AttributeField from "../ui/AttributeField";
import AttributesList from "../ui/AttributesList";
import ButtonEdit from "../ui/ButtonEdit";
import HoverActions from "../ui/HoverActions";
import LearnItem from "../ui/LearnItem";
import NodeActions from "../ui/NodeActions";
import Block from "./Block";
import { useBlock } from "./Blocks";
import Level from "./Level";
import LevelIcon from "./LevelIcon";
import PreviewAnnotations from "../ui/learn/PreviewAnnotations";

export default function BlockOp({node, isEdit, onAction, parentAddy}) {
    const editor = useEditor()
    const block = useBlock(node)

    const [stateEdit, setEdit] = useState(!!isEdit)


    function toggleEdit() {
        setEdit(!stateEdit)
    }

    const [stateTypeEdit, setTypeEdit] = useState(false) //TODO finish impl
    const editType = () => {
        setTypeEdit(!stateTypeEdit);
    }

    let args = node.getAttributeNames()
    let operation = args.shift()
    let op = editor.definitions.op[operation];
    if(!op){
        return <><LevelIcon><FaBolt/></LevelIcon>
            <span style={{color:'red'}}><b>{operation}</b> (invalid operation)</span>
            </>
    }
    const valueAttr = op.attributes.filter(a=>a.name=='~')[0];
    const realAttr = op.attributes.filter(a=>a.name!='~');

    const noEditDisplay = valueAttr ? 
        <AttributeField definition={valueAttr} title={op?.desc} node={node} att={operation}/> :
        <AttributeField definition={op} node={node} att={operation} type={op ? op.type : null}/>

    return <>
        <LevelIcon><FaBolt/></LevelIcon> 
        
        {stateTypeEdit 
            ? <AttributeField autoFocus={true} onEnterKey={editType} onEscapeKey={editType} definition={{label:'type',choices:editor.getDefinitionsOf('op')}} placeholder={"operation"} hideName={true} node={node} att={operation}/>
            : noEditDisplay }    

        {realAttr.length ? <ButtonEdit onClick={toggleEdit} isEdit={stateEdit}/> : <></>}
        <HoverActions><NodeActions node={node} onAction={onAction} addy={parentAddy}/></HoverActions>        
        <LearnItem node={node} type={BlockOp}/>
        <Level isEdit={stateEdit}>
            <AttributesList prefix="_." hiddenAttributes={[operation,'op','_.~']} isEdit={stateEdit} icon={<FaCog/>}
                            node={node} accepted={op ? op.attributes : null} deletable={true}/>
        </Level>
    </>
}

BlockOp.learn = {
    render: o => {
        let count = '?'
        let obj;
        let annotations;
        if (o.output) {
            try {
                let j = obj = JSON.parse(o.output);
                if (j && j.length !== undefined){
                    count = j.length;
                    if(j[0].charAt && j[0].charAt(0)==='@'){
                        annotations = j;
                    }
                }
                else if (j && Object.keys(j).length) count = Object.keys(j).length;
            } catch (ex) {
                console.warn(ex);
            }
        }
        return <PreviewAnnotations list={annotations}><span className={!count ? 'zero' : ''}>
            <i>{o.outputClass}</i> <b className={"color-op"}>{count}</b>
            </span></PreviewAnnotations>
    }
}
BlockOp.addy = {
    icon: <span title={"add operation"}><FaBolt/></span>,
    make: id=>Block.parseFromString('<OP ' + id + '="" />'),    
    definitions:'op'
}