import { useEffect, useState } from "react";
import { useEditor } from "../../EditorContext";
import { LearnStep } from "../learn/StepLogger";
import Utils from "../../converter/Utils";

export default function FFMPEGInstaller() {
    const editor = useEditor()
    const [state,setState] = useState(null)
    const updateNow = ()=>{
        setState({})
        editor.converter.call('install-ffmpeg');
    }
    useEffect(() => {
        const onInstallProgress = (v) => {            
            if(!v.detail.log) return;
            setState(v.detail.log);
            //setProgress(v.detail.total);
            //setProgressFFMPEG(v.detail.ffmpeg);
        }
        editor.converter.on('install-ffmpeg', onInstallProgress)
        return () => {
            editor.converter.off('install-ffmpeg', onInstallProgress)            
        }
    }, []);
    
    return <div>
        <p>AVAA can try to install FFmpeg for you.</p>                
        {state && state.steps ? state.steps.map((o,i)=><LearnStep key={i} step={o}/>) : <></>}
        <button onClick={updateNow} disabled={!!state}>Install FFmpeg automatically</button> or <a href="https://ffmpeg.org/" target="_blank">install manually</a>
        
    </div>
}