import React, {useContext, useEffect, useState} from "react";
import {FaBolt, FaCheckDouble, FaCog, FaEye, FaRegCheckCircle, FaSearch} from "react-icons/fa";
import LevelIcon from "./LevelIcon";
import Level from "./Level";
import AttributesList from "../ui/AttributesList";
import EditorContext from "../EditorContext";
import AttributeField from "../ui/AttributeField";
import {useBlock, useLearn} from "./Blocks";
import ButtonEdit from "../ui/ButtonEdit";
import HoverActions from "../ui/HoverActions";
import ButtonClone from "../ui/ButtonClone";
import ButtonDelete from "../ui/ButtonDelete";
import BlockSelect from "./BlockSelect";
import Block from "./Block";
import LearnItem from "../ui/LearnItem";
import BlockStack from "./BlockStack";
import { useFile } from "../contexts/FileContext";
import NodeActions from "../ui/NodeActions";

export default function BlockTest({node, isEdit, onAction,parentAddy}) {
    const context = useContext(EditorContext)
    const block = useBlock(node)
    const file = useFile()
    const learn = useLearn(node)
    const [stateEdit, setEdit] = useState(!!isEdit || !block.getAtt("name"))
    const [learnData, setLearnData] = useState(file.learn ? file.learn[learn.getRef()] : null)
    //const [internalEdit, setInternalEdit] = useState(false)

    function toggleEdit() {
        setEdit(!stateEdit)        
    }
    const onLearnData = data => {        
        let proc = file.learn && file.learn.processing;        
        if (proc) setLearnData(null)
        else setLearnData(file.learn[learn.getRef()])
    }
    
    useEffect(() => {
        file.on('learn-data', onLearnData)
        return () => {
            file.off("learn-item", onLearnData);
        }
    }, [node]);

    let args = node.getAttributeNames()
    let operation = 'test';
    let op = {
        id:'test',   
        novalue:true,     
        nolabel:true,
        attributes:[
            {name:'name',type:'string'},
            {name:'code',type:'js'},
            {name:'type',type:'choices',choices:'assert,expect',def:'assert'},            
            {name:'result',type:'js'}
        ]
    }
    //<ButtonEdit onClick={toggleEdit}/>
    const failpass = learnData ? (learnData.error || learnData.fail ? 'fail' : (learnData.result!==undefined ? 'pass' : '')) : '';
    return <>
    <HoverActions><NodeActions node={node} onAction={onAction} addy={parentAddy} /></HoverActions>
        <LevelIcon><FaRegCheckCircle/></LevelIcon> 
        
        <a className={"test-name test-"+failpass} onClick={toggleEdit}>{block.getAtt("name")||'unamed test'}</a> 
        {stateEdit ? <>
            
            <LearnItem node={node} type={BlockTest} />
            <Level isEdit={stateEdit}>
                <AttributesList isEdit={stateEdit} icon={<FaCog/>}
                                node={node} accepted={op ? op.attributes : null} deletable={true}/>
            </Level>
        </> :
            <>
            <LearnItem node={node} type={BlockTest} />
            </>
        }
    </>
}

BlockTest.learn = {
    render: o => {
        let count = '?'
        let obj;
        if (o.output) {
            try {
                let j = obj = JSON.parse(o.output);
                if (j && j.length !== undefined) count = j.length;
                else if (j && Object.keys(j).length) count = Object.keys(j).length;
            } catch (ex) {
                console.warn(ex);
            }
        }        
        return <span>
            <b className={"test-"+(o.fail || o.error ? 'fail':'pass')}>{(o.fail || o.error? 'FAIL':'PASS')}</b>
            </span>
    }
}
BlockTest.addy = {
    icon: <span title={"add a test"}><FaRegCheckCircle/></span>,
    make: ()=>Block.parseFromString('<TEST />')
}